import React, { useMemo, useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MarketplaceLayout } from "jobber/marketplace/components/MarketplaceLayout";
import type { AppsQuery } from "~/utilities/API/graphql";
import type { AppsQuery as PublicAppsQuery } from "~/utilities/API/graphqlExternal";
import { CTA_SHOWN } from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";
import { PUBLIC_APPS_QUERY } from "external/marketplace/queries/apps.graphql";
import { AlternateSchema } from "~/utilities/API/APIClient";
import styles from "./IntegrationList.module.css";
import { IntegrationRequest } from "./IntegrationRequest";
import { SearchBox } from "./SearchBox";
import type { Integration, IntegrationListProps } from "./IntegrationListProps";
import { APPS_QUERY } from "./IntegrationList.graphql";
import { IntegrationCardList } from "./IntegrationCardList";
import { AllAppsSection } from "./AllAppsSection";
import { OurPicksSection } from "./OurPicksSection";
import { messages } from "../../messages";

export enum CardType {
  OUR_PICKS = "picks",
  ALL_APPS = "all",
  CONNECTED = "connected",
}

function IntegrationList({
  allApps,
  connectedApps,
  featuredApps,
  publicFacing,
  highlightedApp,
}: IntegrationListProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const { formatMessage } = useIntl();
  const [fetchSearchResults, { data, loading, error }] = useLazyQuery<
    PublicAppsQuery | AppsQuery
  >(publicFacing ? PUBLIC_APPS_QUERY : APPS_QUERY, {
    context: {
      schema: publicFacing ? AlternateSchema.External : undefined,
    },
  });

  const [ctaShown] = useMutation(CTA_SHOWN);

  React.useEffect(() => {
    if (publicFacing) return;

    void ctaShown({
      variables: { id: "private_app_marketplace_page_viewed" },
    });
  }, []);

  const searchResult: {
    filteredApps: Integration[];
    header: string;
    showOurPicks: boolean;
  } = useMemo(() => {
    if (!data?.apps?.nodes || !searchTerm.trim()) {
      return {
        filteredApps: [...allApps].sort(sortByTitle),
        header: formatMessage(messages.sectionAllAppsTitle),
        showOurPicks: true,
      };
    }

    const header = `${formatMessage(
      messages.sectionResultsForTitle,
    )} "${searchTerm.trim()}" (${data.apps.nodes.length})`;

    if (data.apps.nodes.length === 0) {
      return { filteredApps: [], header, showOurPicks: false };
    }

    const matchedApps = data.apps.nodes
      .map(app => {
        return allApps.find(a => a.id === app.id);
      })
      .filter(app => app !== undefined) as Integration[];

    return { filteredApps: matchedApps, header, showOurPicks: false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, allApps]);

  function handleChange(newValue: string | number | boolean | Date) {
    setSearchTerm(newValue.toString());

    if (newValue.toString().trim() === "") {
      void fetchSearchResults({
        variables: { searchTerm: "" },
      });
    }
  }

  function handleSearch() {
    void fetchSearchResults({
      variables: { searchTerm: searchTerm.trim() },
    });
  }

  return (
    <div className={styles.pageWrap}>
      <div
        data-testid="torn-page-background"
        className={`${styles.tornPage} ${
          publicFacing ? styles.tornPagePublic : ""
        }`}
      ></div>
      <MarketplaceLayout publicFacing={publicFacing}>
        <div className={styles.searchBoxSection}>
          <SearchBox
            value={searchTerm}
            onChange={handleChange}
            onSearch={handleSearch}
          />
        </div>
        {loading && searchTerm.trim() ? (
          <div className={styles.appsSectionContainer}>
            <div className="spinner spinner--inline" />
          </div>
        ) : (
          <>
            {searchResult.showOurPicks && !error && (
              <OurPicksSection
                featuredApps={featuredApps}
                publicFacing={publicFacing}
              />
            )}

            <div className={styles.appSection}>
              {error ? (
                <div className={styles.appsSectionContainer}>
                  <p>{formatMessage(messages.sectionErrorMessage)}</p>
                </div>
              ) : (
                <AllAppsSection
                  searchResult={searchResult}
                  highlightedApp={highlightedApp}
                  setSearchTerm={setSearchTerm}
                  fetchSearchResults={fetchSearchResults}
                  publicFacing={publicFacing}
                />
              )}
            </div>
          </>
        )}

        <div className={styles.requestAppContainer}>
          <IntegrationRequest
            defaultName={
              searchResult.filteredApps.length === 0 ? searchTerm.trim() : ""
            }
            publicFacing={publicFacing}
          />
        </div>

        {connectedApps.length > 0 && (
          <div className={styles.appSection}>
            <Heading level={2}>
              {formatMessage(messages.sectionConnectedAppsTitle)}
            </Heading>
            <div className={styles.appGrid}>
              <IntegrationCardList
                apps={[...connectedApps].sort(sortByTitle)}
                type={CardType.CONNECTED}
                publicFacing={publicFacing}
              />
            </div>
          </div>
        )}
      </MarketplaceLayout>
    </div>
  );
}

function sortByTitle(a: Integration, b: Integration) {
  return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
}

export { IntegrationList };
