import React, {
  type PropsWithChildren,
  type RefObject,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import type { FormRef } from "@jobber/components/Form";
import {
  CampaignTemplateCtaLinkTo,
  Segment,
  Template,
} from "~/utilities/API/graphql";
import type {
  AllClientsCriteriaInput,
  File,
  PastClientsCriteriaInput,
  TemplateVariablesFragment,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { ClientsCriteriaFromSegment } from "jobber/campaigns/utils/segmentCriteriaUtils";
import {
  DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
  DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
} from "jobber/campaigns/constants";
import { useSentDelighter } from "jobber/campaigns/components/SentDelighterModal/useSentDelighter";
import { usePauseAndEdit } from "jobber/campaigns/components/PauseAndEditModal/usePauseAndEdit";
import { useSendNow } from "jobber/campaigns/components/SendNowModal/useSendNow";
import { useReactivateCampaign } from "jobber/campaigns/components/ReactivateCampaignModal/useReactivateCampaign";
import { useDeactivateCampaign } from "jobber/campaigns/components/DeactivateCampaignModal/useDeactivateCampaign";
import { useAutomatedPauseAndEditCampaign } from "jobber/campaigns/components/AutomatedPauseAndEditModal/useAutomatedPauseAndEditCampaign";
import { campaignContentReducer } from "./CampaignContentReducer";
import { DEFAULT_CTA_BUTTON_COLOR } from "./constants";
import { useCampaignSegmentCriteria } from "./useCampaignSegmentCriteria";
import type { AdditionalCriteriaReducerActions } from "./CampaignAdditionalSegmentCriteriaReducer";

const defaultFunction = () => undefined;
export const campaignWizardContextDefaultValue: CampaignWizardContextProps = {
  campaignContent: {
    subject: "",
    header: "",
    isDirty: false,
    body: "",
    showLogo: true,
    ctaButtonEnabled: true,
    linkTo: CampaignTemplateCtaLinkTo.REQUEST_FORM,
    linkValue: undefined,
    buttonText: "",
    buttonColor: DEFAULT_CTA_BUTTON_COLOR,
    campaignId: "",
    templateType: Template.RE_ENGAGE,
    headerImage: undefined,
    imageIds: [],
    referralLink: undefined,
    variables: [],

    setSubject: defaultFunction,
    setHeader: defaultFunction,
    setBody: defaultFunction,
    setShowLogo: defaultFunction,
    setIsDirty: defaultFunction,
    resetCampaignTemplateContent: defaultFunction,
    setCampaignId: defaultFunction,
    setCtaButtonEnabled: defaultFunction,
    setLinkTo: defaultFunction,
    setLinkValue: defaultFunction,
    setButtonText: defaultFunction,
    setButtonColor: defaultFunction,
    setTemplateType: defaultFunction,
    setHeaderImage: defaultFunction,
    setImageIds: defaultFunction,
    setReferralLink: defaultFunction,
    setVariables: defaultFunction,
  },
  campaignSegment: {
    allClientsAdditionalCriteria: [],
    pastClientsAdditionalCriteria: [],
    updatePastClientsAdditionalCriteria: defaultFunction,
    selectedSegmentType: Segment.PAST_CLIENTS,
    updateAllClientsAdditionalCriteria: defaultFunction,
    setSelectedSegmentType: defaultFunction,
    setAllClientsSegmentCriteria: defaultFunction,
    setPastClientsSegmentCriteria: defaultFunction,
    setNotDirty: defaultFunction,
    pastClientsSegmentCriteria: DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
    upcomingClientsSegmentCriteria: DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
    setUpcomingClientsSegmentCriteria: defaultFunction,
    upcomingClientsAdditionalCriteria: [],
    updateUpcomingClientsAdditionalCriteria: defaultFunction,
    segmentCriteriaFormRef: undefined,
    isSegmentCriteriaFormValid: true,
    setSegmentCriteriaFormState: defaultFunction,
  },
  sentDelighter: {
    open: false,
    clientCount: null,
    type: Template.RE_ENGAGE,
    setOpen: defaultFunction,
    setClientCount: defaultFunction,
    setType: defaultFunction,
  },
  pauseAndEdit: {
    open: false,
    campaignId: null,
    setOpen: defaultFunction,
    setCampaignId: defaultFunction,
  },
  reactivateAutomatedCampaign: {
    open: false,
    campaignId: null,
    setOpen: defaultFunction,
    setCampaignId: defaultFunction,
  },
  deactivateAutomatedCampaign: {
    open: false,
    campaignId: null,
    setOpen: defaultFunction,
    setCampaignId: defaultFunction,
  },
  pauseAndEditAutomatedCampaign: {
    open: false,
    campaignId: null,
    setOpen: defaultFunction,
    setCampaignId: defaultFunction,
  },
  sendNow: {
    open: false,
    campaignId: null,
    setOpen: defaultFunction,
    setCampaignId: defaultFunction,
  },
};

export interface CampaignContent {
  subject: string;
  header: string;
  isDirty: boolean;
  body: string;
  showLogo: boolean;
  ctaButtonEnabled: boolean;
  linkTo: CampaignTemplateCtaLinkTo;
  linkValue?: string;
  buttonText: string;
  buttonColor: string;
  campaignId: string;
  templateType: Template;
  headerImage?: File;
  imageIds?: string[];
  referralLink?: string;
  variables: TemplateVariablesFragment[];

  setSubject: (value: string, isDirty: boolean) => void;
  setHeader: (value: string, isDirty: boolean) => void;
  setIsDirty: (value: boolean) => void;
  setBody: (value: string, isDirty: boolean) => void;
  setShowLogo: (value: boolean, isDirty: boolean) => void;
  setCtaButtonEnabled: (value: boolean, isDirty: boolean) => void;
  setLinkTo: (value: CampaignTemplateCtaLinkTo, isDirty: boolean) => void;
  setLinkValue: (value: string, isDirty: boolean) => void;
  setButtonText: (value: string, isDirty: boolean) => void;
  setButtonColor: (value: string, isDirty: boolean) => void;
  setCampaignId: (value: string) => void;
  resetCampaignTemplateContent: () => void;
  setTemplateType: (value?: Template) => void;
  setHeaderImage: (value: File | undefined, isDirty: boolean) => void;
  setImageIds: (value: string[] | undefined) => void;
  setReferralLink: (value: string | undefined) => void;
  setVariables: (value: TemplateVariablesFragment[]) => void;
}

export interface CampaignSegment {
  pastClientsSegmentCriteria: PastClientsCriteriaInput;
  setPastClientsSegmentCriteria: (
    value: ClientsCriteriaFromSegment,
    setDirty?: boolean,
  ) => void;
  allClientsSegmentCriteria?: AllClientsCriteriaInput;
  setAllClientsSegmentCriteria: (
    value: ClientsCriteriaFromSegment,
    setDirty?: boolean,
  ) => void;
  selectedSegmentType: Segment;
  pastClientsAdditionalCriteria: AdditionalCriteriaUnion[];
  updatePastClientsAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  allClientsAdditionalCriteria: AdditionalCriteriaUnion[];
  updateAllClientsAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  setSelectedSegmentType: (value: Segment, setDirty?: boolean) => void;
  upcomingClientsAdditionalCriteria: AdditionalCriteriaUnion[];
  updateUpcomingClientsAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  upcomingClientsSegmentCriteria: UpcomingClientsCriteriaInput;
  setUpcomingClientsSegmentCriteria: (
    value: ClientsCriteriaFromSegment,
    setDirty?: boolean,
  ) => void;
  isDirty?: boolean;
  setNotDirty: () => void;
  segmentCriteriaFormRef?: RefObject<FormRef>;
  isSegmentCriteriaFormValid: boolean;
  setSegmentCriteriaFormState: React.Dispatch<
    React.SetStateAction<{
      isDirty: boolean;
      isValid: boolean;
    }>
  >;
}

export interface SentDelighterState {
  open: boolean;
  clientCount: number | string | null;
  type: Template;
  setOpen: (flag: boolean) => void;
  setClientCount: (count: string | number | null) => void;
  setType: (type: Template) => void;
}

export interface ConfirmationModalState {
  open: boolean;
  setOpen: (flag: boolean) => void;
  campaignId: string | null;
  setCampaignId: (id: string | null) => void;
}

export interface CampaignWizardContextProps {
  campaignContent: CampaignContent;
  campaignSegment: CampaignSegment;
  sentDelighter: SentDelighterState;
  pauseAndEdit: ConfirmationModalState;
  reactivateAutomatedCampaign: ConfirmationModalState;
  deactivateAutomatedCampaign: ConfirmationModalState;
  pauseAndEditAutomatedCampaign: ConfirmationModalState;
  sendNow: ConfirmationModalState;
}

const CampaignWizardContext = createContext(campaignWizardContextDefaultValue);

export function useCampaignWizardContext(): CampaignWizardContextProps {
  return useContext(CampaignWizardContext);
}

export interface CampaignWizardProviderProps {
  campaignContent?: Pick<
    CampaignContent,
    | "subject"
    | "header"
    | "body"
    | "showLogo"
    | "isDirty"
    | "ctaButtonEnabled"
    | "linkTo"
    | "linkValue"
    | "buttonText"
    | "buttonColor"
    | "headerImage"
    | "imageIds"
    | "referralLink"
    | "variables"
  >;

  campaignSegment?: Pick<CampaignSegment, "selectedSegmentType">;
}

// eslint-disable-next-line max-statements
export function CampaignWizardProvider({
  children,
  campaignContent: _campaignContent,
  campaignSegment: _campaignSegment,
}: PropsWithChildren<CampaignWizardProviderProps>) {
  const initial = Object.assign(
    {},
    campaignWizardContextDefaultValue.campaignContent,
    _campaignContent,
  );

  const [campaignContent, dispatch] = useReducer(
    campaignContentReducer,
    initial,
  );

  const setSubject = useCallback((value: string, isDirty: boolean) => {
    dispatch({ type: "setSubject", payload: { value, isDirty } });
  }, []);

  const setHeader = useCallback((value: string, isDirty: boolean) => {
    dispatch({ type: "setHeader", payload: { value, isDirty } });
  }, []);

  const setBody = useCallback((value: string, isDirty: boolean) => {
    dispatch({ type: "setBody", payload: { value, isDirty } });
  }, []);

  const setShowLogo = useCallback((value: boolean, isDirty: boolean) => {
    dispatch({ type: "setShowLogo", payload: { value, isDirty } });
  }, []);

  const setIsDirty = useCallback((value: boolean) => {
    dispatch({ type: "setIsDirty", payload: value });
  }, []);

  const resetCampaignTemplateContent = useCallback(() => {
    dispatch({ type: "resetCampaignTemplateContent" });
  }, []);

  const setCampaignId = useCallback((value: string) => {
    dispatch({ type: "setCampaignId", payload: value });
  }, []);

  const setCtaButtonEnabled = useCallback(
    (value: boolean, isDirty: boolean) => {
      dispatch({ type: "setCtaButtonEnabled", payload: { value, isDirty } });
    },
    [],
  );

  const setLinkTo = useCallback(
    (value: CampaignTemplateCtaLinkTo, isDirty: boolean) => {
      dispatch({ type: "setLinkTo", payload: { value, isDirty } });
    },
    [],
  );

  const setLinkValue = useCallback((value: string, isDirty: boolean) => {
    dispatch({ type: "setLinkValue", payload: { value, isDirty } });
  }, []);

  const setButtonText = useCallback((value: string, isDirty: boolean) => {
    dispatch({ type: "setButtonText", payload: { value, isDirty } });
  }, []);

  const setButtonColor = useCallback((value: string, isDirty: boolean) => {
    dispatch({ type: "setButtonColor", payload: { value, isDirty } });
  }, []);

  const setTemplateType = useCallback((value: Template) => {
    dispatch({ type: "setTemplateType", payload: value });
  }, []);

  const setHeaderImage = useCallback(
    (value: File | undefined, isDirty: boolean) => {
      dispatch({ type: "setHeaderImage", payload: { value, isDirty } });
    },
    [],
  );

  const setImageIds = useCallback((value: string[]) => {
    dispatch({ type: "setImageIds", payload: value });
  }, []);

  const setReferralLink = useCallback((value: string) => {
    dispatch({ type: "setReferralLink", payload: value });
  }, []);

  const setVariables = useCallback((value: TemplateVariablesFragment[]) => {
    dispatch({ type: "setVariables", payload: value });
  }, []);

  const {
    pastClientsAdditionalCriteria,
    updatePastClientsAdditionalCriteria,
    allClientsAdditionalCriteria,
    updateAllClientsAdditionalCriteria,
    allClientsSegmentCriteria,
    pastClientsSegmentCriteria,
    setAllClientsSegmentCriteria,
    setPastClientsSegmentCriteria,
    setSelectedSegmentType,
    selectedSegmentType,
    isDirty,
    setNotDirty,
    upcomingClientsAdditionalCriteria,
    updateUpcomingClientsAdditionalCriteria,
    upcomingClientsSegmentCriteria,
    setUpcomingClientsSegmentCriteria,
    segmentCriteriaFormRef,
    isSegmentCriteriaFormValid,
    setSegmentCriteriaFormState,
  } = useCampaignSegmentCriteria({
    selectedSegmentType: _campaignSegment?.selectedSegmentType,
  });

  const {
    open: isSentDelighterOpen,
    clientCount: sentDelighterClientCount,
    type: sentDelighterType,
    setOpen: setSentDelighterOpen,
    setClientCount: setSentDelighterClientCount,
    setType: setSentDelighterType,
  } = useSentDelighter();

  const {
    open: isPauseAndEditOpen,
    campaignId: pauseAndEditCampaignId,
    setOpen: setPauseAndEditOpen,
    setCampaignId: setPauseAndEditCampaignId,
  } = usePauseAndEdit();

  const {
    open: isReactivateModalOpen,
    campaignId: reactivateCampaignId,
    setOpen: setReactivateModalOpen,
    setCampaignId: setReactivateCampaignId,
  } = useReactivateCampaign();

  const {
    open: isDeactivateModalOpen,
    campaignId: deactivateCampaignId,
    setOpen: setDeactivateModalOpen,
    setCampaignId: setDeactivateCampaignId,
  } = useDeactivateCampaign();

  const {
    open: isAutomatedPauseAndEditModalOpen,
    campaignId: automatedPauseAndEditCampaignId,
    setOpen: setAutomatedPauseAndEditModalOpen,
    setCampaignId: setAutomatedPauseAndEditCampaignId,
  } = useAutomatedPauseAndEditCampaign();

  const {
    open: isSendNowOpen,
    campaignId: sendNowCampaignId,
    setOpen: setSendNowOpen,
    setCampaignId: setSendNowCampaignId,
  } = useSendNow();

  return (
    <CampaignWizardContext.Provider
      value={{
        campaignContent: {
          header: campaignContent.header,
          subject: campaignContent.subject,
          body: campaignContent.body,
          showLogo: campaignContent.showLogo,
          isDirty: campaignContent.isDirty,
          ctaButtonEnabled: campaignContent.ctaButtonEnabled,
          linkTo: campaignContent.linkTo,
          linkValue: campaignContent.linkValue,
          buttonText: campaignContent.buttonText,
          buttonColor: campaignContent.buttonColor,
          campaignId: campaignContent.campaignId,
          templateType: campaignContent.templateType,
          headerImage: campaignContent.headerImage,
          imageIds: campaignContent.imageIds,
          referralLink: campaignContent.referralLink,
          variables: campaignContent.variables,
          setSubject,
          setHeader,
          setBody,
          setShowLogo,
          setIsDirty,
          resetCampaignTemplateContent,
          setCampaignId,
          setCtaButtonEnabled,
          setLinkTo,
          setLinkValue,
          setButtonText,
          setButtonColor,
          setTemplateType,
          setHeaderImage,
          setImageIds,
          setReferralLink,
          setVariables,
        },
        campaignSegment: {
          allClientsAdditionalCriteria,
          updateAllClientsAdditionalCriteria,
          pastClientsAdditionalCriteria,
          updatePastClientsAdditionalCriteria,
          selectedSegmentType,
          setSelectedSegmentType,
          allClientsSegmentCriteria,
          pastClientsSegmentCriteria,
          upcomingClientsSegmentCriteria,
          setAllClientsSegmentCriteria,
          setPastClientsSegmentCriteria,
          isDirty: isDirty,
          setNotDirty,
          setUpcomingClientsSegmentCriteria,
          upcomingClientsAdditionalCriteria,
          updateUpcomingClientsAdditionalCriteria,
          segmentCriteriaFormRef,
          isSegmentCriteriaFormValid,
          setSegmentCriteriaFormState,
        },
        sentDelighter: {
          open: isSentDelighterOpen,
          clientCount: sentDelighterClientCount,
          type: sentDelighterType,
          setOpen: setSentDelighterOpen,
          setClientCount: setSentDelighterClientCount,
          setType: setSentDelighterType,
        },
        pauseAndEdit: {
          open: isPauseAndEditOpen,
          campaignId: pauseAndEditCampaignId,
          setOpen: setPauseAndEditOpen,
          setCampaignId: setPauseAndEditCampaignId,
        },
        reactivateAutomatedCampaign: {
          open: isReactivateModalOpen,
          campaignId: reactivateCampaignId,
          setOpen: setReactivateModalOpen,
          setCampaignId: setReactivateCampaignId,
        },
        deactivateAutomatedCampaign: {
          open: isDeactivateModalOpen,
          campaignId: deactivateCampaignId,
          setOpen: setDeactivateModalOpen,
          setCampaignId: setDeactivateCampaignId,
        },
        pauseAndEditAutomatedCampaign: {
          open: isAutomatedPauseAndEditModalOpen,
          campaignId: automatedPauseAndEditCampaignId,
          setOpen: setAutomatedPauseAndEditModalOpen,
          setCampaignId: setAutomatedPauseAndEditCampaignId,
        },
        sendNow: {
          open: isSendNowOpen,
          campaignId: sendNowCampaignId,
          setOpen: setSendNowOpen,
          setCampaignId: setSendNowCampaignId,
        },
      }}
    >
      {children}
    </CampaignWizardContext.Provider>
  );
}
