/* eslint-disable max-statements */
import React, { useMemo } from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components/Glimmer";
import type { IconNames } from "@jobber/components";
import { Flex, Icon, Typography } from "@jobber/components";
import { Tooltip } from "@jobber/components/Tooltip";
import classNames from "classnames";
import {
  CampaignStatus,
  type CommsCampaignQuery,
} from "~/utilities/API/graphql";
import { messages as disabledCardMessages } from "jobber/campaigns/views/CampaignsTemplatePage/messages";
import styles from "./SelectableCard.module.css";
import { messages } from "./messages";

interface SelectableCardIcon {
  icon: IconNames;
  alternativeText: string;
}

/**
 * Omitting variables overwritten in a collection
 */
export type SelectableCardSimplified = Omit<
  SelectableCard,
  "disabled" | "disabledMessage" | "fullWidth" | "onChange" | "name" | "loading"
>;

export interface SelectableCard<
  T extends string | number | readonly string[] = string | number,
> {
  heading: string;
  description: string;

  /**
   * The additional detail to display below the description
   */
  subDescription?: string;

  /**
   * Value of the radio button, this will be set to the onChange when
   * card is selected
   */
  value: T;

  /**
   * Whether the card is checked on initial load. If multiple have true,
   * last one will be used by default.
   */
  checked: boolean;

  /**
   * Unique name used to group cards together.
   */
  name?: string;

  /**
   * SelectableCardIcon to display
   */
  img?: SelectableCardIcon;

  /**
   * Display an inline Recommended label
   */
  recommended?: boolean;

  /**
   * When set, a View and Edit button is displayed. This function is invoked when
   * the the button is clicked
   */
  additionalButtonClick?: () => void;
  additionalButtonText?: string;

  /**
   * When true, disables the card and adds a coming soon inline label
   */
  comingSoon?: boolean;
  referral?: boolean;

  // Below are attributes not included in the simplified version.

  loading?: boolean;

  /**
   * Render card as full width
   */
  fullWidth?: boolean;

  /**
   * Function called when a different card from group is selected.
   */
  onChange?: (newValue: T) => void;

  /**
   * Disable the card
   */
  disabled?: boolean;
  disabledMessage?: string;
  campaign?: CommsCampaignQuery["commsCampaign"];
}

export function SelectableCard<
  T extends string | number | readonly string[] = string,
>({
  heading,
  description,
  subDescription,
  value,
  checked,
  name,
  onChange,
  img,
  recommended,
  additionalButtonClick,
  additionalButtonText = "",
  fullWidth,
  loading,
  comingSoon,
  referral,
  disabled,
  disabledMessage = "",
  campaign = undefined,
}: SelectableCard<T>) {
  const { formatMessage } = useIntl();

  function handleChange() {
    onChange?.(value);
  }

  const disabledAutomatedCampaignTemplateCard = useMemo(() => {
    return (
      !disabled &&
      !comingSoon &&
      campaign &&
      campaign.isAutomated &&
      campaign.status !== CampaignStatus.DRAFT &&
      campaign.templates?.nodes[0].type !== value
    );
  }, [campaign, disabled, comingSoon, value]);

  const disabledCardState = disabled || disabledAutomatedCampaignTemplateCard;

  const cardIcon = () => {
    return img ? (
      <div className={styles.iconContainer}>
        <Icon
          name={img.icon}
          color={disabledCardState ? "disabled" : "heading"}
        />
      </div>
    ) : null;
  };

  const cardRecommended = () => {
    return recommended && !img ? (
      <InlineLabel color="lightBlue">
        {formatMessage(messages.recommended)}
      </InlineLabel>
    ) : null;
  };

  const cardStatus = () => {
    if (comingSoon && !disabled) {
      return (
        <InlineLabel color="lightBlue">
          {formatMessage(messages.comingSoon)}
        </InlineLabel>
      );
    }

    if (referral) {
      return (
        <InlineLabel color="lightBlue">
          {formatMessage(messages.referral)}
        </InlineLabel>
      );
    }
  };

  const cardName = () => {
    return heading ? (
      <Typography
        fontWeight={"bold"}
        textColor={disabledCardState ? "disabled" : "heading"}
      >
        {heading}
      </Typography>
    ) : (
      <Glimmer.Header />
    );
  };

  const cardDescription = () => {
    return description ? (
      <Text variation={disabledCardState ? "disabled" : "subdued"}>
        {description}
      </Text>
    ) : (
      <Glimmer.Text lines={3} />
    );
  };

  const cardSubDescription = () => {
    if (subDescription) {
      return (
        <Text variation={disabledCardState ? "disabled" : "subdued"}>
          {subDescription}
        </Text>
      );
    }
  };

  const showAdditionalClickButton: boolean =
    checked &&
    typeof additionalButtonClick === "function" &&
    additionalButtonText !== undefined;

  const cardAdditionalClickButton = () => {
    return showAdditionalClickButton ? (
      <Button
        label={additionalButtonText}
        onClick={additionalButtonClick}
        variation={"work"}
        type={"tertiary"}
      />
    ) : null;
  };

  if (loading) {
    return (
      <div className={styles.loadingContent}>
        <Glimmer.Text />
      </div>
    );
  }
  const parentClassName = () => {
    const _class = fullWidth ? styles.contentVertical : styles.content;

    return disabledCardState
      ? classNames(_class, styles.disabled)
      : classNames(_class);
  };

  const disabledCardMessage = () => {
    if (disabledAutomatedCampaignTemplateCard) {
      return formatMessage(disabledCardMessages.unableToSwitchAutomated);
    }
    return disabled ? disabledMessage : "";
  };

  return (
    <label className={styles.container}>
      <input
        id={value.toString()}
        type="radio"
        name={name}
        className={styles.radio}
        checked={checked}
        onClick={handleChange}
        onChange={() => {
          // I've moved the handleChange function to the onClick event because we want to click on the selected option and trigger
          // the action. This component is no longer behaving as a radio button in all cases
        }}
        value={value}
        disabled={
          comingSoon || disabled || disabledAutomatedCampaignTemplateCard
        }
      />

      <div className={parentClassName()}>
        <Tooltip message={disabledCardMessage()}>
          <div className={styles.contentContainer}>
            <div className={styles.innerContentContainer}>
              <div className={styles.iconAndStatusContainer}>
                <Flex align="center" template={["grow", "shrink"]}>
                  <Flex
                    align="center"
                    gap="smaller"
                    template={["shrink", "shrink", "shrink"]}
                  >
                    {img ? cardIcon() : cardRecommended()}
                  </Flex>
                  {cardStatus()}
                </Flex>
              </div>
              <div>
                <div
                  className={
                    showAdditionalClickButton
                      ? styles.contentHeadingAndViewEditButton
                      : ""
                  }
                >
                  {cardName()}
                  {cardAdditionalClickButton()}
                </div>
                <div className={styles.descriptionContainer}>
                  {cardDescription()}
                </div>
                <div className={styles.descriptionContainer}>
                  {cardSubDescription()}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
    </label>
  );
}
