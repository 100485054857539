import { defineMessages } from "react-intl";

export const messages = defineMessages({
  allClientsSegmentCardDescription: {
    id: "campaigns.selectableSegment.allClientsSegmentCardDescription",
    defaultMessage: "Clients that have not been archived",
    description: "Description for the all clients segment card",
  },
  clients: {
    id: "campaigns.clientSegmentPage.allClientsSegment.clients",
    defaultMessage:
      "{totalCount} {totalCount, plural, one {client} other {clients}} ",
    description: "clients label",
  },
});
