import React, { useCallback } from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { useIntl } from "react-intl";
import { jobberOnline } from "components/JobberOnline/jobberOnline";
import type { AutocompleteCompany as AutocompleteCompanyType } from "jobber/setupWizard/types";
import { useDatadogLogger } from "~/utilities/errors/Datadog/DatadogErrorBoundaryProvider";
import { AutocompleteCompany } from "./components/AutocompleteCompany";
import { messages } from "./messages";

interface GoogleCompanyAutocompleteProps {
  invalid: boolean;
  onChange: (company: AutocompleteCompanyType) => void;
  value: string | undefined;
  valueLabel: string | undefined;
  signupConsentCountry: string | undefined;
  fallback: JSX.Element;
}

export function GoogleCompanyAutocomplete({
  invalid,
  onChange,
  value,
  valueLabel,
  signupConsentCountry,
  fallback,
}: GoogleCompanyAutocompleteProps) {
  const { formatMessage } = useIntl();
  const apiKey = jobberOnline.constants.googleCloudPlatformKey;

  const onCompanyChange = useCallback(
    (company: AutocompleteCompanyType) => {
      onChange(company);
    },
    [onChange],
  );

  const { logError } = useDatadogLogger();

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return fallback;
      case Status.FAILURE:
        logError("Error loading Google Places API");
        return fallback;
      case Status.SUCCESS:
        return (
          <AutocompleteCompany
            onCompanyChange={onCompanyChange}
            placeholder={formatMessage(messages.companyNamePlaceholder)}
            value={value}
            valueLabel={valueLabel}
            name="company"
            invalid={invalid}
            signupConsentCountry={signupConsentCountry}
          />
        );
    }
  };

  return (
    <Wrapper
      apiKey={apiKey}
      channel="5"
      libraries={["core", "places"]}
      render={render}
    />
  );
}
