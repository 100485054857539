import { type MutableRefObject, useRef, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { useMutation } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { useOnMount } from "@jobber/hooks/useOnMount";
import { useIntl } from "react-intl";
import type { ActivateCoreToConnectTrialMutation } from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { UpgradeWaveFeatureCarousel } from "~/components/Expansion/UpgradeWaveFeatureCarousel/UpgradeWaveFeatureCarousel";
import { FeatureCarouselPlanEnum } from "~/components/Expansion/FeatureCarousel/featureCarouselPlans";
import { ACTIVATE_CORE_TO_CONNECT_TRIAL } from "./ActivateCoreToConnectTrial.graphql";
import { messages } from "./messages";

export enum CoreToConnectTrialStartSource {
  DEEPLINKED = "deeplinked",
  AUTOTRIGGERED = "auto-triggered",
  OLB_PLE = "online-booking-ple",
  UPGRADE_PAGE = "upgrade-page",
  EXPANSION_LANDING_PAGE = "expansion-landing-page",
  CUSTOM_FIELDS_TIP_CARD = "custom-fields-tip-card",
}

export interface CoreToConnectTrialBeginModalProps {
  source: CoreToConnectTrialStartSource;
  showModal: boolean;
  dismissModal: () => void;
}

function ErrorBanner() {
  const { formatMessage: t } = useIntl();
  return (
    <Content spacing={"large"}>
      <Banner type="error" icon="alert" dismissible={false}>
        {t(messages.modalDefaultError)}
      </Banner>
    </Content>
  );
}

export function CoreToConnectTrialBeginModal({
  source,
  showModal,
  dismissModal,
}: CoreToConnectTrialBeginModalProps) {
  const [activateCoreToConnectTrial, { loading }] =
    useMutation<ActivateCoreToConnectTrialMutation>(
      ACTIVATE_CORE_TO_CONNECT_TRIAL,
      {
        variables: {
          input: {
            deeplinked: source === CoreToConnectTrialStartSource.DEEPLINKED,
          },
        },
      },
    );
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const ctaName = "core_to_connect_trial_pre_trial_modal_cta";
  const [error, setError] = useState(false);

  const { formatMessage: t } = useIntl();

  useOnMount(() => {
    if (showModal) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: ctaName,
        source,
      });
    }
  });

  return (
    <APIProvider>
      <CallToAction ref={ctaRef} ctaName={ctaName}>
        <Modal
          open={showModal}
          size="large"
          dismissible={true}
          title=""
          onRequestClose={handleModalDismissal}
          primaryAction={{
            label: t(messages.primaryButtonLabel),
            onClick: handleStartFreeTrial,
            loading: loading,
          }}
          secondaryAction={{
            label: t(messages.secondaryButtonLabel),
            onClick: handleModalDismissal,
          }}
        >
          {error && <ErrorBanner />}
          <UpgradeWaveFeatureCarousel plan={FeatureCarouselPlanEnum.Connect} />
        </Modal>
      </CallToAction>
    </APIProvider>
  );

  function handleModalDismissal() {
    dismissCTA(ctaRef, { source })();
    dismissModal();
  }

  async function handleStartFreeTrial() {
    await activateCoreToConnectTrial({
      onError() {
        setError(true);
      },
      onCompleted({ activateCoreToConnectTrial: { userErrors } }) {
        if (!userErrors.length) {
          convertCTA(ctaRef, { source })();
          window.location.href = "/home";
          dismissModal();
        } else {
          setError(true);
        }
      },
    });
  }
}
