import React from "react";
import { Button } from "@jobber/components/Button";
import classNames from "classnames";
import styles from "./ConversationPills.module.css";
import type { Followup } from "./ConversationListItem";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

interface ConversationPillsProps {
  readonly prompts: Followup[];
  readonly onClick: (prompt: PromptType) => void;
  readonly fullWidth?: boolean;
}

export function ConversationPills({
  prompts,
  onClick,
  fullWidth = false,
}: ConversationPillsProps) {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.fullWidth]: fullWidth,
      })}
      data-testid="conversation-pills"
    >
      {prompts.map(({ displayText, loading, prompt, disabled }, index) => (
        <div
          className={loading ? styles.loading : styles.notLoading}
          key={index}
        >
          <Button
            size="small"
            type="secondary"
            onClick={() => onClick({ message: prompt })}
            label={displayText}
            loading={loading}
            fullWidth={fullWidth}
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  );
}
