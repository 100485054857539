const supportBannerKey = "jobberCopilotSupportBanner";

const readSupportBannerMapFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(supportBannerKey) || "{}");
  } catch (error) {
    localStorage.setItem(supportBannerKey, "{}");
    return {};
  }
};

export const writeSupportBannerShownMap = (
  userId: string,
  conversationId: string,
) => {
  const bannerMap = readSupportBannerMapFromStorage();
  bannerMap[userId] = conversationId;
  localStorage.setItem(supportBannerKey, JSON.stringify(bannerMap));
};

export const userHasSeenSupportBanner = (
  userId: string,
  conversationId: string,
) => {
  const bannerShownMap = readSupportBannerMapFromStorage();

  return bannerShownMap[userId] === conversationId;
};
