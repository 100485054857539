import React, { useContext, useEffect, useState } from "react";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { InputGroup } from "@jobber/components/InputGroup";
import { InputText } from "@jobber/components/InputText";
import { useIntl } from "react-intl";
import type { AvailableBillingCountries } from "~/utilities/API/graphql";
import { provinces as inputProvinces } from "components/InputAddress/provinces";
import { RecurlyInputText } from "jobber/billing/components/EditBillingInfo/components/RecurlyInputText";
import type { BillingAddressType } from "jobber/billing/components/EditBillingInfo/components/BillingAddress";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import {
  type TrackingDetails,
  trackInteractedWithInput,
} from "jobber/billing/utils/trackInteractions";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import styles from "./BillingAddressEdit.module.css";
import { ProvinceSelect } from "./components/ProvinceSelect";
import { CountrySelect } from "./components/CountrySelect";
import { messages } from "./messages";

const CANADA = "Canada";
const UNITED_STATES = "United States";

const CANADIAN_PROVINCES = inputProvinces.CA as string[];
const AMERICAN_STATES = inputProvinces.US as string[];

const SUB_REGIONS: { [country: string]: string[] } = {
  [CANADA]: CANADIAN_PROVINCES,
  [UNITED_STATES]: AMERICAN_STATES,
};

export interface BillingAddressProps {
  availableBillingCountries: AvailableBillingCountries;
  billingAddress: BillingAddressType;
  trackingDetails: TrackingDetails;
  setBillingAddress: React.Dispatch<React.SetStateAction<BillingAddressType>>;
  onValidationError(error: FieldErrorState): void;
}

export function BillingAddressEdit(props: BillingAddressProps) {
  const { formatMessage } = useIntl();

  const {
    availableBillingCountries,
    billingAddress,
    trackingDetails,
    setBillingAddress,
    onValidationError,
  } = props;

  const context = useContext(PurchaseFormContext);

  const [regionList, setRegionList] = useState<string[] | undefined>(
    billingAddress.country ? SUB_REGIONS[billingAddress.country] : undefined,
  );

  useEffect(() => {
    if (context.country) {
      billingAddress.country = context.country;
      updateRegionList(context.country);
    }
    setInitialRegion(billingAddress.state);
    // This is added because we only want to set the initial region once
    // when the component is first rendered.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content>
      <InputGroup>
        <InputText
          validations={{
            required: {
              value: true,
              message: formatMessage(messages.emailAddressError),
            },
          }}
          value={billingAddress.email}
          placeholder={formatMessage(messages.emailAddressPlaceholder)}
          onChange={setBillingAddressField("email")}
          onFocus={() => {
            handleInputFocus("email");
          }}
          onValidation={message => {
            onValidationError({ field: "email", message: message });
          }}
          disabled={context.submitting}
        />
      </InputGroup>
      {!context.country && (
        <div className={styles.paymentDisclaimer}>
          <Text variation="subdued">
            {formatMessage(messages.paymentSummaryDisclaimer)}
          </Text>
        </div>
      )}
      <InputGroup flowDirection="vertical">
        <InputGroup flowDirection="horizontal">
          <div className={styles.addressRowItemLarge}>
            <CountrySelect
              placeholder={formatMessage(messages.countryPlaceholder)}
              countries={availableBillingCountries.countries || []}
              setSelected={setBillingAddressField("country")}
              isSelected={!!billingAddress.country}
              updateRegionList={updateRegionList}
              value={billingAddress.country}
              handleFocus={handleInputFocus}
              onValidationError={onValidationError}
            />
            <input
              data-recurly="country"
              value={billingAddress.country}
              hidden={true}
              readOnly={true}
              name="countryRecurly"
              data-testid="countryRecurly"
            />
          </div>
          <div className={styles.addressRowItemSmall}>
            <ProvinceSelect
              placeholder={
                billingAddress.country === CANADA
                  ? formatMessage(messages.provincePlaceholder)
                  : formatMessage(messages.statePlaceholder)
              }
              provinces={regionList}
              setSelected={setBillingAddressField("state")}
              isSelected={!!billingAddress.state}
              value={billingAddress.state}
              handleFocus={handleInputFocus}
              onValidationError={onValidationError}
            />
            <input
              data-recurly="state"
              value={billingAddress.state}
              hidden={true}
              readOnly={true}
              name="stateRecurly"
              data-testid="stateRecurly"
            />
          </div>
        </InputGroup>
        <InputGroup flowDirection="horizontal">
          <RecurlyInputText
            className={styles.addressRowItemLarge}
            inputValue={billingAddress.address1}
            inputName={formatMessage(messages.streetAddressPlaceholder)}
            dataRecurlyTag={"address1"}
            required={true}
            handleFocus={handleInputFocus}
            setSelected={setBillingAddressField("address1")}
            onValidationError={onValidationError}
          />
          <RecurlyInputText
            className={styles.addressRowItemSmall}
            inputValue={billingAddress.address2}
            inputName={formatMessage(messages.unitPlaceholder)}
            dataRecurlyTag={"address2"}
            required={false}
            handleFocus={handleInputFocus}
            setSelected={setBillingAddressField("address2")}
            onValidationError={onValidationError}
          />
        </InputGroup>
        <InputGroup flowDirection="horizontal">
          <RecurlyInputText
            className={styles.addressRowItemLarge}
            inputValue={billingAddress.city}
            inputName={formatMessage(messages.cityPlaceholder)}
            dataRecurlyTag={"city"}
            required={true}
            handleFocus={handleInputFocus}
            setSelected={setBillingAddressField("city")}
            onValidationError={onValidationError}
          />
          <RecurlyInputText
            className={styles.addressRowItemSmall}
            inputValue={billingAddress.zip}
            inputName={
              billingAddress.country === CANADA
                ? formatMessage(messages.postalCodePlaceholder)
                : formatMessage(messages.zipCodePlaceholder)
            }
            dataRecurlyTag={"postal_code"}
            required={true}
            handleFocus={handleInputFocus}
            setSelected={setBillingAddressField("zip")}
            onValidationError={onValidationError}
          />
        </InputGroup>
      </InputGroup>
    </Content>
  );

  function updateRegionList(selectedCountry?: string): void {
    setRegionList(selectedCountry ? SUB_REGIONS[selectedCountry] : undefined);
  }

  function setInitialRegion(storedState?: string) {
    if (!regionList) {
      return setBillingAddressField("state")(storedState || "");
    }

    const matchingRegion = regionList?.find(
      region => region.toLowerCase() === storedState?.toLowerCase(),
    );

    return matchingRegion
      ? setBillingAddressField("state")(matchingRegion)
      : setBillingAddressField("state")("");
  }

  function setBillingAddressField(field: keyof BillingAddressType) {
    return (value: string) => {
      setBillingAddress({
        ...billingAddress,
        [field]: value,
      });
    };
  }

  function handleInputFocus(inputName: string): void {
    const eventInputMapping: { [input: string]: string } = {
      address1: "street1",
      address2: "street2",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      postal_code: "pc",
    };

    trackInteractedWithInput({
      ...trackingDetails,
      input: eventInputMapping[inputName] || inputName,
    });
  }
}
