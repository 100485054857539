import React, { type ReactNode } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { Box } from "@jobber/components";
import { AddonPurchaseModal } from "jobber/billing/features/AddonPurchaseModal";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { AddonDetails } from "./components/AddonDetails";
import styles from "./SubscriptionAddonCard.module.css";
import { RemoveAddonModal } from "./components/RemoveAddonModal";
import { messages } from "./messages";

interface SubscriptionAddonCardProps {
  subscriptionAddon: SubscriptionAddon;
  recurlyPublicKey?: string;
  inactiveCardAction?: ReactNode;
}

export function SubscriptionAddonCard(props: SubscriptionAddonCardProps) {
  const { recurlyPublicKey, subscriptionAddon, inactiveCardAction } = props;
  const {
    isActive,
    isCancelled,
    name,
    currentBillingCycle,
    identifier,
    includedAddonSetIdentifiers,
    isResubscribeDisabled,
  } = subscriptionAddon;

  const { formatMessage } = useIntl();

  return (
    <Box
      background={isActive ? "surface--background--subtle" : "surface"}
      border="base"
      radius="base"
      padding="base"
      height="100%"
    >
      <CardHeader title={name}>
        {isActive
          ? renderActiveCardAction()
          : renderInactiveCardAction(formatMessage(messages.add))}
      </CardHeader>
      <Content>
        <div className={styles.cardBody}>
          <AddonDetails subscriptionAddon={subscriptionAddon} />
        </div>
      </Content>
    </Box>
  );

  function renderActiveCardAction() {
    if (!currentBillingCycle) {
      return null;
    }

    return (
      <RemoveAddonModal
        addonIdentifier={identifier}
        addonName={name}
        addonCode={currentBillingCycle.addonCode}
        includedAddonSetIdentifiers={includedAddonSetIdentifiers}
        isCancelled={isCancelled}
        isResubscribeDisabled={isResubscribeDisabled}
      />
    );
  }

  function renderInactiveCardAction(buttonText: string) {
    if (inactiveCardAction) {
      return inactiveCardAction;
    }

    if (!recurlyPublicKey) {
      return null;
    }

    return (
      <AddonPurchaseModal
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={identifier}
        buttonProps={{
          buttonText: buttonText,
          ariaLabel: formatMessage(messages.addAriaLabel, { addonName: name }),
          buttonType: "tertiary",
        }}
      />
    );
  }
}

function CardHeader({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <div className={styles.cardHeader}>
      <div className={styles.headerAndDiscountContainer}>
        <Heading level={4}>{title}</Heading>
      </div>
      {children}
    </div>
  );
}
