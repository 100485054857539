import classNames from "classnames";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useReducer } from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  type AreaDetailsFragment,
  type CustomFieldConfigurationNodeFragment,
  CustomFieldConfigurationValueType,
  type DropdownDetailsFragment,
  type NumericDetailsFragment,
  type TextDetailsFragment,
  type TrueFalseDetailsFragment,
} from "~/utilities/API/graphql";
import { AddCustomFieldButton } from "./AddCustomFieldButton";
import type { CustomFieldValue } from "./CustomField";
import { CustomFieldGroup } from "./CustomFieldGroup";
import styles from "./CustomFieldGroups.module.css";
import {
  type ConfigurationState,
  type CustomFieldsValueType,
  type GroupState,
  type RailsCustomFieldGroup,
  initReducerFromRailsCustomFieldGroups,
  reducer,
} from "./customFieldReducer";
import { LoadError } from "./LoadError";
import {
  type AttachedToClassNames,
  handleMutationObserver,
  syncHiddenInputsToReducerState,
  syncReducerStateToHiddenInputs,
} from "./railsBridgeHelpers";

export interface CustomFieldGroupsProps {
  // The three `rails*` props support the Rails/React bridge.
  railsCustomFieldGroups: RailsCustomFieldGroup[];
  railsHiddenInputContainerId?: string;
  railsMutationObserverSelectors?: string[];
  railsIsCompanyAdmin?: boolean;
  hideCreateButton?: boolean;
  showReadOnlyForm?: boolean;
  showTipCard?: boolean;
  attachedToClassName?: AttachedToClassNames;
  createForm?: boolean;
  shouldAddPropertyToPciClass?: boolean;
}

function getConfigurationIdFromResponse(idFromResponse: string): number {
  const decodedPossiblyGlobalId = atob(idFromResponse);
  const matches = decodedPossiblyGlobalId.match(/[0-9]*$/) ?? [];
  if (matches.length !== 1) {
    throw new Error("Unable to parse custom field configuration ID.");
  }

  return Number(matches[0]);
}

const graphQLTypeToReducerType: {
  [key in keyof typeof CustomFieldConfigurationValueType]: CustomFieldsValueType;
} = {
  [CustomFieldConfigurationValueType.AREA]: "area",
  [CustomFieldConfigurationValueType.DROPDOWN]: "select",
  [CustomFieldConfigurationValueType.LINK]: "link",
  [CustomFieldConfigurationValueType.NUMERIC]: "int",
  [CustomFieldConfigurationValueType.TEXT]: "text",
  [CustomFieldConfigurationValueType.TRUE_FALSE]: "bool",
};

function getReducerTypeForGraphQLType(
  graphQLType: CustomFieldConfigurationValueType,
): CustomFieldsValueType {
  return graphQLTypeToReducerType[graphQLType];
}

function buildConfigurationState(
  configuration: CustomFieldConfigurationNodeFragment,
): ConfigurationState {
  let customFieldValue: CustomFieldValue;

  switch (configuration.valueType) {
    case CustomFieldConfigurationValueType.AREA:
      customFieldValue = {
        length: (configuration as AreaDetailsFragment).areaDefaultValue.length,
        width: (configuration as AreaDetailsFragment).areaDefaultValue.width,
        unit: (configuration as AreaDetailsFragment).unit,
      };
      break;
    case CustomFieldConfigurationValueType.DROPDOWN:
      customFieldValue = {
        selection: (configuration as DropdownDetailsFragment)
          .dropdownDefaultValue,
        options: (configuration as DropdownDetailsFragment).dropdownOptions,
      };
      break;
    case CustomFieldConfigurationValueType.LINK:
      // Unsupported.
      customFieldValue = {
        text: "",
        url: "",
      };
      break;
    case CustomFieldConfigurationValueType.NUMERIC:
      customFieldValue = {
        number: (configuration as NumericDetailsFragment).numericDefaultValue,
        unit: (configuration as NumericDetailsFragment).unit,
      };
      break;
    case CustomFieldConfigurationValueType.TEXT:
      customFieldValue = (configuration as TextDetailsFragment)
        .textDefaultValue;
      break;
    case CustomFieldConfigurationValueType.TRUE_FALSE:
      customFieldValue = (configuration as TrueFalseDetailsFragment)
        .booleanDefaultValue;
      break;
    default:
      customFieldValue = {
        text: "",
        url: "",
      };
  }

  return {
    configurationId: getConfigurationIdFromResponse(configuration.id),
    label: configuration.name,
    readOnly: false, // False => an Edit component when an edit form.
    stringValue: "", // For the Edit components, it doesn't matter.
    type: getReducerTypeForGraphQLType(configuration.valueType),
    value: customFieldValue,
  };
}

export function CustomFieldGroups({
  railsCustomFieldGroups,
  railsHiddenInputContainerId,
  railsMutationObserverSelectors,
  railsIsCompanyAdmin,
  hideCreateButton,
  showReadOnlyForm,
  showTipCard,
  attachedToClassName,
  createForm = false,
  shouldAddPropertyToPciClass = false,
}: CustomFieldGroupsProps) {
  const [state, dispatch] = useReducer(
    reducer,
    railsCustomFieldGroups,
    initReducerFromRailsCustomFieldGroups,
  );

  function valueUpdateHandler(
    configurationId: number,
    value: CustomFieldValue,
  ) {
    dispatch({
      type: "Replace Value",
      configurationId: configurationId,
      value: value,
    });
  }

  function updateShowTipCard(value: boolean) {
    dispatch({
      type: "Set Show Tip Card",
      configurationId: 0,
      value: value,
    });
  }

  function onCreateCustomFieldConfiguration(
    configuration: CustomFieldConfigurationNodeFragment,
  ) {
    const configurationState = buildConfigurationState(configuration);

    dispatch({
      type: "Add New Custom Field",
      configurationId: getConfigurationIdFromResponse(configuration.id),
      configurationState: configurationState,
    });
  }

  useEffect(() => {
    if (showTipCard) {
      // Not configuration specific -- provide any integer value.
      dispatch({ type: "Set Show Tip Card", configurationId: 0, value: true });
    }

    if (
      railsHiddenInputContainerId === undefined ||
      attachedToClassName === undefined
    ) {
      // Without a hidden input container, the component can only be used in a read-only
      // configuration.
      return;
    }

    syncHiddenInputsToReducerState(
      railsHiddenInputContainerId,
      state,
      dispatch,
    );

    const observer = new MutationObserver(() => {
      const asyncHandler = async () => {
        await handleMutationObserver(dispatch, attachedToClassName);
      };

      asyncHandler().catch(() => {
        // Not configuration specific -- provide any integer value.
        dispatch({ type: "Set Show Load Error", configurationId: 0 });
      });
    });

    if (railsMutationObserverSelectors === undefined) {
      return;
    }

    railsMutationObserverSelectors.forEach(mutationObserverSelector => {
      const mutationObserverElement = document.querySelector(
        mutationObserverSelector,
      ) as HTMLInputElement;

      if (mutationObserverElement !== null) {
        observer.observe(mutationObserverElement, { attributes: true });
      }
    });
  }, []);

  const syncReducerStateToHiddenInputsDebounced = useCallback(
    debounce(syncReducerStateToHiddenInputs, 100),
    [],
  );

  useEffect(() => {
    if (railsHiddenInputContainerId === undefined) {
      return;
    }

    syncReducerStateToHiddenInputsDebounced(
      state,
      railsHiddenInputContainerId,
      shouldAddPropertyToPciClass,
    );
  }, [
    syncReducerStateToHiddenInputsDebounced,
    railsHiddenInputContainerId,
    state,
  ]);

  let displayLineBreak = false;
  return (
    <APIProvider>
      <>
        {state.allGroups.map((groupKey: string) => {
          // For app groups we do not want to display a line break above so we set this to false.
          if (state.byGroup[groupKey].appTitle) {
            displayLineBreak = false;
          }

          const customFieldGroup = shouldRenderCustomFieldGroup(
            state.byGroup[groupKey],
            createForm,
          ) ? (
            <div
              key={groupKey}
              className={classNames({
                [styles.customFieldGroups]: true,
                [styles.lineBreak]: displayLineBreak,
              })}
            >
              <CustomFieldGroup
                groupState={state.byGroup[groupKey]}
                valueUpdateHandler={valueUpdateHandler}
                showReadOnlyForm={showReadOnlyForm || false}
                createForm={createForm}
              />
            </div>
          ) : (
            <></>
          );

          // For app groups we do not want to display a line below so we keep it false in case
          // the next group isn't an app group
          displayLineBreak = !state.byGroup[groupKey].appTitle;

          return customFieldGroup;
        })}

        {state.showLoadError && <LoadError />}
        {!hideCreateButton && !showReadOnlyForm && railsIsCompanyAdmin && (
          <AddCustomFieldButton
            showTipCard={state.showTipCard}
            setShowTipCard={updateShowTipCard}
            attachedToClassName={attachedToClassName ?? "Client"}
            onCreateCustomFieldConfiguration={onCreateCustomFieldConfiguration}
          />
        )}
      </>
    </APIProvider>
  );
}

// If the the custom field group:
// 1. Is for an app
// 2. Has only read-only custom fields
// 3. Is for a create form
// Then we do not want to render the group at all
function shouldRenderCustomFieldGroup(
  groupState: GroupState,
  createForm: boolean,
) {
  const allFieldsReadonly = groupState.allConfigurations.every(
    configurationKey => {
      return groupState.byConfiguration[configurationKey].readOnly;
    },
  );

  return !groupState.appTitle || !allFieldsReadonly || !createForm;
}
