import React from "react";
import { useIntl } from "react-intl";
import { BottomBar } from "jobber/campaigns/components/Bottombar/BottomBar";
import {
  CampaignsBreadCrumb,
  breadCrumbMessages,
} from "jobber/campaigns/components/Breadcrumbs";
import { ErrorBanner } from "jobber/campaigns/components/ErrorBanner";
import { messages } from "./messages";
import styles from "./RecipientsWrapper.module.css";

interface RecipientsWrapperProps {
  children: React.ReactNode;
  onNext: () => void;
  onCancel: () => void;
  onCancelLabel: string;
  isInErrorState: boolean;
  loading: boolean;
}
export function RecipientsWrapper({
  children,
  onNext,
  onCancel,
  onCancelLabel,
  isInErrorState,
  loading,
}: RecipientsWrapperProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <CampaignsBreadCrumb
        currentStep={formatMessage(breadCrumbMessages.recipientsLabel)}
        onBack={onCancel}
      />
      <div className={styles.segmentsPageContainer}>
        <ErrorBanner
          isVisible={isInErrorState}
          bottomBar={false}
          errorMessage={formatMessage(messages.errorSavingClientSegment)}
        />

        {children}
      </div>
      <BottomBar
        loading={loading}
        onNext={onNext}
        onCancel={onCancel}
        onCancelLabel={onCancelLabel}
        step={2}
      />
    </div>
  );
}
