import { defineMessages } from "react-intl";

export const messages = defineMessages({
  paymentOptionsCardTitle: {
    id: "managed_accounts.settings.paymentOptionsCardTitle",
    defaultMessage: "Payment options",
    description: "Payment options card title",
  },
  cardPaymentTitle: {
    id: "managed_accounts.settings.cardPaymentTitle",
    defaultMessage: "Card payments",
    description: "Title for card payments setting group",
  },
  achPaymentsSectionTitle: {
    id: "managed_accounts.settings.achPaymentsTitle",
    defaultMessage: "ACH bank payments",
    description: "Title for ACH payments setting group",
  },
  achPaymentsLimitsDisclaimer: {
    id: "managed_accounts.settings.achPaymentsLimitsDisclaimer",
    defaultMessage:
      "When exceeded, we'll temporarily disable the ACH bank payment option on invoices and quotes",
    description: "ACH payments limits disclaimer",
  },
  amexEnabledToggleTitle: {
    id: "managed_accounts.settings.amexEnabledToggleTitle",
    defaultMessage: "Accept American Express payments",
    description: "Title for American Express payments toggle",
  },
  feeRateTag: {
    id: "managed_accounts.settings.feeRateTag",
    defaultMessage: "Rate: ",
    description: "Identifier tag for fee rate",
  },
  AchLimitsTag: {
    id: "managed_accounts.settings.AchLimitsTag",
    defaultMessage: "Limits: ",
    description: "Identifier tag for ACH limits",
  },
  creditCardFeeRateDetail: {
    id: "managed_accounts.settings.creditCardFeeRateDetail",
    defaultMessage: "{percent}% + {centsPerCharge}{centCurrency} / transaction",
    description: "Credit card fee rate detail",
  },
  achFeeRateDetail: {
    id: "managed_accounts.settings.achFeeRateDetail",
    defaultMessage: "{percent}% / transaction",
    description: "ACH fee rate detail",
  },
  achUsageAndLimitsSectionTitle: {
    id: "managed_accounts.settings.ACHUsageSectionTitle",
    defaultMessage: "ACH usage and limits",
    description: "ACH usage and limits section title",
  },
});
