import { defineMessages } from "react-intl";

export const messages = defineMessages({
  edit: {
    id: "campaigns.useClientSegmentOptions.edit",
    defaultMessage: "Edit",
    description: "Edit",
  },
  allClientsTitle: {
    id: "campaigns.useClientSegmentOptions.allClientsTitle",
    defaultMessage: "All clients",
    description: "Title for the all clients segment card",
  },
  allClientsDescription: {
    id: "campaigns.useClientSegmentOptions.allClientsDescription",
    defaultMessage:
      "All clients that you haven't archived {additionalCriteriaCount, plural, =0 {} other {+ {additionalCriteriaCount} additional criteria}}",
    description: "Description for the all clients segment card",
  },
  upcomingVisitClientsTitle: {
    id: "campaigns.useClientSegmentOptions.upcomingVisitClientsTitle",
    defaultMessage: "Clients with upcoming visits",
    description: "Title for the upcoming visit clients segment card",
  },
  upcomingVisitClientsDescription: {
    id: "campaigns.useClientSegmentOptions.upcomingVisitClientsDescription",
    defaultMessage:
      "Clients with at least one visit within the next {interval} {unit}(s) {additionalCriteriaCount, plural, =0 {} other {+ {additionalCriteriaCount} additional criteria}}",
    description: "Description for the upcoming visit clients segment card",
  },
  pastClientsTitle: {
    id: "campaigns.useClientSegmentOptions.pastClientsTitle",
    defaultMessage: "Past clients",
    description: "Title for the past clients segment card",
  },
  pastClientsDescription: {
    id: "campaigns.useClientSegmentOptions.pastClientsDescription",
    defaultMessage:
      "Clients with last job completed more than {interval} {unit}(s) ago, with no upcoming jobs scheduled {additionalCriteriaCount, plural, =0 {} other {+ {additionalCriteriaCount} additional criteria}}",
    description: "Description for the past clients segment card",
  },
  clients: {
    id: "campaigns.useClientSegmentOptions.clients",
    defaultMessage:
      "{totalCount} {totalCount, plural, one {client} other {clients}}",
    description: "clients label",
  },
});
