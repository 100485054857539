import {
  Avatar,
  Emphasis,
  Flex,
  Heading,
  Icon,
  Text,
} from "@jobber/components";
import React from "react";
import { Switch } from "@jobber/components/Switch";
import { useIntl } from "react-intl";
import { useOnMount } from "@jobber/hooks/useOnMount";
import QuoteImage from "@images/marketingSuiteExpansion/checkoutBanner/Stephen_Jobe.jpg";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import { AddonDetails } from "~/jobber/billing/components/SubscriptionAddonCard/components/AddonDetails";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./MarketingSuiteAddon.module.css";
import { messages } from "./messages";

export const INTERACTED_WITH_EXPERIMENT = "Interacted with Experiment";

export function MarketingSuiteAddonBanner({
  subscriptionAddons,
  selectedAddonCodes,
  setSelectedAddonCodes,
  checkoutExpVariation,
}: {
  subscriptionAddons: SubscriptionAddonPreview[];
  setSelectedAddonCodes: React.Dispatch<React.SetStateAction<string[]>>;
  selectedAddonCodes: string[];
  checkoutExpVariation: string;
}) {
  const { formatMessage } = useIntl();
  const marketingSuiteAdded = selectedAddonCodes?.includes(
    "monthly_marketing_suite",
  );
  const marketingSuiteAddon = subscriptionAddons.find(
    addons => addons.name === "Marketing Suite",
  );
  const isOnQuote = checkoutExpVariation === "on_quote";
  const compactExperimentVariation = checkoutExpVariation === "compact";
  const source = compactExperimentVariation
    ? "compact"
    : isOnQuote
      ? "quote"
      : "data";

  useOnMount(() => {
    Amplitude.TRACK_EVENT(INTERACTED_WITH_EXPERIMENT, {
      experiment: "checkout_marketing_suite_banner",
      interaction: "viewed",
      source: source,
    });
  });

  const handleAddMarketingSuite = () => {
    if (!marketingSuiteAdded) {
      // Since we only have marketing addons and no other addons, and this button only adds the entire suite,
      // we set the selectedAddonCodes to the suite code so that there is no way to add individual addons and the suite.
      setSelectedAddonCodes(["monthly_marketing_suite"]);
      Amplitude.TRACK_EVENT(INTERACTED_WITH_EXPERIMENT, {
        experiment: "checkout_marketing_suite_banner",
        interaction: "added marketing suite",
        source: source,
      });
    } else {
      setSelectedAddonCodes(
        selectedAddonCodes.filter(code => code !== "monthly_marketing_suite"),
      );
      Amplitude.TRACK_EVENT(INTERACTED_WITH_EXPERIMENT, {
        experiment: "checkout_marketing_suite_banner",
        interaction: "removed marketing suite",
        source: source,
      });
    }
  };

  return (
    <div className={styles.marketingSuiteAddonContainer}>
      <Flex template={["grow", "shrink"]} direction="row" gap="base">
        <Flex template={["shrink", "grow"]} direction="column" gap="smallest">
          <Heading level={3}>{formatMessage(messages.title)}</Heading>
          {marketingSuiteAddon && (
            <Flex template={["shrink", "grow"]} direction="column" gap="small">
              <Text variation="subdued">
                {formatMessage(messages.description)}
              </Text>
              <AddonDetails
                subscriptionAddon={marketingSuiteAddon}
                omitDescription={true}
              />
            </Flex>
          )}
        </Flex>
        <div className={styles.switch}>
          <Flex template={["shrink", "shrink"]} direction="row" gap="small">
            <Emphasis variation={"highlight"}>
              <Heading level={4}>{formatMessage(messages.addLabel)}</Heading>
            </Emphasis>
            <Switch
              ariaLabel={formatMessage(messages.addLabel)}
              onChange={handleAddMarketingSuite}
              value={marketingSuiteAdded}
            />
          </Flex>
        </div>
      </Flex>
      <Flex template={["grow", "grow", "grow"]}>
        <Addon
          title={formatMessage(messages.campaignsAddonTitle)}
          description={formatMessage(messages.campaignsAddonDescription)}
        />
        <Addon
          title={formatMessage(messages.reviewsAddonTitle)}
          description={formatMessage(messages.reviewsAddonDescription)}
        />
        <Addon
          title={formatMessage(messages.referralsAddonTitle)}
          description={formatMessage(messages.referralsAddonDescription)}
        />
      </Flex>
      <Testimonial isOnQuote={isOnQuote} />
    </div>
  );
}

function Addon({ title, description }: { title: string; description: string }) {
  return (
    <Flex template={["grow"]} direction="column" gap="smallest" align="center">
      <Text size="large" align="center">
        <Emphasis variation="bold">{title}</Emphasis>
      </Text>
      <Text align="center">{description}</Text>
    </Flex>
  );
}

function Testimonial({ isOnQuote }: { isOnQuote: boolean }) {
  const { formatMessage } = useIntl();

  if (!isOnQuote) {
    return (
      <div className={styles.businessData}>
        <Icon name="starburst" size="large" />
        <Text size="large">{formatMessage(messages.businessData)}</Text>
      </div>
    );
  }

  return (
    <div className={styles.testimonial}>
      <Flex template={["grow", "shrink"]}>
        <Text size="large">
          <Emphasis variation="italic">
            {formatMessage(messages.testimonialQuote, {
              quote: messages.testimonialQuote.defaultMessage,
            })}
          </Emphasis>
        </Text>
        <Flex template={["grow"]} direction="row" align="center" gap="smallest">
          <div className={styles.avatar}>
            <Avatar size="base" imageUrl={QuoteImage}></Avatar>
          </div>
          <Text align="center" size="base">
            <Emphasis variation="bold">
              {messages.testimonialQuote.author}
            </Emphasis>
          </Text>
          <Text align="center" size={"small"}>
            {messages.testimonialQuote.company}
          </Text>
        </Flex>
      </Flex>
    </div>
  );
}
