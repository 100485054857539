import { gql } from "@apollo/client";

export const OPEN_SUPPORT_CONVERSATIONS = gql`
  query OpenSupportConversations {
    openSupportConversations {
      intercomConversations {
        conversationId
      }
    }
  }
`;
