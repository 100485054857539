import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { debounce } from "lodash";
import { Glimmer } from "@jobber/components/Glimmer";
import type {
  IntervalUnits,
  PastClientsCriteriaInput,
} from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { useIntervalValidation } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/hooks/useIntervalValidation/useIntervalValidation";
import { BaseCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/components/BaseCriteria/BaseCriteria";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { AdditionalCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria";
import { SEGMENT_CLIENT_DEBOUNCE_TIME } from "jobber/campaigns/constants";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import { messages } from "./messages";
import { INTERVAL_UNIT_OPTIONS } from "./constants";

export interface PastClientsSegmentEditProps {
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    value: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  pageSize?: number;
  openSideDrawer?: () => void;
}

const clientSegmentDataConditionalVariables = {
  isPastClientDrawer: true,
};

export function PastClientsSegmentEdit({
  additionalCriteria,
  updateAdditionalCriteria,
  openSideDrawer,
  pageSize = 20,
}: PastClientsSegmentEditProps): JSX.Element {
  const { formatMessage } = useIntl();

  const { validationMessage, setValidationMessage, validateInterval } =
    useIntervalValidation();

  const {
    campaignSegment: {
      isSegmentCriteriaFormValid,
      pastClientsSegmentCriteria,
      setPastClientsSegmentCriteria,
      isDirty,
    },
  } = useCampaignWizardContext();

  const interval = pastClientsSegmentCriteria.interval;
  const unit = pastClientsSegmentCriteria.unit;
  const setInterval = (newInterval: number) =>
    setPastClientsSegmentCriteria(
      { ...pastClientsSegmentCriteria, interval: newInterval },
      true,
    );
  const setUnit = (newUnit: IntervalUnits) =>
    setPastClientsSegmentCriteria(
      { ...pastClientsSegmentCriteria, unit: newUnit },
      true,
    );

  const isFormValid = useCallback(() => {
    return (
      (!isDirty || isSegmentCriteriaFormValid) &&
      validateInterval(interval) === true
    );
  }, [interval, isSegmentCriteriaFormValid, validateInterval, isDirty]);

  const {
    refetch: fetchData,
    fullReload,
    clientSegmentsData,
  } = useClientSegmentData({
    conditionalVariables: clientSegmentDataConditionalVariables,
  });

  const debounceFetchData = useMemo(
    () =>
      debounce((newSegmentCriteria: PastClientsCriteriaInput) => {
        const {
          clientTagCriteria,
          lineItemCriteria,
          jobTypeCriteria,
          ...newCriteria
        } = newSegmentCriteria;
        fetchData({
          first: pageSize,
          after: btoa("0"),
          ...{
            ...newCriteria,
            pastClientTagCriteria: clientTagCriteria,
            pastClientLineItemCriteria: lineItemCriteria,
            pastClientJobTypeCriteria: jobTypeCriteria,
          },
        });
      }, SEGMENT_CLIENT_DEBOUNCE_TIME),
    [fetchData, pageSize],
  );

  useEffect(() => {
    if (unit && interval && isFormValid()) {
      debounceFetchData({
        unit,
        interval,
        clientTagCriteria:
          parseAdditionalCriteria(additionalCriteria).clientTagCriteria,
        lineItemCriteria:
          parseAdditionalCriteria(additionalCriteria).lineItemCriteria,
        jobTypeCriteria:
          parseAdditionalCriteria(additionalCriteria).jobTypeCriteria,
      });
    }
  }, [
    unit,
    interval,
    validationMessage,
    debounceFetchData,
    isFormValid,
    additionalCriteria,
  ]);

  return (
    <Content spacing="base">
      <Content>
        <BaseCriteria
          segmentCriteria={{ unit, interval }}
          setInterval={setInterval}
          validationMessage={validationMessage}
          setValidationMessage={setValidationMessage}
          validateInterval={validateInterval}
          setUnit={setUnit}
          intervalUnitOptions={INTERVAL_UNIT_OPTIONS}
          preFieldsDescription={formatMessage(messages.pastClientsDescription1)}
          postFieldsDescription={formatMessage(
            messages.pastClientsDescription2,
          )}
        />
        <AdditionalCriteria
          criteria={additionalCriteria}
          updateAdditionalCriteria={(value: AdditionalCriteriaReducerActions) =>
            updateAdditionalCriteria(value, true)
          }
        />
      </Content>
      <Text>
        {fullReload ? (
          <Glimmer.Text />
        ) : (
          <TextActionButton
            label={formatMessage(messages.clients, {
              totalCount: clientSegmentsData.pastClients?.total,
            })}
            onClick={openSideDrawer}
          />
        )}
      </Text>
    </Content>
  );
}
