import { defineMessages } from "react-intl";

export const messages = defineMessages({
  intervalInputWholeNumberError: {
    id: "campaigns.clientSegmentPage.intervalInput.wholeNumberError",
    defaultMessage: "Enter a whole number",
    description: "Inline error shown when the input is a decimal number",
  },
  intervalInputBetweenMinAndMaxError: {
    id: "campaigns.clientSegmentPage.intervalInput.betweenMinAndMax",
    defaultMessage: "Enter a number between {min} and {max}",
    description:
      "Inline error shown when the input is not populated, is below the minimum, or above the maximum",
  },
});
