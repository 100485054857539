import { set, startOfDay } from "date-fns";

export function setTimeToMidnightOnDate(date: Date): Date {
  return startOfDay(date);
}

export const combineDateTime = (
  date?: Date | undefined,
  time?: Date | undefined,
) => {
  return date && time
    ? set(date, {
        hours: time?.getHours(),
        minutes: time.getMinutes(),
        seconds: time.getSeconds(),
        milliseconds: time.getMilliseconds(),
      })
    : undefined;
};
