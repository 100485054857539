import React from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { showToast } from "@jobber/components/Toast";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import styles from "./BottomBar.module.css";
import { messages } from "./messages";

export interface BottomBarProps {
  onCancel: () => void;
  onNext?: () => void;
  onSaveDraft?: () => Promise<string>;
  step: number;
  loading?: boolean;
  isFromEmailValidated?: boolean;
  onCancelLabel?: string;
  children?: React.ReactNode;
}

export function BottomBar({
  onCancel,
  onNext,
  onSaveDraft,
  step: currentStep,
  loading,
  isFromEmailValidated,
  onCancelLabel,
  children,
}: BottomBarProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { mediumAndUp } = useBreakpoints();
  const { getBottomBarCTA } = useCampaignUpsellSplit();

  function saveDraft() {
    if (onSaveDraft) {
      void onSaveDraft()
        .then(() => {
          showToast({
            message: formatMessage(messages.savedDraftSuccessToast),
            variation: "success",
          });
        })
        .catch(() => undefined);
    }
  }

  const ctaLabel = getBottomBarCTA(currentStep, loading, isFromEmailValidated);

  const displaySaveDraftButton =
    ctaLabel === formatMessage(messages.reviewCampaign);

  const nextButton = () => {
    return (
      onNext && (
        <Button
          label={ctaLabel}
          onClick={onNext}
          type={"primary"}
          loading={loading}
        />
      )
    );
  };

  return (
    <div
      className={styles.bottomBarContainer}
      data-testid="bottom-bar-container"
    >
      {!mediumAndUp && (
        <div
          className={
            !displaySaveDraftButton && !onNext
              ? styles.bottomBarMedAndDownNoButtons
              : styles.bottomBarMedAndDown
          }
        >
          {displaySaveDraftButton && (
            <div className={styles.mobileButtons}>
              <Button
                label={formatMessage(messages.saveDraft)}
                onClick={saveDraft}
                variation={"work"}
                type={"tertiary"}
                fullWidth={true}
                loading={loading}
                id={"addons-save-draft-button"}
              />
            </div>
          )}
          <div className={styles.mobileButtons}>
            {children ? children : nextButton()}
          </div>
        </div>
      )}
      {mediumAndUp && (
        <div className={styles.bottomBarMedAndUp}>
          <Button
            label={getBackLabel()}
            onClick={onCancel}
            type={"primary"}
            variation={"subtle"}
            loading={loading}
          />
          <div className={styles.bottomRightBarMedAndUp}>
            {displaySaveDraftButton && (
              <Button
                label={formatMessage(messages.saveDraft)}
                onClick={saveDraft}
                variation={"work"}
                type={"tertiary"}
                loading={loading}
                id={"addons-save-draft-button"}
              />
            )}
            {children ? children : nextButton()}
          </div>
        </div>
      )}
    </div>
  );

  function getBackLabel() {
    if (onCancelLabel) return onCancelLabel;
    return currentStep === 1
      ? formatMessage(messages.cancelButton)
      : formatMessage(messages.backButton);
  }
}
