import { gql } from "@apollo/client";

export const MICRO_SURVEY_FEEDBACK_CREATE_MUTATION = gql`
  mutation MicroSurveyFeedbackCreate($input: MicroSurveyFeedbackCreateInput!) {
    microSurveyFeedbackCreate(input: $input) {
      feedback {
        description
        rating
        feature
      }
      userErrors {
        message
      }
    }
  }
`;
