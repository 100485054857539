import { useLazyQuery } from "@apollo/client";
import { OPEN_SUPPORT_CONVERSATIONS } from "jobber/helpDrawer/hooks/OpenSupportConversations.graphql";
import type {
  OpenSupportConversationsQuery,
  OpenSupportConversationsQueryVariables,
} from "~/utilities/API/graphql";

export const useOpenSupportConversations = () => {
  const [openSupportConversations, { loading }] = useLazyQuery<
    OpenSupportConversationsQuery,
    OpenSupportConversationsQueryVariables
  >(OPEN_SUPPORT_CONVERSATIONS, { fetchPolicy: "network-only" });

  const intercomConversationIds = async () => {
    let conversationIds: string[] = [];

    const queryResult = await openSupportConversations();
    const intercomConversations =
      queryResult?.data?.openSupportConversations.intercomConversations;
    if (intercomConversations && intercomConversations.length > 0) {
      conversationIds = intercomConversations.map(
        conversation => conversation.conversationId,
      );
    }

    return conversationIds;
  };

  return {
    intercomConversationIds,
    loading,
  };
};
