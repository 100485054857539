import { useCallback, useMemo, useState } from "react";
import { interactedWithClientSegmentAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import type { ClientsCriteriaFromSegment } from "jobber/campaigns/utils/segmentCriteriaUtils";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { Segment } from "~/utilities/API/graphql";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";

export enum DrawerMode {
  View = "VIEW",
  Edit = "EDIT",
}

export interface UseClientSegmentSideDrawer {
  isOpen: boolean;
  openSideDrawer: (name: string) => void;
  closeSideDrawer: () => void;
  segmentName: string;
  segmentCriteria: ClientsCriteriaFromSegment;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
  additionalCriteria: AdditionalCriteriaUnion[];
}

interface SegmentCriteriaSwitch {
  segmentCriteria: ClientsCriteriaFromSegment;
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
}

export function useClientSegmentSideDrawer(): UseClientSegmentSideDrawer {
  const [isOpen, setIsOpen] = useState(false);
  const [segmentName, setSegmentName] = useState("Past clients");

  const {
    campaignSegment: {
      updateAllClientsAdditionalCriteria,
      updatePastClientsAdditionalCriteria,
      allClientsAdditionalCriteria,
      pastClientsAdditionalCriteria,
      upcomingClientsSegmentCriteria,
      upcomingClientsAdditionalCriteria,
      selectedSegmentType,
      allClientsSegmentCriteria,
      updateUpcomingClientsAdditionalCriteria,
      pastClientsSegmentCriteria,
    },
  } = useCampaignWizardContext();

  const openSideDrawer = useCallback((name: string) => {
    setIsOpen(true);
    setSegmentName(name);
    interactedWithClientSegmentAmplitudeEvent({
      interaction: "Drawer Viewed",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      segment_type: name,
    });
  }, []);

  const closeSideDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const { additionalCriteria, segmentCriteria, updateAdditionalCriteria } =
    useMemo((): SegmentCriteriaSwitch => {
      switch (selectedSegmentType) {
        case Segment.ALL_CLIENTS:
          return {
            segmentCriteria: allClientsSegmentCriteria,
            additionalCriteria: allClientsAdditionalCriteria,
            updateAdditionalCriteria: updateAllClientsAdditionalCriteria,
          };
        case Segment.PAST_CLIENTS:
          return {
            segmentCriteria: pastClientsSegmentCriteria,
            additionalCriteria: pastClientsAdditionalCriteria,
            updateAdditionalCriteria: updatePastClientsAdditionalCriteria,
          };
        case Segment.UPCOMING_CLIENTS:
          return {
            segmentCriteria: upcomingClientsSegmentCriteria,
            additionalCriteria: upcomingClientsAdditionalCriteria,
            updateAdditionalCriteria: updateUpcomingClientsAdditionalCriteria,
          };
      }
    }, [
      allClientsAdditionalCriteria,
      allClientsSegmentCriteria,
      pastClientsAdditionalCriteria,
      pastClientsSegmentCriteria,
      selectedSegmentType,
      upcomingClientsAdditionalCriteria,
      upcomingClientsSegmentCriteria,
      updateAllClientsAdditionalCriteria,
      updatePastClientsAdditionalCriteria,
      updateUpcomingClientsAdditionalCriteria,
    ]);

  return {
    isOpen,
    openSideDrawer,
    closeSideDrawer,
    segmentName,
    segmentCriteria,
    updateAdditionalCriteria,
    additionalCriteria,
  };
}
