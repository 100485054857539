import { fireEvent, screen } from "@testing-library/react";
import { GLIMMER_TEST_ID } from "@jobber/components/Glimmer";
import { messages as additionalCriteriaMessages } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/messages";

export const getAllGlimmer = () => {
  return screen.getAllByTestId(GLIMMER_TEST_ID);
};

export const getIntervalInput = () => {
  return screen.getByDisplayValue("12");
};

export const getAndAdditionalCriteriaText = () => {
  return screen.getByText(
    additionalCriteriaMessages.andAdditionalCriteria.defaultMessage,
  );
};

export const getClientTagsCriterion = () => {
  return screen.getByDisplayValue(
    additionalCriteriaMessages.clientTagsCriterion.defaultMessage,
  );
};

export const getCriterionTestId = (testId: string) => {
  return screen.getByTestId(testId);
};

export const getExpectedCountOfClients = (expectedCount: number) => {
  return screen.getByText(`${expectedCount.toString()} clients`);
};

export const getJobTypeCriterion = () => {
  return screen.getByDisplayValue(
    additionalCriteriaMessages.jobTypeCriterion.defaultMessage,
  );
};

export const queryAllAndAdditionalCriteriaText = () => {
  return screen.queryAllByText(
    additionalCriteriaMessages.andAdditionalCriteria.defaultMessage,
  );
};

export const queryAllClientTagsCriterion = () => {
  return screen.queryAllByDisplayValue(
    additionalCriteriaMessages.clientTagsCriterion.defaultMessage,
  );
};

export const getAllTestId = (testId: string) => {
  return screen.getAllByTestId(testId);
};

export const queryAllLineItemsCriterion = () => {
  return screen.queryAllByDisplayValue(
    additionalCriteriaMessages.lineItemsCriterion.defaultMessage,
  );
};

export const queryAllJobTypeCriterion = () => {
  return screen.queryAllByDisplayValue(
    additionalCriteriaMessages.jobTypeCriterion.defaultMessage,
  );
};

export const queryAndAdditionalCriteriaText = () => {
  return screen.queryByText(
    additionalCriteriaMessages.andAdditionalCriteria.defaultMessage,
  );
};

export const queryClientTagsCriterion = () => {
  return screen.queryByDisplayValue(
    additionalCriteriaMessages.clientTagsCriterion.defaultMessage,
  );
};

export const queryCriterionTestId = (testId: string) => {
  return screen.queryByTestId(testId);
};

export const getErrorMessage = () => {
  return screen.getByText("Enter a number between 1 and 500");
};

// solo actions
export const iChangeTheIntervalInput = (value: string) => {
  fireEvent.change(getIntervalInput(), { target: { value: value } });
};

export const iClickTotalCountOfClientsButton = (expectedCount: number) => {
  return fireEvent.click(getExpectedCountOfClients(expectedCount));
};
