import React from "react";
import { Banner } from "@jobber/components/Banner";
import type {
  ArrivalWindow,
  ArrivalWindowDayOfWeekMap,
} from "jobber/google_lsa/MerchantConfiguration";

export interface ValidationError {
  message: string;
}

interface ValidationBannerProps {
  errors: ValidationError[];
}

export function ValidationBanner({ errors }: ValidationBannerProps) {
  if (errors.length < 1) {
    return <></>;
  }
  return (
    <Banner type="error">
      <span>
        Arrival windows cannot be saved. Please check the following{" "}
        {errors.length} errors:
      </span>
      {errors.map((error, index) => (
        <span className="list-item" key={index}>
          &bull;&nbsp; {error.message}
        </span>
      ))}
    </Banner>
  );
}

export function overlappingWindowsValidation(
  windows: ArrivalWindow[],
): ValidationError[] {
  interface StartEndPair {
    previousEndTime: number;
    currentStartTime: number;
  }

  // If we want to compare arrival window overlap with day of week included,
  // we need to represent arrival window times relative to a week rather than
  // a day.
  const arrivalWindowsInSeconds = arrivalWindowsToSecondsInWeek(windows);
  const startTimes = arrivalWindowsInSeconds.map(window => window.startSeconds);
  const endTimes = arrivalWindowsInSeconds.map(window => window.endSeconds);
  const startEndPairs = startTimes.map((currentStartTime, index) => {
    if (index < 1) {
      return {};
    }
    return {
      previousEndTime: endTimes[index - 1],
      currentStartTime: currentStartTime,
    };
  });
  startEndPairs.shift(); // We don't want to keep the first empty element
  const validPairs = startEndPairs.map((pair: StartEndPair) => {
    if (pair.previousEndTime <= pair.currentStartTime) {
      return true;
    }
    return false;
  });
  const anyInvalid = validPairs.filter(state => state === false);

  if (anyInvalid.length > 0) {
    return [{ message: "Check for overlapping arrival window times" }];
  }
  return [];
}

export function startTimeBeforeEndTimeValidation(
  windows: ArrivalWindow[],
): ValidationError[] {
  const validWindows = windows.map(window =>
    startTimeIsBeforeEndTime(window.startAt, window.endAt),
  );
  const anyInvalid = validWindows.filter(state => state === false);

  if (anyInvalid.length > 0) {
    return [{ message: "Check that arrival window start is before end time" }];
  }
  return [];
}

export function dayOfWeekValidation(
  windows: ArrivalWindow[],
): ValidationError[] {
  const validWindows = windows.map(window =>
    atLeastOneDayOfWeekIsSelected(window.dayOfWeekMap),
  );
  const anyInvalid = validWindows.filter(state => state === false);

  if (anyInvalid.length > 0) {
    return [
      {
        message:
          "Check that each arrival window has at least one day of the week selected",
      },
    ];
  }
  return [];
}

export function startTimeIsBeforeEndTime(startAt: Date, endAt: Date): boolean {
  const startSeconds = windowTimeToSeconds(startAt);
  const endSeconds = windowTimeToSeconds(endAt);

  if (startSeconds < endSeconds) {
    return true;
  }
  return false;
}

export function atLeastOneDayOfWeekIsSelected(
  daysSelected: ArrivalWindowDayOfWeekMap,
): boolean {
  const validStatuses = Object.values(daysSelected).filter(day => day === true);
  if (validStatuses.length > 0) {
    return true;
  }
  return false;
}

interface WindowSecondsPair {
  startSeconds: number;
  endSeconds: number;
}

// There are 86,400 seconds in one day
enum DayOfWeekSecondOffset {
  Sunday = 86400 * 0,
  Monday = 86400 * 1,
  Tuesday = 86400 * 2,
  Wednesday = 86400 * 3,
  Thursday = 86400 * 4,
  Friday = 86400 * 5,
  Saturday = 86400 * 6,
}

function arrivalWindowsToSecondsInWeek(
  windows: ArrivalWindow[],
): WindowSecondsPair[] {
  const arrivalWindowsInSeconds: WindowSecondsPair[] = [];
  windows.forEach(window => {
    const daysOfWeekInSeconds = arrivalWindowToSeconds(window);
    arrivalWindowsInSeconds.push(...daysOfWeekInSeconds);
  });

  return arrivalWindowsInSeconds.sort((first, second) => {
    return first.startSeconds - second.startSeconds;
  });
}

function arrivalWindowToSeconds(window: ArrivalWindow): WindowSecondsPair[] {
  const emptyTimes = {
    startSeconds: -1,
    endSeconds: -1,
  };
  const sundayWindow = window.dayOfWeekMap.sunday
    ? {
        startSeconds:
          windowTimeToSeconds(window.startAt) +
          Number(DayOfWeekSecondOffset.Sunday),
        endSeconds:
          windowTimeToSeconds(window.endAt) +
          Number(DayOfWeekSecondOffset.Sunday),
      }
    : emptyTimes;
  const mondayWindow = window.dayOfWeekMap.monday
    ? {
        startSeconds:
          windowTimeToSeconds(window.startAt) +
          Number(DayOfWeekSecondOffset.Monday),
        endSeconds:
          windowTimeToSeconds(window.endAt) +
          Number(DayOfWeekSecondOffset.Monday),
      }
    : emptyTimes;
  const tuesdayWindow = window.dayOfWeekMap.tuesday
    ? {
        startSeconds:
          windowTimeToSeconds(window.startAt) +
          Number(DayOfWeekSecondOffset.Tuesday),
        endSeconds:
          windowTimeToSeconds(window.endAt) +
          Number(DayOfWeekSecondOffset.Tuesday),
      }
    : emptyTimes;
  const wednesdayWindow = window.dayOfWeekMap.wednesday
    ? {
        startSeconds:
          windowTimeToSeconds(window.startAt) +
          Number(DayOfWeekSecondOffset.Wednesday),
        endSeconds:
          windowTimeToSeconds(window.endAt) +
          Number(DayOfWeekSecondOffset.Wednesday),
      }
    : emptyTimes;
  const thursdayWindow = window.dayOfWeekMap.thursday
    ? {
        startSeconds:
          windowTimeToSeconds(window.startAt) +
          Number(DayOfWeekSecondOffset.Thursday),
        endSeconds:
          windowTimeToSeconds(window.endAt) +
          Number(DayOfWeekSecondOffset.Thursday),
      }
    : emptyTimes;
  const fridayWindow = window.dayOfWeekMap.friday
    ? {
        startSeconds:
          windowTimeToSeconds(window.startAt) +
          Number(DayOfWeekSecondOffset.Friday),
        endSeconds:
          windowTimeToSeconds(window.endAt) +
          Number(DayOfWeekSecondOffset.Friday),
      }
    : emptyTimes;
  const saturdayWindow = window.dayOfWeekMap.saturday
    ? {
        startSeconds:
          windowTimeToSeconds(window.startAt) +
          Number(DayOfWeekSecondOffset.Saturday),
        endSeconds:
          windowTimeToSeconds(window.endAt) +
          Number(DayOfWeekSecondOffset.Saturday),
      }
    : emptyTimes;
  const windowsInSeconds = [
    sundayWindow,
    mondayWindow,
    tuesdayWindow,
    wednesdayWindow,
    thursdayWindow,
    fridayWindow,
    saturdayWindow,
  ];
  return windowsInSeconds.filter(
    windowInSeconds => windowInSeconds.startSeconds > 0,
  );
}

function windowTimeToSeconds(windowTime: Date): number {
  return windowTime
    ? windowTime.getHours() * 3600 +
        windowTime.getMinutes() * 60 +
        windowTime.getSeconds()
    : 0;
}
