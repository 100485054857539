import { defineMessages } from "react-intl";

export const messages = defineMessages({
  marketingSuiteDescription: {
    id: "subscriptionAddons.inactiveAddonDetails.marketingSuiteDescription",
    defaultMessage:
      "Unlock your full growth potential—get Referrals, Campaigns, and Reviews all in one powerful suite",
    description: "Marketing suite addon description",
  },
  campaignsDescription: {
    id: "subscriptionAddons.inactiveAddonDetails.campaignsDescription",
    defaultMessage:
      "Drive repeat business and boost your bottom line with easy, effective email campaigns",
    description: "Campaigns addon description",
  },
  reviewsDescription: {
    id: "subscriptionAddons.inactiveAddonDetails.reviewsDescription",
    defaultMessage:
      "Build trust and stand out in the crowd by consistently getting more 5-star reviews",
    description: "Reviews addon description",
  },
  referralsDescription: {
    id: "subscriptionAddons.inactiveAddonDetails.referralsDescription",
    defaultMessage:
      "Let your best customers bring in more business for you with an automated referral program",
    description: "Referrals addon description",
  },
  recommended: {
    id: "subscriptionAddons.inactiveAddonDetails.recommended",
    defaultMessage: "Recommended",
    description: "Recommended label text",
  },
  renewal: {
    id: "subscriptionAddons.activeAddonDetails.renewsPrefix",
    defaultMessage: "Renews {renewalDate}",
    description: "Renewal date",
  },
  expiry: {
    id: "subscriptionAddons.activeAddonDetails.expiryPrefix",
    defaultMessage: "Expires on {expiryDate}",
    description: "Expiry date",
  },
  futureBilled: {
    id: "subscriptionAddons.activeAddonDetails.futureBilledPrefix",
    defaultMessage: "Billed {futureStartBillingOnDate}",
    description: "Future billed date",
  },
});
