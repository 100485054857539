import React from "react";
import { Heading } from "@jobber/components/Heading";
import { SchedulingTimeInputs } from "./SchedulingTimeInputs";
import styles from "./styles.module.css";
import { useScheduleCardChangeHandlers, useScheduleCardState } from "../hooks";

interface SchedulingTimeControlsProps {
  renderHeading: boolean;
}

export function SchedulingTimeControls({
  renderHeading,
}: SchedulingTimeControlsProps) {
  const { stateMatches, schedulingState } = useScheduleCardState();
  const { handleEndTimeChange, handleStartTimeChange } =
    useScheduleCardChangeHandlers();

  return (
    <>
      {renderHeading && (
        <div className={styles.topMargin}>
          <Heading level={5}>Times</Heading>
        </div>
      )}
      <SchedulingTimeInputs
        disabled={stateMatches("times.disabled")}
        startTime={
          stateMatches("times.disabled") ? undefined : schedulingState.startTime
        }
        onStartTimeChange={handleStartTimeChange}
        endTime={
          stateMatches("times.disabled") ? undefined : schedulingState.endTime
        }
        onEndTimeChange={handleEndTimeChange}
      />
    </>
  );
}
