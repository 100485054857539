import { defineMessages } from "react-intl";

export const messages = defineMessages({
  primaryButtonLabel: {
    id: "coreToConnectTrial.preTrialModal.primaryButtonLabel",
    defaultMessage: "Try Connect Features",
    description: "try features label",
  },
  secondaryButtonLabel: {
    id: "coreToConnectTrial.preTrialModal.secondaryButtonLabel",
    defaultMessage: "Dismiss",
    description: "dismiss label",
  },
  modalDefaultError: {
    id: "coreToConnectTrial.preTrialModal.modalDefaultError",
    defaultMessage:
      "Connect trial could not be started. Please try again or contact support.",
    description: "default error message",
  },
});
