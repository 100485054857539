import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { AI_ASSISTANT_CONTEXT_HAND_OFF } from "jobber/jobberAssistant/components/JobberAssistant.graphql";
import { messages } from "jobber/jobberAssistant/messages";

interface UseHandOffContext {
  requestId: string;
}

export const useHandOffContext = ({ requestId }: UseHandOffContext) => {
  const [mutate, { error: mutationError, loading: handOffContextLoading }] =
    useMutation(AI_ASSISTANT_CONTEXT_HAND_OFF);
  const [handOffContextError, setHandOffContextError] = useState<string>();
  const { formatMessage } = useIntl();

  const handOffContext = useCallback(
    async (intercomConversationId: string) => {
      try {
        const result = await mutate({
          variables: {
            input: {
              intercomConversationId,
            },
            requestId,
          },
        });

        if (
          result.errors?.length ||
          result.data?.aiAssistantContextHandOff?.userErrors ||
          mutationError
        ) {
          setHandOffContextError(formatMessage(messages.genericErrorMessage));
        } else {
          setHandOffContextError(undefined);
        }
      } catch (error) {
        setHandOffContextError(formatMessage(messages.genericErrorMessage));
      }
    },
    [mutate, requestId, mutationError, formatMessage],
  );

  return {
    handOffContext,
    handOffContextError,
    handOffContextLoading,
  };
};
