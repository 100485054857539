import React, { useCallback, useContext } from "react";
import { Autocomplete, type Option } from "@jobber/components/Autocomplete";
import { SetupWizardContext } from "jobber/setupWizard/context";
import type { AutocompleteCompany as AutocompleteCompanyType } from "jobber/setupWizard/types";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import { usePreloadImages } from "jobber/setupWizard/hooks/usePreloadImages";
import { useCompanyAutocompleteResults } from "../useCompanyAutocompleteResults";
import { getHighestReviews, getPhotosByCompany } from "../helpers";

interface Props {
  placeholder: string;
  value: string | undefined;
  valueLabel: string | undefined;
  name?: string | undefined;
  onCompanyChange(company: AutocompleteCompanyType): void;
  invalid: boolean;
  signupConsentCountry: string | undefined;
}

export const AutocompleteCompany = ({
  invalid,
  value,
  valueLabel,
  onCompanyChange,
  placeholder,
  signupConsentCountry,
}: Props) => {
  const { setIsCompanyNameManual, setCompanyPhotos, setCompanyReviews } =
    useContext(SetupWizardContext);
  const { predictions, companyFromPlace } = useCompanyAutocompleteResults({
    google,
    signupConsentCountry,
  });
  const { preloadImages } = usePreloadImages();

  const handleManualValue = useCallback(() => {
    setIsCompanyNameManual(true);
    Amplitude.TRACK_EVENT(
      ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete.eventName,
      {
        experiment:
          ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete.experiment,
        interaction:
          ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete.interaction
            .manualCompanyInteraction,
      },
    );
  }, [setIsCompanyNameManual]);

  const handleLoadingCompanyFromPlace = useCallback(
    async (option: Option) => {
      onCompanyChange({ name: option.label });

      try {
        const company = await companyFromPlace(option.value?.toString() ?? "", [
          "website",
          "name",
          "reviews",
          "photos",
          "place_id",
        ]);

        onCompanyChange(company);
        Amplitude.TRACK_EVENT(
          ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete.eventName,
          {
            experiment:
              ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete
                .experiment,
            interaction:
              ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete
                .interaction.googleCompanyInteraction,
          },
        );

        const photosByCompany = getPhotosByCompany(
          company.photos,
          company.name,
        );
        void preloadImages(photosByCompany.urls);
        setCompanyPhotos(photosByCompany.urls);

        const reviews = getHighestReviews(company.reviews);
        setCompanyReviews(reviews);
      } catch (error) {
        onCompanyChange({ name: option.label });
      }
    },
    [
      companyFromPlace,
      onCompanyChange,
      preloadImages,
      setCompanyPhotos,
      setCompanyReviews,
    ],
  );

  const onChange = useCallback(
    async (option: Option | undefined) => {
      if (!option) {
        return;
      } else if (option.value === "manual") {
        handleManualValue();
      } else if (option.value) {
        await handleLoadingCompanyFromPlace(option);
      } else {
        onCompanyChange({ name: option.label });
      }
    },
    [handleLoadingCompanyFromPlace, handleManualValue, onCompanyChange],
  );

  return (
    <div data-testid="autocomplete-company-input">
      <Autocomplete
        clearable="while-editing"
        value={{ value, label: valueLabel || "" }}
        getOptions={predictions}
        onChange={onChange}
        invalid={invalid}
        size="large"
        placeholder={placeholder}
      />
    </div>
  );
};
