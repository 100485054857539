import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientSegmentNavigationModalTitle: {
    id: "campaigns.clientSegmentNavigationModal.title",
    defaultMessage: "Leave this form?",
    description: "Client Segment Nav modal title label",
  },
  clientSegmentNavigationModalText: {
    id: "campaigns.clientSegmentNavigationModal.text",
    defaultMessage:
      "You have unsaved changes in your segment. Do you want to save the Campaign as a draft before leaving? If you don't save, your changes will be lost.",
    description: "Client Segment Nav message label",
  },
  clientSegmentNavigationModalPrimaryAction: {
    id: "campaigns.clientSegmentNavigationModal.primaryAction",
    defaultMessage: "Save as Draft",
    description: "Confirm button label",
  },
  clientSegmentNavigationModalSecondaryAction: {
    id: "campaigns.clientSegmentNavigationModal.secondaryAction",
    defaultMessage: "Leave Without Saving",
    description: "Secondary button label",
  },
  clientSegmentNavigationModalTertiaryAction: {
    id: "campaigns.clientSegmentNavigationModal.tertiaryAction",
    defaultMessage: "Cancel",
    description: "Tertiary button label",
  },
});
