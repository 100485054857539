import lawnRevenueImageSrc from "@images/signup/business-revenue_lawncare.jpg";
import landscapingRevenueImageSrc from "@images/signup/business-revenue_landscaping.jpg";
import handymanRevenueImageSrc from "@images/signup/business-revenue_handyman.jpg";
import constructionRevenueImageSrc from "@images/signup/business-revenue_construction.jpg";
import cleaningRevenueImageSrc from "@images/signup/business-revenue_cleaning.jpg";
import arboristRevenueImageSrc from "@images/signup/business-revenue_arborist.jpg";
import electricalRevenueImageSrc from "@images/signup/business-revenue_electrical.jpg";
import hvacRevenueImageSrc from "@images/signup/business-revenue_hvac.jpg";
import paintingRevenueImageSrc from "@images/signup/business-revenue_painting.jpg";
import plumbingRevenueImageSrc from "@images/signup/business-revenue_plumbing.jpg";
import pressureWashingRevenueImageSrc from "@images/signup/business-revenue_pressure-washing.jpg";
import windowWashingRevenueImageSrc from "@images/signup/business-revenue_window-washing.jpg";
import defaultRevenueImageSrc from "@images/signup/business-revenue.jpg";
import blueBackground from "@images/signup/blue-bg-w-effect.png";
import collageImageSrc from "@images/signup/collage.jpg";
import companyStepImageSrc from "@images/signup/company-step.jpg";
import defaultBusinessImageSrc from "@images/signup/set-up-your-business.jpg";
import defaultHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us.jpg";
import defaultTopPriorityImageSrc from "@images/signup/top-priority.jpg";
import didYouKnow from "@images/signup/did-you-know.svg";
import largeMaskingTape from "@images/signup/large-masking-tape.svg";
import maskingTape1 from "@images/signup/small-masking-tape-1.svg";
import maskingTape2 from "@images/signup/small-masking-tape-2.svg";
import maskingTape3 from "@images/signup/small-masking-tape-3.svg";
import paperRipSrc from "@images/signup/paper-rip.svg";
import themeDark from "@images/signup/theme-dark.svg";
import themeLight from "@images/signup/theme-light.svg";
// industry images
import arboristBusinessImageSrc from "@images/signup/set-up-your-business_arborist.jpg";
import arboristHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_arborist.jpg";
import arboristTopPriorityImageSrc from "@images/signup/top-priority_arborist.jpg";
import cleaningBusinessImageSrc from "@images/signup/set-up-your-business_cleaning.jpg";
import cleaningHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_cleaning.jpg";
import cleaningTopPriorityImageSrc from "@images/signup/top-priority_cleaning.jpg";
import constructionBusinessImageSrc from "@images/signup/set-up-your-business_construction.jpg";
import constructionHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_construction.jpg";
import constructionTopPriorityImageSrc from "@images/signup/top-priority_construction.jpg";
import electricalBusinessImageSrc from "@images/signup/set-up-your-business_electrical.jpg";
import electricalHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_electrical.jpg";
import electricalTopPriorityImageSrc from "@images/signup/top-priority_electrical.jpg";
import handymanHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_handyman.jpg";
import handymanTopPriorityImageSrc from "@images/signup/top-priority_handyman.jpg";
import hvacBusinessImageSrc from "@images/signup/set-up-your-business_hvac.jpg";
import hvacHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_hvac.jpg";
import hvacTopPriorityImageSrc from "@images/signup/top-priority_hvac.jpg";
import landscapingBusinessImageSrc from "@images/signup/set-up-your-business_landscaping.jpg";
import landscapingHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_landscaping.jpg";
import landscapingTopPriorityImageSrc from "@images/signup/top-priority_landscaping.jpg";
import lawnBusinessImageSrc from "@images/signup/set-up-your-business_lawncare.jpg";
import lawnHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_lawncare.jpg";
import lawnTopPriorityImageSrc from "@images/signup/top-priority_lawncare.jpg";
import paintingBusinessImageSrc from "@images/signup/set-up-your-business_painting.jpg";
import paintingHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_painting.jpg";
import paintingTopPriorityImageSrc from "@images/signup/top-priority_painting.jpg";
import plumbingBusinessImageSrc from "@images/signup/set-up-your-business_plumbing.jpg";
import plumbingHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_plumbing.jpg";
import plumbingTopPriorityImageSrc from "@images/signup/top-priority_plumbing.jpg";
import pressureWashingBusinessImageSrc from "@images/signup/set-up-your-business_pressure-washing.jpg";
import pressureWashingHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_pressure-washing.jpg";
import pressureWashingTopPriorityImageSrc from "@images/signup/top-priority_pressure-washing.jpg";
import windowWashingBusinessImageSrc from "@images/signup/set-up-your-business_window-washing.jpg";
import windowWashingHeardAboutUsImageSrc from "@images/signup/how-did-you-hear-about-us_window-washing.jpg";
import windowWashingTopPriorityImageSrc from "@images/signup/top-priority_window-washing.jpg";

export const defaultAssets = {
  blueBackground,
  collageImageSrc,
  companyStepImageSrc,
  didYouKnow,
  largeMaskingTape,
  maskingTape1,
  maskingTape2,
  maskingTape3,
  paperRipSrc,
  themeDark,
  themeLight,
};

export const arboristImages = {
  business: arboristBusinessImageSrc,
  heardAboutUs: arboristHeardAboutUsImageSrc,
  revenue: arboristRevenueImageSrc,
  topPriority: arboristTopPriorityImageSrc,
};

export const cleaningImages = {
  business: cleaningBusinessImageSrc,
  heardAboutUs: cleaningHeardAboutUsImageSrc,
  revenue: cleaningRevenueImageSrc,
  topPriority: cleaningTopPriorityImageSrc,
};

export const constructionImages = {
  business: constructionBusinessImageSrc,
  heardAboutUs: constructionHeardAboutUsImageSrc,
  revenue: constructionRevenueImageSrc,
  topPriority: constructionTopPriorityImageSrc,
};

export const electricalImages = {
  business: electricalBusinessImageSrc,
  heardAboutUs: electricalHeardAboutUsImageSrc,
  revenue: electricalRevenueImageSrc,
  topPriority: electricalTopPriorityImageSrc,
};

export const hvacImages = {
  business: hvacBusinessImageSrc,
  heardAboutUs: hvacHeardAboutUsImageSrc,
  revenue: hvacRevenueImageSrc,
  topPriority: hvacTopPriorityImageSrc,
};

export const handymanImages = {
  business: hvacBusinessImageSrc,
  heardAboutUs: handymanHeardAboutUsImageSrc,
  revenue: handymanRevenueImageSrc,
  topPriority: handymanTopPriorityImageSrc,
};

export const landscapingImages = {
  business: landscapingBusinessImageSrc,
  heardAboutUs: landscapingHeardAboutUsImageSrc,
  revenue: landscapingRevenueImageSrc,
  topPriority: landscapingTopPriorityImageSrc,
};

export const lawnImages = {
  business: lawnBusinessImageSrc,
  heardAboutUs: lawnHeardAboutUsImageSrc,
  revenue: lawnRevenueImageSrc,
  topPriority: lawnTopPriorityImageSrc,
};

export const paintingImages = {
  business: paintingBusinessImageSrc,
  heardAboutUs: paintingHeardAboutUsImageSrc,
  revenue: paintingRevenueImageSrc,
  topPriority: paintingTopPriorityImageSrc,
};

export const plumbingImages = {
  business: plumbingBusinessImageSrc,
  heardAboutUs: plumbingHeardAboutUsImageSrc,
  revenue: plumbingRevenueImageSrc,
  topPriority: plumbingTopPriorityImageSrc,
};

export const pressureWashingImages = {
  business: pressureWashingBusinessImageSrc,
  heardAboutUs: pressureWashingHeardAboutUsImageSrc,
  revenue: pressureWashingRevenueImageSrc,
  topPriority: pressureWashingTopPriorityImageSrc,
};

export const windowWashingImages = {
  business: windowWashingBusinessImageSrc,
  heardAboutUs: windowWashingHeardAboutUsImageSrc,
  revenue: windowWashingRevenueImageSrc,
  topPriority: windowWashingTopPriorityImageSrc,
};

export const defaultImages = {
  business: defaultBusinessImageSrc,
  heardAboutUs: defaultHeardAboutUsImageSrc,
  revenue: defaultRevenueImageSrc,
  topPriority: defaultTopPriorityImageSrc,
};
