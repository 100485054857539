import React, { useEffect, useState } from "react";
import { AddDebitCardDialog } from "jobber/payments_sca/components/AddDebitCardDialog/AddDebitCardDialog";
import useDialogOpen from "jobber/payments_sca/hooks/useDialogOpen/useDialogOpen";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import {
  type DepositResult,
  WithdrawFundsDialog,
} from "jobber/managed_accounts/WithdrawFundsDialog";
import { withStripeElementProvider } from "~/bunker/payments_react/clientHubJobberPayments/utilities/withStripeElementProvider";
import { DepositResultBanner } from "jobber/managed_accounts/PaymentsCard/DepositResultBanner";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { GetItNowButton } from "./components/GetItNowButton/GetItNowButton";

export interface InstantPayoutModalProps {
  instantAvailableAmount: string;
  instantPayoutFeeMultiplier: string;
  instantPayoutRequestedLast24Hours: string;
  debitCard?: CreditCard;
  debitCardIsValid: boolean;
  onPayoutSucceed(): void;
}

export const InstantPayoutModal =
  withStripeElementProvider<InstantPayoutModalProps>(
    InstantPayoutModalInternal,
  );

function InstantPayoutModalInternal(props: InstantPayoutModalProps) {
  const { currencySymbol } = useAccount();

  const [isWithdrawDialogOpen, openWithdrawDialog, closeWithdrawDialog] =
    useDialogOpen();

  const [isAddDebitDialogOpen, openAddDebitDialog, closeAddDebitDialog] =
    useDialogOpen();

  const [debitCard, setDebitCard] = useState(props.debitCard);
  const [depositResult, setDepositResult] = useState<DepositResult>();
  const getItNowButtonOnClick = debitCard
    ? onWithdrawFundsButtonClick
    : openAddDebitDialog;

  useEffect(() => {
    const isInstantPayoutModalOpen = localStorage.getItem(
      "instant_payout_modal_open",
    );

    if (isInstantPayoutModalOpen) {
      getItNowButtonOnClick();
      localStorage.removeItem("instant_payout_modal_open");
    }
  }, [getItNowButtonOnClick]);

  return (
    <>
      {debitCard && (
        <WithdrawFundsDialog
          isOpen={isWithdrawDialogOpen}
          onRequestClose={closeWithdrawDialog}
          onDepositSuccess={onDepositSuccess}
          instantAvailableAmount={props.instantAvailableAmount}
          feeMultiplier={props.instantPayoutFeeMultiplier}
          currencyUnit={currencySymbol}
          debitCard={debitCard}
        />
      )}
      <GetItNowButton
        onClick={getItNowButtonOnClick}
        debitCardLinked={!!debitCard}
        debitCardIsValid={props.debitCardIsValid}
        instantAvailableAmount={props.instantAvailableAmount}
        instantPayoutRequestedLast24Hours={
          props.instantPayoutRequestedLast24Hours
        }
      />
      <AddDebitCardDialog
        saveButtonLabel="Save and continue"
        isDialogOpen={isAddDebitDialogOpen}
        onRequestCloseDialog={closeAddDebitDialog}
        onDebitCardAdded={onDebitCardAdded}
      />
      {depositResult && (
        <DepositResultBanner
          depositResult={depositResult}
          currencyUnit={currencySymbol}
        />
      )}
    </>
  );

  function onDebitCardAdded(card: CreditCard) {
    setDebitCard(card);
  }

  function onWithdrawFundsButtonClick() {
    setDepositResult(undefined);
    openWithdrawDialog();
  }

  function onDepositSuccess(deposit: DepositResult) {
    setDepositResult(deposit);
    props.onPayoutSucceed();
  }
}
