import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sendSmsButton: {
    id: "Sms.SendSmsButtons.sendSmsButton",
    defaultMessage: "Send",
    description: "Send button for sms",
  },
  cancel: {
    id: "Sms.SendSmsButtons.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button for sms",
  },
  upgradeToSendHeading: {
    id: "Sms.SendSmsButtons.upgradeToSendHeading",
    defaultMessage: "Upgrade to send text messages",
    description: "Heading for upgrade to send SMS",
  },
  upgradeToSendText: {
    id: "Sms.SendSmsButtons.upgradeToSendText",
    defaultMessage:
      "Unlock access to text messages features by upgrading to a paid plan.",
    description: "Text for upgrade to send SMS",
  },
  viewPlansButton: {
    id: "Sms.SendSmsButtons.viewPlansButton",
    defaultMessage: "View Plans",
    description: "View plans button for upgrade to send SMS",
  },
});
