import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Banner,
  Content,
  Flex,
  Heading,
  InputNumber,
  Option,
  Select,
  Switch,
} from "@jobber/components";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import type { ApolloError } from "@apollo/client";
import {
  ClientNotificationId,
  type MessageTemplateEmail,
  type MessageTemplateSms,
  NotificationDeliveryMethod,
  type NotificationSchedule,
} from "~/utilities/API/graphql";
import { useUpdateNotification } from "jobber/reviews/views/ReviewsPage/hooks/useUpdateNotification";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { MessagePreviewer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessagePreviewer/ReviewMessagePreviewer";
import {
  Controller,
  FormProvider,
  type FormSubmitHandler,
  type SubmitHandler,
  useFormWithDefaults,
} from "~/utilities/reactHookForm/module";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { messages } from "../messages";
import styles from "../ReviewsSettings.module.css";

interface FollowUpMessageSettingsProps {
  schedule: NotificationSchedule;
  templates: {
    email: MessageTemplateEmail;
    sms: MessageTemplateSms;
  };
  error?: ApolloError;
  hasDPN: boolean;
}

interface FormData {
  deliveryMethod: NotificationDeliveryMethod;
  template: MessageTemplateEmail | MessageTemplateSms;
  scheduleEnabled: boolean;
  scheduleOffsetValue: number;
}

export function FollowUpMessageSettings({
  schedule,
  templates,
  error,
  hasDPN,
}: FollowUpMessageSettingsProps) {
  const { formatMessage } = useIntl();
  const { drawerActions, form, unsavedChangesModalActions } = useContext(
    ReviewsSettingsDrawerContext,
  );

  const { updateNotification } = useUpdateNotification();

  const defaultValues = {
    deliveryMethod: schedule.deliveryMethod || NotificationDeliveryMethod.EMAIL,
    template:
      schedule.deliveryMethod === "EMAIL" ? templates?.email : templates?.sms,
    scheduleEnabled: schedule.enabled,
    scheduleOffsetValue: schedule?.offset?.value || 0,
  };

  const handleUnsavedChanges = () => {
    form?.handleFormIsDirty();
  };

  return (
    <PrivacyMask disabled>
      <div className={styles.backButton}>
        <Button
          type="tertiary"
          variation="subtle"
          icon="longArrowLeft"
          ariaLabel={formatMessage(messages.messageSettingsBackAriaLabel)}
          onClick={() => {
            if (form?.isDirty) {
              unsavedChangesModalActions?.open();
            } else {
              drawerActions?.goTo(DrawerView.ManageSettings);
            }
          }}
        />
      </div>

      {error && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.deliveryMethodError)}
        </Banner>
      )}

      <div className={styles.settingsContainer}>
        <SettingsForm
          defaultValues={defaultValues}
          hasDPN={hasDPN}
          templates={templates}
          handleUnsavedChanges={handleUnsavedChanges}
          onSubmit={async (
            {
              deliveryMethod,
              scheduleEnabled: enabled,
              scheduleOffsetValue: offsetValue,
            },
            { setError },
          ) => {
            const errors = await updateNotification({
              clientNotificationId:
                ClientNotificationId.REVIEW_REQUEST_REMINDER,
              deliveryMethod,
              scheduleId: schedule.id,
              scheduleEnabled: enabled,
              scheduleOffsetValue: parseFloat(offsetValue.toString()),
            });

            if (errors.length > 0) {
              setError("root.serverError", {
                type: "serverError",
                message: formatMessage(messages.generalError),
              });
            } else {
              drawerActions?.goTo(DrawerView.ManageSettings);
              showToast({
                message: formatMessage(messages.messageSettingsSaveSuccess),
              });
            }
          }}
        />
      </div>
    </PrivacyMask>
  );
}

interface SettingsFormProps {
  defaultValues: FormData;
  handleUnsavedChanges: () => void;
  onSubmit: FormSubmitHandler<FormData>;
  hasDPN: boolean;
  templates: { email: MessageTemplateEmail; sms: MessageTemplateSms };
}

function SettingsForm({
  defaultValues,
  onSubmit,
  hasDPN,
  templates,
  handleUnsavedChanges,
}: SettingsFormProps) {
  const { formatMessage } = useIntl();

  const methods = useFormWithDefaults<FormData>({ defaultValues });
  const [scheduleEnabled, deliveryMethod] = methods.watch([
    "scheduleEnabled",
    "deliveryMethod",
  ]);

  const handleSubmit: SubmitHandler<FormData> = formValues => {
    onSubmit(formValues, { setError: methods.setError });
  };

  useEffect(() => {
    if (methods.formState.isDirty) {
      handleUnsavedChanges();
    }
  }, [methods.formState.isDirty, handleUnsavedChanges]);

  return (
    <>
      {methods.formState.errors?.root?.serverError && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.generalError)}
        </Banner>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Content spacing={"small"}>
            <Flex template={["grow", "shrink"]} direction="row" align="start">
              <Heading level={4}>
                {formatMessage(messages.followUpHeading)}
              </Heading>
              <Controller
                name={"scheduleEnabled"}
                render={({ field: { value, onChange } }) => {
                  return <Switch value={value} onChange={onChange} />;
                }}
              />
            </Flex>
            {scheduleEnabled && (
              <>
                <Flex
                  template={["grow", "grow"]}
                  direction="row"
                  align="start"
                  gap="smaller"
                >
                  <InputNumber
                    placeholder={formatMessage(
                      messages.messageSettingsOffsetPlaceholder,
                    )}
                    name={"scheduleOffsetValue"}
                    suffix={{
                      label: formatMessage(
                        messages.messageSettingsOffsetSuffix,
                      ),
                    }}
                    min={1}
                    max={35}
                  />
                  <Select
                    placeholder={formatMessage(
                      messages.messageSettingsDeliveryPlaceholder,
                    )}
                    name={"deliveryMethod"}
                  >
                    <Option value={NotificationDeliveryMethod.EMAIL}>
                      {formatMessage(messages.emailOptionLabel)}
                    </Option>
                    <Option value={NotificationDeliveryMethod.SMS}>
                      {formatMessage(messages.textOptionLabel)}
                    </Option>
                  </Select>
                </Flex>
                <div className={styles.editContainer}>
                  <MessagePreviewer
                    template={
                      deliveryMethod === "EMAIL"
                        ? templates?.email
                        : templates?.sms
                    }
                    hasDPN={hasDPN}
                  />
                </div>
              </>
            )}
            <div className={styles.footer}>
              <Button
                type="primary"
                size="large"
                submit={true}
                fullWidth={true}
                ariaLabel={formatMessage(messages.messageSettingsSaveAriaLabel)}
                label={formatMessage(messages.messageSettingsSaveLabel)}
                disabled={methods.formState.isSubmitting}
              />
            </div>
          </Content>
        </form>
      </FormProvider>
    </>
  );
}
