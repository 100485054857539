import { fireEvent, screen } from "@testing-library/react";
import { messages } from "jobber/campaigns/components/ClientSegmentNavigationModal/messages";

// Selectors
export function getClientSegmentNavigationModalText() {
  return screen.getByText(
    messages.clientSegmentNavigationModalText.defaultMessage,
  );
}

export function getCancelButton() {
  return screen.getByText("Cancel");
}

export function getDialog() {
  return screen.getByRole("dialog");
}

// Query
export function queryDialog() {
  return screen.queryByRole("dialog");
}

// Actions
export function iClickCancelButton() {
  fireEvent.click(getCancelButton());
}

export function iClickClientSegmentNavigationModalText() {
  fireEvent.click(getClientSegmentNavigationModalText());
}

export function iClickPrimaryAction() {
  fireEvent.click(
    screen.getByText(
      messages.clientSegmentNavigationModalPrimaryAction.defaultMessage,
    ),
  );
}

export function iClickSecondaryAction() {
  fireEvent.click(
    screen.getByText(
      messages.clientSegmentNavigationModalSecondaryAction.defaultMessage,
    ),
  );
}

export function iClickOnCloseModal() {
  fireEvent.click(screen.getByLabelText("Close modal"));
}
