import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/components/BaseCriteria/messages";

const MIN_INTERVAL = 1;
const MAX_INTERVAL = 500;

export function useIntervalValidation() {
  const { formatMessage } = useIntl();

  const [validationMessage, setValidationMessage] = useState<string>("");

  const validateInterval = useCallback(
    (interval: number) => {
      if (interval && interval % 1 !== 0) {
        return formatMessage(messages.intervalInputWholeNumberError);
      } else if (
        !interval ||
        interval < MIN_INTERVAL ||
        interval > MAX_INTERVAL
      ) {
        return formatMessage(messages.intervalInputBetweenMinAndMaxError, {
          min: MIN_INTERVAL,
          max: MAX_INTERVAL,
        });
      } else {
        return true;
      }
    },
    [formatMessage],
  );

  return { validationMessage, setValidationMessage, validateInterval };
}
