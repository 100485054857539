import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientListClientHeader: {
    id: "campaigns.clientSegmentTable.clientListClientHeader",
    defaultMessage: "Client",
    description: "Client header for the Client List DataTable",
  },
  clientListEmailHeader: {
    id: "campaigns.clientSegmentTable.clientListEmailHeader",
    defaultMessage: "Email",
    description: "Email header for the Client List DataTable",
  },
});
