import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Segment } from "~/utilities/API/graphql";
import {
  MOCK_ALL_CLIENTS_SELECTABLE_SEGMENT,
  MOCK_UPCOMING_CLIENTS_SELECTABLE_SEGMENT,
} from "./mocks";

export function getLoadingState() {
  return screen.getAllByTestId("ATL-GlimmerText");
}

export function getSelectableSegmentTitle(title: string) {
  return screen.getByText(title);
}

// button selectors
export function getRadioButtonElements() {
  return screen.getAllByRole("radio") as HTMLInputElement[];
}

export function getAllClientsSegmentRadioOption() {
  return screen.getByDisplayValue(Segment.ALL_CLIENTS);
}

export function getPastClientsSegmentRadioOption() {
  return screen.getByDisplayValue(Segment.PAST_CLIENTS) as HTMLInputElement;
}

export function getUpcomingClientsSegmentRadioOption() {
  return screen.getByDisplayValue(Segment.UPCOMING_CLIENTS);
}

export function getUpcomingClientsSegmentCriteriaTitle() {
  return screen.getByText(
    MOCK_UPCOMING_CLIENTS_SELECTABLE_SEGMENT.heading + " Criteria",
  );
}

export function getAllClientsSegmentCriteriaTitle() {
  return screen.getByText(
    MOCK_ALL_CLIENTS_SELECTABLE_SEGMENT.heading + " Criteria",
  );
}

// solo actions
export function iClickPastClientsSegmentRadioOption() {
  return userEvent.click(getPastClientsSegmentRadioOption());
}

export function iClickUpcomingClientsSegmentRadioOption() {
  return userEvent.click(getUpcomingClientsSegmentRadioOption());
}
