import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import type {
  ClientHubSettingsQuery,
  InvoiceOrQuote,
  MutationErrors,
} from "~/utilities/API/graphql";
import { JobberPaymentsDefaultPaymentPreference } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import { useUpdateDefaultPaymentPreference } from "./useUpdateDefaultPaymentPreference";
// eslint-disable-next-line import/no-internal-modules
import { ConfirmACHModal } from "./modals/ConfirmACHModal";
import { useOnlinePaymentsDefaultUpdateSplit } from "./useOnlinePaymentsDefaultUpdateSplit";

interface DefaultPaymentRadioGroupProps {
  handleOnChange: (value: string) => void;
  selectedOption: string | number;
  onError?(error: Error): void;
  objectType: InvoiceOrQuote;
  currentSettings?: ClientHubSettingsQuery;
  isMandatoryCardOnFileEnabled?: boolean;
  isAchEnabled?: boolean;
  setIsAchEnabled?: (value: boolean) => void;
  setToggleAchFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}
const genericError = new Error("Could not update settings");

export function DefaultPaymentRadioGroup(props: DefaultPaymentRadioGroupProps) {
  const { formatMessage } = useIntl();
  const { handleUpdateSettings, loading } = useUpdateDefaultPaymentPreference();
  const [modalOpen, setModalOpen] = useState(false);
  const { inOnlinePaymentsDefaultUpdateSplit } =
    useOnlinePaymentsDefaultUpdateSplit();

  const [chosenRadioOption, setChosenRadioOption] =
    useState<JobberPaymentsDefaultPaymentPreference>(
      JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
    );
  return (
    <>
      <Content>
        <Heading level={4}>
          {formatMessage(messages.defaultPreferenceRadioGroupTitle)}
        </Heading>
        <RadioGroup
          onChange={onRadioOptionChange}
          value={props.selectedOption}
          ariaLabel="Default Payment Options"
        >
          <RadioOption
            value={JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD}
            label={formatMessage(
              messages.defaultPreferenceRadioOptionAchAndCreditLabel,
            )}
            disabled={props.isMandatoryCardOnFileEnabled}
          />
          <RadioOption
            value={JobberPaymentsDefaultPaymentPreference.CREDIT_CARD}
            label={formatMessage(
              messages.defaultPreferenceRadioOptionCreditLabel,
            )}
          />
          <RadioOption
            value={JobberPaymentsDefaultPaymentPreference.ACH}
            label={formatMessage(messages.defaultPreferenceRadioOptionAchLabel)}
            disabled={props.isMandatoryCardOnFileEnabled}
          />
        </RadioGroup>
      </Content>
      <ConfirmACHModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(!modalOpen)}
        onError={() => props.onError?.(genericError)}
        updateDefaultPreference={updateDefaultPreference}
        radioOptionChosen={chosenRadioOption}
        loading={loading}
        setIsAchEnabled={props.setIsAchEnabled}
        setToggleAchFlag={props.setToggleAchFlag}
      />
    </>
  );

  async function onRadioOptionChange(
    value: JobberPaymentsDefaultPaymentPreference,
  ) {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "Default payment options button",
      source: `Select ${props.objectType.toString().toLowerCase()} payment option`,
      action: "Select default",
      type: value.toLowerCase(),
    });

    if (inOnlinePaymentsDefaultUpdateSplit) {
      await updateDefaultPreference(value);
      return;
    }

    if (props.isAchEnabled) {
      await updateDefaultPreference(value);
    } else {
      setChosenRadioOption(value);
      setModalOpen(true);
    }
  }

  async function updateDefaultPreference(
    value: JobberPaymentsDefaultPaymentPreference,
  ) {
    try {
      const { data } = await handleUpdateSettings({
        objectType: props.objectType,
        preference: value,
      });
      const errors = data?.jobberPaymentsUpdateDefaultPaymentPreference
        ?.userErrors as MutationErrors[];

      if (!data) {
        props.onError?.(genericError);
      }
      if (
        errors.length > 0 &&
        data?.jobberPaymentsUpdateDefaultPaymentPreference.success === false
      ) {
        props.onError?.(new Error(errors[0].message) || genericError);
      } else {
        props.handleOnChange(value);
      }
    } catch (error) {
      props.onError?.(error as Error);
    }
  }
}
