import { IntervalUnits } from "~/utilities/API/graphql";
import { messages } from "./messages";
import type { BaseCriteriaProps } from "../../../BaseCriteria";

export const INTERVAL_UNIT_OPTIONS: BaseCriteriaProps["intervalUnitOptions"] = [
  {
    label: messages.yearsAgoLabel,
    value: IntervalUnits.YEARS,
  },
  {
    label: messages.monthsAgoLabel,
    value: IntervalUnits.MONTHS,
  },
  {
    label: messages.weeksAgoLabel,
    value: IntervalUnits.WEEKS,
  },
  {
    label: messages.daysAgoLabel,
    value: IntervalUnits.DAYS,
  },
];
