import { useMemo, useState } from "react";
import { AiAssistantConversationIntent } from "~/utilities/API/graphql";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";
import {
  userHasSeenSupportBanner,
  writeSupportBannerShownMap,
} from "./supportBanner.utils";

interface UseShouldShowSupportBannerProps {
  conversationId: string;
  conversationIntent: AiAssistantConversationIntent | undefined;
  isResumingConversation: boolean;
}

export function useShouldShowSupportBanner(
  props: UseShouldShowSupportBannerProps,
) {
  // Since the conversation list can grow, the values passed should only be read
  // once. The banner state should only be inferred at initial load.
  const [{ conversationId, conversationIntent, isResumingConversation }] =
    useState(props);

  const { currentUser } = useCurrentUser([]);
  const shouldShowBanner = useMemo(() => {
    const isSeekingSupport =
      conversationIntent === AiAssistantConversationIntent.PRODUCT_SUPPORT;
    const hasSeenBanner = userHasSeenSupportBanner(
      currentUser.id ?? "",
      conversationId,
    );

    return isSeekingSupport && isResumingConversation && !hasSeenBanner;
  }, [
    conversationIntent,
    isResumingConversation,
    currentUser.id,
    conversationId,
  ]);

  return {
    setHasSeenBanner: () =>
      writeSupportBannerShownMap(currentUser.id ?? "", conversationId),
    shouldShowBanner,
  };
}
