import { defineMessages } from "react-intl";

const prefix = "setupWizard.maskingTapeFact.";

export const messages = defineMessages({
  defaultFact: {
    id: `${prefix}defaultFact`,
    defaultMessage:
      "<h1>Business owners who use Jobber save <span>7 hours</span> a week.</h1>",
    description: "Default fact for the masking tape fact component",
  },
  revenueDefaultFact: {
    id: `${prefix}revenueDefaultFact`,
    defaultMessage:
      "<h1>You can get paid <span>4x faster</span> with Jobber's online payments.</h1>",
    description: "Default revenue fact for the masking tape fact component",
  },
  companyDefaultFact: {
    id: `${prefix}companyDefaultFact`,
    defaultMessage:
      "<h1>Businesses grow their revenue by <span>37%</span> on average with Jobber.</h1>",
    description: "Default company fact for the masking tape fact component",
  },
});
