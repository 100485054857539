import { defineMessages } from "react-intl";

export const messages = defineMessages({
  ctaBannerImageAlt: {
    id: "settings.commsSettings.ctaBannerImageAlt",
    defaultMessage: "SMS Messaging Trial Banner",
    description: "Alt text for the SMS Messaging Trial Banner",
  },
  ctaBannerHeader: {
    id: "settings.commsSettings.ctaBannerHeader",
    defaultMessage: "Keep clients updated instantly with text messaging",
    description: "Header text for the SMS Messaging Trial Banner",
  },
  ctaBannerBody: {
    id: "settings.commsSettings.ctaBannerBody",
    defaultMessage:
      "Unlock access to SMS features, like on-my-way texts, quote follow-ups, and booking confirmations by upgrading to a paid plan.",
    description: "Body text for the SMS Messaging Trial Banner",
  },
  ctaBannerButtonLabel: {
    id: "settings.commsSettings.ctaBannerButtonLabel",
    defaultMessage: "View Plans",
    description: "Button label for the SMS Messaging Trial Banner",
  },
});
