import { defineMessages } from "react-intl";

export const messages = defineMessages({
  copilotName: {
    id: "jobberCopilot.jobberAssistantRoot.copilotName",
    defaultMessage: "Copilot",
    description: "Jobber Copilot name",
  },
  betaFlag: {
    id: "jobberCopilot.jobberAssistantRoot.betaFlag",
    defaultMessage: "Beta",
    description: "Jobber Copilot beta flag",
  },
  genericErrorMessage: {
    id: "jobberCopilot.jobberAssistantRoot.genericErrorMessage",
    defaultMessage: "Something went wrong.",
    description: "Generic error message",
  },
});
