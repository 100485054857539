import React from "react";
import {
  DataTable,
  type ManualPagination,
  type ManualSorting,
} from "@jobber/components/DataTable";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { createAccessorKey } from "~/jobber/features/Reporting/components/Report/utils/createAccessorKey";
import { ClientSegmentSortableFields } from "~/utilities/API/graphql";
import { messages } from "./messages";

export interface ClientSegmentTableProps {
  loading?: boolean;
  clientData: ClientSegmentTableClientData[];
  pagination: ManualPagination;
  sorting: ManualSorting;
}
export interface ClientSegmentTableClientData {
  name: string;
  email: string;
}

export function ClientSegmentTable({
  loading,
  clientData,
  pagination,
  sorting,
}: ClientSegmentTableProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <DataTable
      stickyHeader={true}
      loading={loading}
      data={clientData}
      pagination={pagination}
      sorting={sorting}
      columns={[
        {
          id: ClientSegmentSortableFields.NAME,
          accessorKey: createAccessorKey(ClientSegmentSortableFields.NAME),
          header: formatMessage(messages.clientListClientHeader),
          cell: cell => <Text>{cell.getValue<string>()}</Text>,
        },
        {
          id: ClientSegmentSortableFields.EMAIL,
          accessorKey: createAccessorKey(ClientSegmentSortableFields.EMAIL),
          header: formatMessage(messages.clientListEmailHeader),
          cell: cell => <Text>{cell.getValue<string>()}</Text>,
        },
      ]}
    />
  );
}
