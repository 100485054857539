import { Button } from "@jobber/components";
import type { Dispatch, SetStateAction } from "react";
import React from "react";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./RemoveAddonButton.module.css";
import { messages } from "./messages";
import type { SubscriptionAddonPreview } from "../../types";

export const RemoveAddonButton = ({
  subscriptionAddon,
  setSelectedAddons,
}: {
  subscriptionAddon: SubscriptionAddonPreview;
  setSelectedAddons: Dispatch<SetStateAction<string[]>>;
}) => {
  const { formatMessage } = useIntl();
  const INTERACTED_WITH_EXPERIMENT = "Interacted with Experiment";
  const handleRemoveAddon = () => {
    Amplitude.TRACK_EVENT(INTERACTED_WITH_EXPERIMENT, {
      experiment: "checkout_remove_addon_button",
      interaction: "removed",
    });
    setSelectedAddons((addons: string[]) => {
      return addons.filter(
        code => code !== subscriptionAddon.monthlyBillingCycle.addonCode,
      );
    });
  };
  return (
    <div className={styles.container}>
      <Button
        type="tertiary"
        variation="subtle"
        onClick={handleRemoveAddon}
        ariaLabel={`Remove ${subscriptionAddon.name} from cart`}
        label={formatMessage(messages.removeAddonButton)}
      />
    </div>
  );
};
