import { SplitNames, useSplit } from "utilities/split";

export function useOnlinePaymentsDefaultUpdateSplit(): {
  inOnlinePaymentsDefaultUpdateSplit: boolean;
} {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.OnlinePaymentsDefaultUpdate],
  });

  return {
    inOnlinePaymentsDefaultUpdateSplit: getTreatmentValue(
      SplitNames.OnlinePaymentsDefaultUpdate,
    ),
  };
}
