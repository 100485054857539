import { useEffect } from "react";
import { OPEN_ASSISTANT_FROM_INSIGHT_WIDGET } from "~/jobber/insightWidgets/constants";
import {
  OPEN_ASSISTANT_FROM_BUTTON,
  OPEN_ASSISTANT_FROM_SUPPORT_CHAT,
} from "../constants";

interface UseAssistantEventListeners {
  handleOpenFromInsightWidget: (
    event: CustomEvent<{ message: string; widgetName?: string }>,
  ) => void;
  handleOpenFromTopNavButton: () => void;
  handleOpenFromSupportChat: () => void;
}

export function useAssistantEventListeners({
  handleOpenFromInsightWidget,
  handleOpenFromTopNavButton,
  handleOpenFromSupportChat,
}: UseAssistantEventListeners) {
  useEffect(() => {
    window.addEventListener(
      OPEN_ASSISTANT_FROM_INSIGHT_WIDGET,
      handleOpenFromInsightWidget,
    );
    window.addEventListener(
      OPEN_ASSISTANT_FROM_BUTTON,
      handleOpenFromTopNavButton,
    );
    window.addEventListener(
      OPEN_ASSISTANT_FROM_SUPPORT_CHAT,
      handleOpenFromSupportChat,
    );

    return () => {
      window.removeEventListener(
        OPEN_ASSISTANT_FROM_INSIGHT_WIDGET,
        handleOpenFromInsightWidget,
      );
      window.removeEventListener(
        OPEN_ASSISTANT_FROM_BUTTON,
        handleOpenFromTopNavButton,
      );
      window.removeEventListener(
        OPEN_ASSISTANT_FROM_SUPPORT_CHAT,
        handleOpenFromSupportChat,
      );
    };
  }, [
    handleOpenFromInsightWidget,
    handleOpenFromSupportChat,
    handleOpenFromTopNavButton,
  ]);
}
