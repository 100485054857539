import React, {
  type MutableRefObject,
  type Ref,
  type RefObject,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ThreeDSecureAction, useRecurly } from "@recurly/react-recurly";
import { useMutation } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { ExperimentalPaymentDetails } from "jobber/billing/features/Checkout/components/CheckoutExperiment/ExperimentalPaymentDetails";
import type {
  BillingInfoEditMutation,
  BillingInfoEditMutationVariables,
  MutationErrors,
} from "~/utilities/API/graphql";
import { useRecurlyErrorHandler } from "jobber/billing/components/EditBillingInfo/hooks/useRecurlyErrorHandler";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import type { UpdatedPaymentDetailsType } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails";
import type { BillingAddressType } from "jobber/billing/components/EditBillingInfo/components/BillingAddress";
import styles from "jobber/billing/components/EditBillingInfo/EditBillingInfo.module.css";
import type { EditBillingInfoProps } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import {
  getInputValues,
  hasStoredBillingAddress,
  hasStoredPaymentDetails,
} from "jobber/billing/components/EditBillingInfo/utils";
import {
  EDIT_BILLING_INFO_DATA,
  EDIT_BILLING_INFO_MUTATION,
} from "jobber/billing/components/EditBillingInfo/EditBillingInfo.graphql";
import { messages } from "jobber/billing/components/EditBillingInfo/messages";
import { ExperimentalBillingAddress } from "jobber/billing/features/Checkout/components/CheckoutExperiment/ExperimentalBillingAddress";

export interface EditBillingInfoRef {
  submit(): Promise<void>;
}

export const ExperimentalEditBillingInfo = forwardRef(EditBillingInfoForm);

// eslint-disable-next-line max-statements
function EditBillingInfoForm(
  props: EditBillingInfoProps,
  ref: Ref<EditBillingInfoRef>,
) {
  const {
    isPurchasing,
    showPreview,
    shouldShowDisplay,
    storedPaymentDetails,
    storedBillingAddress,
    trackingDetails,
    setSaveEnabled,
    setShowParentActions,
    onSubmitSuccess,
    onValidationError,
    onSubmitError,
  } = props;

  const { formatMessage } = useIntl();
  const recurly = useRecurly();
  const formRef = useRef() as MutableRefObject<HTMLFormElement>;

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  const [shouldShowPaymentDetailsDisplay, setShouldShowPaymentDetailsDisplay] =
    useState(
      shouldShowDisplay && hasStoredPaymentDetails(storedPaymentDetails),
    );

  const [billingAddress, setBillingAddress] =
    useState<BillingAddressType>(storedBillingAddress);
  const [updatedPaymentDetails, setUpdatedPaymentDetails] =
    useState<UpdatedPaymentDetailsType>(storedPaymentDetails);

  const [storedRecurlyTokenId, setStoredRecurlyTokenId] = useState<string>();
  const [threeDSecureActionTokenId, setThreeDSecureActionTokenId] =
    useState<string>();

  const [recurlyFieldsInitialized, setRecurlyFieldsInitialized] =
    useState(false);
  const isEditingAddress = Object.keys(storedBillingAddress).some(
    (key: keyof BillingAddressType) =>
      storedBillingAddress[key] !== billingAddress[key],
  );

  const [editBillingInfoMutation] = useMutation<
    BillingInfoEditMutation,
    BillingInfoEditMutationVariables
  >(EDIT_BILLING_INFO_MUTATION, {
    refetchQueries: [EDIT_BILLING_INFO_DATA],
  });

  const { setBillingAddressChanged } = useContext(PurchaseFormContext);
  useEffect(() => {
    // Do not update billingAddressChanged if only email has changed
    if (
      isEditingAddress &&
      storedBillingAddress.email === billingAddress.email
    ) {
      setBillingAddressChanged(true);
    }
  }, [
    isEditingAddress,
    billingAddress,
    storedBillingAddress,
    setBillingAddressChanged,
  ]);

  useEffect(() => {
    if (!setSaveEnabled) {
      return;
    }

    if (threeDSecureActionTokenId) {
      setSaveEnabled(false);
    }

    return recurlyFieldsInitialized
      ? setSaveEnabled(true)
      : setSaveEnabled(isPurchasing || isEditingAddress);
  }, [
    setSaveEnabled,
    isPurchasing,
    recurlyFieldsInitialized,
    isEditingAddress,
    threeDSecureActionTokenId,
  ]);

  useEffect(() => {
    if (threeDSecureActionTokenId) {
      setShowParentActions && setShowParentActions(false);
    } else {
      setShowParentActions && setShowParentActions(true);
    }
  }, [threeDSecureActionTokenId, setShowParentActions]);

  const { getErrorMessage } = useRecurlyErrorHandler();

  return (
    <PrivacyMask>
      <form
        ref={formRef as RefObject<HTMLFormElement>}
        hidden={!!threeDSecureActionTokenId}
      >
        <ExperimentalBillingAddress
          shouldShowDisplay={
            shouldShowDisplay &&
            !!isPurchasing &&
            hasStoredBillingAddress(billingAddress)
          }
          showPreview={!!showPreview}
          billingAddress={billingAddress}
          trackingDetails={trackingDetails}
          setBillingAddress={setBillingAddress}
          onValidationError={onValidationError}
        />
        <div className={styles.paymentDetailsContainer}>
          <ExperimentalPaymentDetails
            isPurchasing={!!isPurchasing}
            showPreview={!!showPreview}
            storedPaymentDetails={storedPaymentDetails}
            updatedPaymentDetails={updatedPaymentDetails}
            setUpdatedPaymentDetails={setUpdatedPaymentDetails}
            trackingDetails={trackingDetails}
            shouldShowPaymentDetailsDisplay={shouldShowPaymentDetailsDisplay}
            setShouldShowPaymentDetailsDisplay={
              setShouldShowPaymentDetailsDisplay
            }
            setRecurlyFieldsInitialized={setRecurlyFieldsInitialized}
            onValidationError={onValidationError}
          />
        </div>
      </form>
      {threeDSecureActionTokenId && (
        <>
          <Banner dismissible={false} type={"warning"}>
            {formatMessage(messages.additionalVerificationRequired)}
          </Banner>
          <ThreeDSecureAction
            className={styles.threeDSecureChallengeContainer}
            actionTokenId={threeDSecureActionTokenId}
            onToken={async token => {
              await submitForm(storedRecurlyTokenId, token.id);
            }}
            onError={error => {
              setThreeDSecureActionTokenId(undefined);
              onSubmitError([{ message: getErrorMessage(error), path: [] }]);
            }}
          />
        </>
      )}
    </PrivacyMask>
  );

  async function handleSubmit(): Promise<void> {
    return new Promise(resolve => {
      if (!shouldShowPaymentDetailsDisplay) {
        recurly.token(formRef.current as HTMLFormElement, (error, token) => {
          if (error) {
            resolve(
              onSubmitError([{ message: getErrorMessage(error), path: [] }]),
            );
          } else {
            if (token) {
              resolve(submitForm(token.id));
            }
          }
        });
      } else if (isEditingAddress) {
        resolve(submitForm());
      } else {
        resolve(onSubmitSuccess());
      }
    });
  }

  async function submitForm(
    recurlyTokenId?: string,
    threeDSecureActionResultTokenId?: string,
  ): Promise<void> {
    setStoredRecurlyTokenId(recurlyTokenId);

    const result = await callMutation(
      recurlyTokenId,
      threeDSecureActionResultTokenId,
    );

    const userErrors: MutationErrors[] | undefined =
      result?.data?.billingInfoEdit?.userErrors;

    const threeDSecureActionToken =
      result?.data?.billingInfoEdit.threeDSecureActionToken;

    handleThreeDSecure(
      threeDSecureActionToken,
      threeDSecureActionResultTokenId,
    );

    const isSuccessfulSubmission =
      !userErrors?.length &&
      !threeDSecureActionToken &&
      !!result?.data?.billingInfoEdit?.billingInformation;

    if (isSuccessfulSubmission) {
      setStoredRecurlyTokenId(undefined);
      return onSubmitSuccess();
    }

    if (userErrors?.length) {
      setStoredRecurlyTokenId(undefined);
      return onSubmitError(userErrors);
    }
  }

  async function callMutation(
    recurlyTokenId?: string,
    threeDSecureActionResultTokenId?: string,
  ) {
    try {
      const result = await editBillingInfoMutation({
        variables: {
          input: getInputValues(
            updatedPaymentDetails,
            billingAddress,
            recurlyTokenId,
            threeDSecureActionResultTokenId,
          ),
        },
      });
      return result;
    } catch (error) {
      onSubmitError([
        { message: formatMessage(messages.recurlyDefaultError), path: [] },
      ]);
      return undefined;
    }
  }

  function handleThreeDSecure(
    threeDSecureActionToken?: string,
    threeDSecureActionResultTokenId?: string,
  ) {
    if (threeDSecureActionToken && !threeDSecureActionResultTokenId) {
      setThreeDSecureActionTokenId(threeDSecureActionToken);
      return;
    }
    setThreeDSecureActionTokenId(undefined);
  }
}
