import React from "react";
import { Banner, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import type { AiAssistantConversationIntent } from "~/utilities/API/graphql";
import { messages } from "./messages";
import type { ConversationListItemModel } from "./ConversationListItem";
import styles from "./SupportBanner.module.css";
import { useShouldShowSupportBanner } from "../hooks";

interface SupportBannerProps {
  conversationId: string;
  conversationIntent: AiAssistantConversationIntent | undefined;
  conversationList: ConversationListItemModel[];
}

export function SupportBanner({
  conversationId,
  conversationIntent,
  conversationList,
}: SupportBannerProps) {
  const { formatMessage } = useIntl();
  const { setHasSeenBanner, shouldShowBanner } = useShouldShowSupportBanner({
    conversationId,
    conversationIntent,
    // Length 1 is the welcome message; only show banner for more than 1 message
    isResumingConversation: conversationList.length > 1,
  });

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <div className={styles.bannerContainer}>
      <Banner
        dismissible
        icon="starburst"
        onDismiss={setHasSeenBanner}
        type="success"
      >
        <Text>{formatMessage(messages.supportBanner)}</Text>
      </Banner>
    </div>
  );
}
