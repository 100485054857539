import React, { useEffect } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { DescriptionList } from "@jobber/components/DescriptionList";
import type {
  AutomationRule,
  ClientSegment,
  ProductsQuery,
} from "~/utilities/API/graphql";
import { interactedWithCampaignSummaryAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import { getClientSegmentSummary } from "jobber/campaigns/components/CampaignTemplatePreviewModal/ClientSegmentSummaryUtil/ClientSegmentSummaryUtil";
import { useTranslateAutomationRulesConditionToText } from "jobber/campaigns/hooks/useTranslateAutomationRulesConditionToText/useTranslateAutomationRulesConditionToText";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { messages } from "./messages";
import { DateTimeDisplay } from "./components/DateTimeDisplay/DateTimeDisplay";
import { RecipientsDisplay } from "./components/RecipientsDisplay/RecipientsDisplay";
import styles from "./CampaignTemplatePreview.module.css";
import { EmailPreview } from "../EmailPreview";

export interface CampaignTemplatePreviewModalProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  sentDate: string;
  fromEmail: string;
  replyToEmail: string;
  totalSent: number;
  totalDelivered: number;
  clientSegment: ClientSegment | null;
  companyName: string;
  campaignIsAutomated?: boolean | undefined;
  automationRule?: AutomationRule;
  productsLoading: boolean;
  lineItemData?: ProductsQuery;
}

export function CampaignTemplatePreviewModal({
  isOpen,
  setOpen,
  sentDate,
  fromEmail,
  replyToEmail,
  totalSent,
  totalDelivered,
  clientSegment,
  companyName,
  campaignIsAutomated,
  automationRule,
  productsLoading,
  lineItemData,
}: CampaignTemplatePreviewModalProps) {
  const { formatMessage } = useIntl();

  const {
    campaignContent: {
      body,
      header,
      subject,
      showLogo,
      ctaButtonEnabled,
      buttonText,
      buttonColor,
      headerImage,
      referralLink,
      variables,
    },
  } = useCampaignWizardContext();

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    interactedWithCampaignSummaryAmplitudeEvent({
      interaction: "View Preview",
    });
  }, []);

  const campaignSummaryLabel = () => {
    return campaignIsAutomated
      ? formatMessage(messages.automationRule)
      : formatMessage(messages.clientSegment);
  };

  const { trigger, timeFrame, conditionText } =
    useTranslateAutomationRulesConditionToText({
      allLoading: productsLoading,
      automationRule,
      lineItemData,
      campaignIsAutomated,
    });

  const campaignSummary = () => {
    return campaignIsAutomated
      ? `${formatMessage(messages.automatedCampaignRecipientSummary, {
          timeFrame: timeFrame,
          trigger: trigger,
          condition: conditionText,
        })}`
      : `${clientSegment?.friendlyName} (${
          clientSegment
            ? getClientSegmentSummary(clientSegment, formatMessage)
            : ""
        })`;
  };

  return (
    <Modal
      title={formatMessage(messages.previewTitle)}
      size="large"
      open={isOpen}
      dismissible={true}
      onRequestClose={closeModal}
    >
      <Content>
        <DescriptionList
          data={[
            ["Sent", <DateTimeDisplay date={sentDate} key={1} />],
            ["Subject", `${subject}`],
            ["From", `${companyName} <${fromEmail}>`],
            ["Reply-to email", `${replyToEmail}`],
            [
              "Recipients",
              <RecipientsDisplay
                totalSent={totalSent}
                totalDelivered={totalDelivered}
                key={5}
              />,
            ],
            [campaignSummaryLabel(), campaignSummary()],
          ]}
        />
        <div className={styles.emailPreview}>
          <EmailPreview
            headerImageUrl={headerImage?.url}
            subject={subject}
            fromEmail={`${companyName} <${fromEmail}>`}
            header={header}
            body={body}
            buttonColor={buttonColor}
            buttonText={buttonText}
            ctaButtonEnabled={ctaButtonEnabled}
            loading={false}
            showLogo={showLogo}
            variables={variables}
            modalView
            referralLink={referralLink}
          />
        </div>
      </Content>
    </Modal>
  );
}
