import React, { useCallback, useEffect, useMemo } from "react";
import { SelectableCards } from "jobber/campaigns/components/SelectableCards";
// TODO (JOB-108466): Remove as part of clean-up
import { OldClientSegmentDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentDrawer";
import { useClientSegmentSideDrawer as oldUseClientSegmentSideDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentSideDrawer";
import { useClientSegmentOptions } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentOptions";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import type { ClientSegmentFragment } from "~/utilities/API/graphql";
import { Segment } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { getSegmentCriteriasFromSegments } from "jobber/campaigns/utils/segmentCriteriaUtils";

interface ClientSegmentInternalsProps {
  initialClientSegment: ClientSegmentFragment | undefined;

  setClientSegment(clientSegment: ClientSegmentFragment | undefined): void;

  loading: boolean;
}

// TODO (JOB-108466): Remove as part of clean-up. We want to keep CampaignsRecipientsPage/components/ClientSegmentInternals
// but we also want to keep the subcomponents of this component. We will need to migrate those subcomponents to
// CampaignsRecipientsPage/components/ClientSegmentInternals.

// TODO (JOB-108466): Remove lint exception when we discard oldUseClientSegmentSideDrawer
// eslint-disable-next-line max-statements
export function ClientSegmentInternals({
  initialClientSegment: clientSegment,
  setClientSegment,
  loading,
}: ClientSegmentInternalsProps) {
  const {
    campaignSegment: {
      selectedSegmentType,
      setSelectedSegmentType,
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    },
    campaignContent: { templateType },
  } = useCampaignWizardContext();

  const oldSideDrawerHook = oldUseClientSegmentSideDrawer({ clientSegment });

  const clientSegmentInternalsConditionalVariables = useMemo(
    () => ({
      isUpcomingClientDrawer: selectedSegmentType === Segment.UPCOMING_CLIENTS,
      isPastClientDrawer: selectedSegmentType === Segment.PAST_CLIENTS,
      isAllClientDrawer: selectedSegmentType === Segment.ALL_CLIENTS,
    }),
    [selectedSegmentType],
  );
  const segmentCriterias = useMemo(() => {
    return {
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    };
  }, [
    allClientsSegmentCriteria,
    pastClientsSegmentCriteria,
    upcomingClientsSegmentCriteria,
  ]);

  const { fetchData, fullReload, loadingMore, refetch, clientSegmentsData } =
    useClientSegmentData({
      conditionalVariables: clientSegmentInternalsConditionalVariables,
      segmentCriterias,
      segmentType: selectedSegmentType,
    });

  const segmentOptions = useClientSegmentOptions({
    clientsCriteriaInput: segmentCriterias,
    clientSegments: clientSegmentsData,
    selectedSegmentType,
    templateType,
  });

  const updateSelectedOption = useCallback(
    (value: Segment) => {
      setSelectedSegmentType(value, true);
    },
    [setSelectedSegmentType],
  );

  useEffect(() => {
    const variables = {
      ...clientSegmentInternalsConditionalVariables,
      ...getSegmentCriteriasFromSegments({
        segmentCriterias: segmentCriterias,
        segmentType: selectedSegmentType,
      }),
      after: btoa("0"),
    };

    refetch(variables);
  }, [
    segmentCriterias,
    selectedSegmentType,
    refetch,
    clientSegmentInternalsConditionalVariables,
  ]);

  return (
    <>
      <SelectableCards
        name="clientSegments"
        fullWidth={true}
        loading={loading || fullReload}
        cards={segmentOptions}
        value={selectedSegmentType}
        onChange={updateSelectedOption}
        additionalButtonClick={() => {
          const heading =
            segmentOptions.find(card => card.type === selectedSegmentType)
              ?.heading || "";
          oldSideDrawerHook.openSideDrawer(heading);
        }}
      />

      <OldClientSegmentDrawer
        isOpen={oldSideDrawerHook.isOpen}
        closeSideDrawer={oldSideDrawerHook.closeSideDrawer}
        drawerState={oldSideDrawerHook.drawerState}
        setDrawerState={oldSideDrawerHook.setDrawerState}
        selectedSegment={selectedSegmentType}
        segmentName={oldSideDrawerHook.segmentName}
        refetch={refetch}
        fetchData={fetchData}
        clientSegmentData={{
          rawData: clientSegment,
          clientSegments: clientSegmentsData,
        }}
        criteria={{
          additionalCriteria: oldSideDrawerHook.additionalCriteria,
          updateAdditionalCriteria: oldSideDrawerHook.updateAdditionalCriteria,
          baseCriteria: oldSideDrawerHook.segmentCriteria,
          setBaseCriteria: oldSideDrawerHook.setSegmentCriteria,
        }}
        loadingState={{
          loadingMore: loadingMore,
          fullReload: fullReload,
        }}
        setClientSegment={setClientSegment}
      />
    </>
  );
}
