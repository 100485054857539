import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import styles from "./Banner.module.css";
import { messages } from "./messages";

interface BannerDismissProps {
  onDismiss?: () => void;
}

export function BannerDismiss({ onDismiss }: BannerDismissProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.bannerDismiss}>
      <Button
        ariaLabel={formatMessage(messages.bannerDismiss)}
        onClick={onDismiss}
        type="tertiary"
        variation="subtle"
        icon="remove"
      />
    </div>
  );
}
