import React from "react";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import { Emphasis } from "@jobber/components/Emphasis";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { ConversationPills } from "jobber/jobberAssistant/components/ConversationPills";
import { AiAssistantFeedbackSentiment } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import { useFeedbackCreateMutation } from "../hooks";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

interface SupportQuestionFollowupProps {
  message: string;
  requestId: string;
  onClick?: (prompt: PromptType) => void;
}

const THAT_HELPED_PROMPT = "yes";

export const SupportQuestionFollowup = ({
  message,
  requestId,
  onClick,
}: SupportQuestionFollowupProps) => {
  const { formatMessage } = useIntl();
  const {
    createFeedback,
    createFeedbackErrorMessage,
    createFeedbackSubmitted,
    createFeedbackLoading,
  } = useFeedbackCreateMutation({ requestId });

  return (
    <>
      <Text>{formatMessage(messages.supportQuestionFollowupPrompt)}</Text>
      {createFeedbackErrorMessage && (
        <Banner type={"error"} dismissible={false}>
          {formatMessage(messages.supportQuestionFollowupSavingError)}
        </Banner>
      )}
      {!createFeedbackSubmitted ? (
        <ConversationPills
          onClick={async prompt => {
            if (prompt.message === THAT_HELPED_PROMPT) {
              await createFeedback(AiAssistantFeedbackSentiment.HELPFUL, "");
            } else {
              handleSupportClick(prompt);
            }
          }}
          prompts={[
            {
              displayText: formatMessage(
                messages.supportQuestionFollowupPositive,
              ),
              prompt: THAT_HELPED_PROMPT,
              loading: createFeedbackLoading,
            },
            {
              displayText: formatMessage(
                messages.supportQuestionFollowupSupport,
              ),
              prompt: formatMessage(messages.supportQuestionFollowupSupport),
              loading: false,
            },
          ]}
        />
      ) : (
        <Text align="end" variation="subdued" size="small">
          <Icon name="checkmark" size="small" />
          &nbsp;
          <Emphasis variation="italic">
            {formatMessage(messages.supportQuestionFollowupAcknowledgement)}
          </Emphasis>
        </Text>
      )}
    </>
  );

  function handleSupportClick(prompt: PromptType) {
    Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
      name: "connect_with_support_clicked",
      requestId,
    });
    if (onClick) {
      const newPrompt = {
        message: prompt.message,
        supportEscalationPrompt: message,
      } as PromptType;
      onClick(newPrompt);
    }
  }
};
