import { SplitNames, useSplit } from "utilities/split";

export function useACHExceededLimitsSplit(): {
  inACHExceededLimitsSplit: boolean;
} {
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.AchExceededLimits],
  });

  return {
    inACHExceededLimitsSplit: getTreatmentValue(SplitNames.AchExceededLimits),
  };
}
