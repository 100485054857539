import { defineMessages } from "react-intl";

export const messages = defineMessages({
  recommended: {
    id: "campaigns.selectableSegment.recommended",
    defaultMessage: "Recommended",
    description: "recommended text",
  },
  allClientsSegmentCardDescription: {
    id: "campaigns.selectableSegment.allClientsSegmentCardDescription",
    defaultMessage: "Clients that have not been archived",
    description: "Description for the all clients segment card",
  },
  pastClientsSegmentCardDescription: {
    id: "campaigns.selectableSegment.pastClientsSegmentCardDescription",
    defaultMessage:
      "Clients who haven’t worked with you in over 12 months, with no upcoming work scheduled",
    description: "Description for the upcoming visit clients segment card",
  },
  upcomingClientsSegmentCardDescription: {
    id: "campaigns.selectableSegment.upcomingClientsSegmentCardDescription",
    defaultMessage: "Clients with at least one visit within the next 30 days",
    description: "Description for the upcoming visit clients segment card",
  },
});
