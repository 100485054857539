import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

export function primaryButton() {
  return screen.getByRole("button", { name: /Try Grow Features/i });
}

export function secondaryButton() {
  return screen.getByRole("button", { name: /Dismiss/i });
}

export function growContent() {
  return screen.getAllByText(
    /Try it now on the Grow plan with a free 14-day trial. You won't be charged when the trial ends./i,
  )[0];
}

export function errorBanner() {
  return screen.getByText(
    /Grow trial could not be started. Please try again or contact support./i,
  );
}

export function iClickPrimaryButton() {
  const button = primaryButton();

  userEvent.click(button);
}

export function iClickSecondaryButton() {
  const button = secondaryButton();

  userEvent.click(button);
}

export function iDismissModal() {
  userEvent.click(screen.getByTestId("remove"));
}
