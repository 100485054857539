import {
  set,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";

export const formatDateTime = (
  dateString: string,
  showYear = false,
  showTime = false,
): string => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    ...(showYear && { year: "numeric" }),
    ...(showTime && {
      hour: "numeric",
      minute: "2-digit",
    }),
  });
};

export function getDateFromTime(hours: number, minutes: number): Date {
  return set(new Date(), {
    hours: hours,
    minutes: minutes,
    seconds: 0,
    milliseconds: 0,
  });
}

export function parseISOTime(timeString: string): Date {
  const [start, milliseconds] = timeString.split(".");
  const [hours, minutes, seconds] = start.split(":").map(Number);

  const now = new Date();
  return setMilliseconds(
    setSeconds(setMinutes(setHours(now, hours), minutes), seconds || 0),
    Number(milliseconds) || 0,
  );
}
