import { Content } from "@jobber/components/Content";
import { InputTime } from "@jobber/components/InputTime";
import { InputText } from "@jobber/components/InputText";
import { InputDate } from "@jobber/components/InputDate";
import React from "react";
import { Option, Select } from "@jobber/components/Select";
import { Checkbox } from "@jobber/components/Checkbox";
import { useIntl } from "react-intl";
import { messages } from "jobber/workOrders/components/JobCost/components/LabourTable/components/TimesheetModal/messages";
import { InputCurrency } from "components/InputCurrency";
import {
  changeTimeOnDate,
  unroundedSecondsToHours,
} from "jobber/workOrders/components/JobCost/utils";
import { InputDuration } from "components/InputDuration";
import {
  createDateWithoutSeconds,
  hoursAndMinutesToSeconds,
  secondsToFlooredHourAndMinutes,
} from "jobber/workOrders/components/JobCost/utils/dateFormatUtils";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import type { UsersQueryType } from "jobber/workOrders/components/JobCost/hooks/types";
import { useSelectedUserName } from "jobber/workOrders/components/JobCost/hooks/useSelectedUserName";
import {
  TimesheetActionTypes,
  type TimesheetModalAction,
  type TimesheetModalState,
  type TimesheetModalStateType,
} from "./TimesheetModalReducer";
import styles from "./CreateTimesheetModal.module.css";

interface TimesheetModalContentProps {
  currencySymbol: string;
  canViewJobCosts: boolean;
  state: TimesheetModalState;
  dispatch: React.Dispatch<TimesheetModalAction>;
  canEditTime: boolean;
  users?: UsersQueryType[];
  canEditLabourRates: boolean;
}

export function TimesheetModalContent({
  currencySymbol,
  canViewJobCosts,
  state,
  dispatch,
  canEditTime,
  users,
  canEditLabourRates,
}: TimesheetModalContentProps) {
  const {
    duration,
    startAt,
    note,
    endAt,
    labourRate,
    assignedTo,
    isLabourRateChanged,
    shouldUpdateDefaultLabourRate,
  } = state;
  const { formatMessage } = useIntl();
  const showDates = startAt && endAt && startAt.getDate() !== endAt.getDate();
  const { can } = useAuthorization();
  const canManageTimesheets = can("manage", "Timesheets");

  const { selectedUserName } = useSelectedUserName(users, assignedTo);

  const handleDispatch = (actionType: TimesheetActionTypes) => {
    return (newValue: TimesheetModalStateType) => {
      dispatch({ type: actionType, value: newValue });
    };
  };

  const formatTotalCost = (totalCost: number) => {
    const totalCostString = formatMessage(
      messages.timesheetModalContentTotalCostString,
      { currencySymbol, totalCost: totalCost.toFixed(2) },
    );

    return totalCostString;
  };

  const {
    onChangeStartTimeHandler,
    onChangeEndTimeHandler,
    onChangeDurationHandler,
    onChangeUserHandler,
  } = useOnChangeHandler(handleDispatch, startAt, endAt, users);

  return (
    <Content spacing={"small"}>
      <div className={styles.inputTime}>
        <div className={styles.inputTimeContainer}>
          <InputTime
            placeholder={formatMessage(
              messages.timesheetModalContentStartTimeInputPlaceholder,
            )}
            value={startAt ?? undefined}
            onChange={onChangeStartTimeHandler}
            description={showDates ? startAt?.toDateString() : undefined}
            validations={{
              required: {
                message: formatMessage(
                  messages.timesheetModalContentStartTimeInputRequiredValidationMessage,
                ),
                value: true,
              },
            }}
            disabled={!canEditTime}
          />
        </div>
        <div className={styles.inputTimeContainer}>
          <InputTime
            placeholder={formatMessage(
              messages.timesheetModalContentEndTimeInputPlaceholder,
            )}
            onChange={onChangeEndTimeHandler}
            value={endAt ?? undefined}
            description={showDates ? endAt?.toDateString() : undefined}
            disabled={!canEditTime}
          />
        </div>
      </div>
      <InputDuration
        duration={secondsToFlooredHourAndMinutes(duration)}
        onChange={onChangeDurationHandler}
        disabled={!canEditTime}
      />
      <InputText
        name={formatMessage(messages.timesheetModalContentNotesInputLabel)}
        placeholder={formatMessage(
          messages.timesheetModalContentNotesInputPlaceholder,
        )}
        multiline={true}
        value={note}
        onChange={handleDispatch(TimesheetActionTypes.UpdateNote)}
      />
      <InputDate
        placeholder={formatMessage(
          messages.timesheetModalContentDateInputPlaceholder,
        )}
        onChange={handleDispatch(TimesheetActionTypes.UpdateStartAtDate)}
        value={startAt}
        validations={{
          required: {
            message: formatMessage(
              messages.timesheetModalContentDateInputRequiredValidationMessage,
            ),
            value: true,
          },
        }}
        disabled={!canEditTime}
      />
      {canManageTimesheets && (
        <Select
          name={formatMessage(
            messages.timesheetModalContentAssignToSelectLabel,
          )}
          placeholder={formatMessage(
            messages.timesheetModalContentAssignToSelectPlaceholder,
          )}
          onChange={onChangeUserHandler}
          value={assignedTo}
        >
          {users?.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name.full}
            </Option>
          ))}
        </Select>
      )}
      {canViewJobCosts && (
        <>
          <InputCurrency
            placeholder={formatMessage(
              messages.timesheetModalContentEmployeeCostInputPlaceholder,
            )}
            prefix={{ label: currencySymbol }}
            suffix={{
              label: formatMessage(
                messages.timesheetModalContentEmployeeCostInputSuffix,
              ),
            }}
            maximumDecimalPlaces={2}
            value={labourRate}
            onChange={handleDispatch(TimesheetActionTypes.UpdateLabourRate)}
            description={formatTotalCost(
              labourRate ? labourRate * unroundedSecondsToHours(duration) : 0,
            )}
            disabled={!canEditTime}
            validations={{
              min: {
                message: formatMessage(
                  messages.timesheetModalContentEmployeeCostInputPositiveValidationMessage,
                ),
                value: 0,
              },
              max: {
                message: formatMessage(
                  messages.timesheetModalContentEmployeeCostInputMaxValidationMessage,
                ),
                value: 9999999999,
              },
            }}
          />
          {canEditLabourRates && isLabourRateChanged && (
            <Checkbox
              label={formatMessage(
                messages.timesheetModalContentLabourRateAppliedInputCheckboxLabel,
                { selectedUserName },
              )}
              checked={shouldUpdateDefaultLabourRate}
              onChange={handleDispatch(
                TimesheetActionTypes.UpdateShouldUpdateDefaultLabourRate,
              )}
            />
          )}
        </>
      )}
    </Content>
  );
}

function useOnChangeHandler(
  handleDispatch: (
    actionType: TimesheetActionTypes,
  ) => (newValue: TimesheetModalStateType) => void,
  startAt?: Date,
  endAt?: Date,
  users?: UsersQueryType[],
) {
  function onChangeUserHandler(value: string) {
    if (value) {
      const selectedUserLabourRate =
        users?.filter(user => user.id === value)[0]?.labourRate || 0;

      handleDispatch(TimesheetActionTypes.UpdateAssignedTo)({
        userId: value,
        labourRate: selectedUserLabourRate || 0,
      });
    }
  }

  function onChangeEndTimeHandler(value?: Date) {
    const dispatchAction = handleDispatch(TimesheetActionTypes.UpdateEndAt);

    if (value) {
      const newEndAt = changeTimeOnDate(
        endAt || createDateWithoutSeconds(),
        value,
      );
      dispatchAction(newEndAt);
    }
  }

  function onChangeStartTimeHandler(value?: Date) {
    const dispatchAction = handleDispatch(TimesheetActionTypes.UpdateStartAt);

    if (value) {
      const newStartAt = changeTimeOnDate(
        startAt || createDateWithoutSeconds(),
        value,
      );
      dispatchAction(newStartAt);
    }
  }

  function onChangeDurationHandler(value: { hours: number; minutes: number }) {
    const dispatchAction = handleDispatch(TimesheetActionTypes.UpdateDuration);

    if (value) {
      const newDuration = hoursAndMinutesToSeconds(value.hours, value.minutes);
      dispatchAction(newDuration);
    }
  }

  return {
    onChangeUserHandler,
    onChangeEndTimeHandler,
    onChangeStartTimeHandler,
    onChangeDurationHandler,
  };
}
