/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { useFormState } from "@jobber/hooks/useFormState";
import { Form } from "@jobber/components/Form";
import type { InputTextRef } from "@jobber/components/InputText";
import type { ApolloError } from "@apollo/client";
import { useCommsCampaignsExperienceQuery } from "jobber/campaigns/hooks/useCommsCampaignsExperienceQuery";
import {
  CampaignsBreadCrumb,
  breadCrumbMessages,
} from "jobber/campaigns/components/Breadcrumbs";
import { BottomBar } from "jobber/campaigns/components/Bottombar/BottomBar";
import { useCampaignContentActions } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignContentActions";
import { triggerValidation } from "jobber/campaigns/views/CampaignsContentPage/hooks";
import { ErrorBanner } from "jobber/campaigns/components/ErrorBanner";
import { CampaignContent } from "jobber/campaigns/views/CampaignsContentPage/components/CampaignContent";
import { useCommsCampaignTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignTemplateQuery";
import { usePreventEditCampaign } from "jobber/campaigns/hooks/usePreventEditCampaign/usePreventEditCampaign";
import { useCampaignNavigation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignNavigation/useCampaignNavigation";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import type { Template } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { CompanyAddressModal } from "jobber/campaigns/components/CompanyAddressModal/CompanyAddressModal";
import {
  AvailableEditors,
  type CampaignContentRefs,
} from "jobber/campaigns/views/CampaignsContentPage/types";
import { AddressConfigurationProvider } from "components/InputAddress";
import type { AddressConfiguration } from "components/InputAddress/types";
import { useCampaignCompanyInformation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignCompanyInformation";
import type { RichTextContentEditorRef } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor";
import { checkCompanyAddress } from "jobber/campaigns/views/CampaignsContentPage/utils/checkCompanyAddress";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import styles from "./CampaignsContentPage.module.css";
import { messages } from "./messages";
import { SendTestEmailForDemoModal } from "./components/SendTestEmailForDemoModal/SendTestEmailForDemoModal";
import { SendTestEmailModal } from "./components/SendTestEmailModal/SendTestEmailModal";

export type CampaignsContentPageProps = CampaignsContentPageInternalProps &
  AddressConfigurationProps;

interface CampaignsContentPageInternalProps {
  campaignId?: string;
  templateType?: Template;
}
interface AddressConfigurationProps {
  addressConfiguration: AddressConfiguration;
}

export function CampaignsContentPage({
  addressConfiguration,
  ...props
}: CampaignsContentPageProps) {
  const {
    campaignContent: { setTemplateType },
  } = useCampaignWizardContext();

  useEffect(() => {
    if (props.templateType) {
      setTemplateType(props.templateType);
    }
  }, [setTemplateType, props.templateType]);

  return (
    <AddressConfigurationProvider value={addressConfiguration}>
      <div className={styles.container}>
        <CampaignsContentPageInternal {...props} />
      </div>
    </AddressConfigurationProvider>
  );
}

// eslint-disable-next-line max-statements
function CampaignsContentPageInternal({
  campaignId = "",
  templateType,
}: CampaignsContentPageInternalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { getPageStep } = useCampaignUpsellSplit();
  const { removeBeforeUnloadListener } = useCampaignNavigation();
  const [{ isValid }, setFormState] = useFormState();
  const { data } = useCommsCampaignsExperienceQuery();

  const { loading, campaign, fromEmail, userEmail, error } =
    useCommsCampaignTemplateQuery({
      campaignId: campaignId,
      defaultTemplateType: templateType,
    });

  usePreventEditCampaign({ campaignStatus: campaign?.status });

  const { isErrorState, setIsErrorState } = useError(error);

  const refs: CampaignContentRefs = {
    [AvailableEditors.subject]: React.useRef<InputTextRef>(null),
    [AvailableEditors.header]: React.useRef<InputTextRef>(null),
    [AvailableEditors.body]: React.useRef<RichTextContentEditorRef>(null),
    [AvailableEditors.linkValue]: React.useRef<InputTextRef>(null),
    [AvailableEditors.buttonText]: React.useRef<InputTextRef>(null),
  };
  const [isSendATestModalOpen, setIsSendATestModalOpen] = useState(false);
  const [isSendATestModalInErrorState, setIsSendATestModalInErrorState] =
    useState<boolean>(false);
  const [renderExitConfirmationModal, setRenderExitConfirmationModal] =
    useState(false);
  const [isCompanyAddressModalOpen, setIsCompanyAddressModalOpen] =
    useState(false);
  const [textRewriteLoading, setTextRewriteLoading] = useState(false);

  const {
    loadingCompanyInformation,
    street1,
    street2,
    city,
    province,
    postalCode,
    country,
  } = useCampaignCompanyInformation();

  const existingAddress = {
    street1,
    street2,
    city,
    province,
    postalCode,
    country,
  };

  const {
    onNext,
    onBack,
    saveCampaign,
    onSendTestEmail,
    onSendTestEmailForDemo,
    onConfirmExitModal,
    actionLoading,
  } = useCampaignContentActions({
    campaignId,
    campaign,
    setRenderExitConfirmationModal,
    setIsErrorState,
    setIsSendATestModalOpen,
    setIsSendATestModalInErrorState,
    setIsCompanyAddressModalOpen,
    userEmail,
    removeBeforeUnloadListener,
    loading,
    formValidationErrors: !isValid,
  });

  const sharedSendTestEmailModalProps = {
    isOpen: isSendATestModalOpen,
    closeModal: () => {
      setIsSendATestModalInErrorState(false);
      setIsSendATestModalOpen(false);
    },
  };

  return (
    <PrivacyMask disabled>
      <Form onStateChange={setFormState}>
        <ErrorBanner isVisible={isErrorState} />
        <ConfirmationModal
          title={formatMessage(messages.exitConfirmationModalTitle)}
          message={formatMessage(messages.exitConfirmationModalText)}
          open={renderExitConfirmationModal}
          confirmLabel={formatMessage(
            messages.exitConfirmationModalPrimaryButton,
          )}
          onConfirm={onConfirmExitModal}
          onRequestClose={() => setRenderExitConfirmationModal(false)}
          size={"small"}
        />
        <CampaignsBreadCrumb
          currentStep={formatMessage(breadCrumbMessages.contentLabel)}
          displaySendButton={true}
          onBack={onBack}
          onSendTestButton={() => {
            triggerValidation(refs);
            setIsSendATestModalOpen(true);
          }}
          loading={loading || actionLoading}
        />

        {data?.commsCampaignsExperience?.hasDemoExperience ? (
          <SendTestEmailForDemoModal
            {...sharedSendTestEmailModalProps}
            isInErrorState={isSendATestModalInErrorState}
            onSendTestEmail={onSendTestEmailForDemo}
          />
        ) : (
          <SendTestEmailModal
            {...sharedSendTestEmailModalProps}
            isInErrorState={isSendATestModalInErrorState}
            onSendTestEmail={onSendTestEmail}
            userEmail={userEmail}
            formValidationErrors={!isValid}
          />
        )}

        {!loadingCompanyInformation && (
          <CompanyAddressModal
            showModal={isCompanyAddressModalOpen}
            existingAddress={existingAddress}
            closeModal={() => setIsCompanyAddressModalOpen(false)}
            onSuccessfulSave={queryData => {
              const companyDetails = queryData.account?.companyDetails;
              if (companyDetails) {
                const isFullCompanyAddressPresent =
                  checkCompanyAddress(companyDetails);
                return onNext(isFullCompanyAddressPresent);
              }
            }}
          />
        )}
        <CampaignContent
          loading={loading}
          refs={refs}
          fromEmail={fromEmail}
          textRewriteLoading={textRewriteLoading}
          setTextRewriteLoading={setTextRewriteLoading}
        />
        <BottomBar
          onNext={() => {
            triggerValidation(refs);
            const isFullCompanyAddressPresent =
              checkCompanyAddress(existingAddress);
            return onNext(isFullCompanyAddressPresent);
          }}
          onCancel={onBack}
          onSaveDraft={() => {
            return saveCampaign().catch(() => {
              triggerValidation(refs);
              return Promise.reject("invalid");
            });
          }}
          step={getPageStep("content")}
          loading={loading || actionLoading}
        />
      </Form>
    </PrivacyMask>
  );
}

function useError(error?: ApolloError) {
  const [isErrorState, setIsErrorState] = useState<boolean>(false);

  useEffect(() => {
    if (error) setIsErrorState(true);
  }, [error]);

  return { isErrorState, setIsErrorState };
}
