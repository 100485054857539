import { Text } from "@jobber/components/Text";
import React from "react";
import { Icon } from "@jobber/components/Icon";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components";
import { IntlProvider } from "@translations/IntlProvider";
import { Intercom } from "utilities/chat/Intercom";
import { PlanTier } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./DowngradeDisclaimer.module.css";

const PLAN_HELP_LINKS: { [key in PlanTier]?: string } = {
  [PlanTier.CONNECT]:
    "https://help.getjobber.com/hc/en-us/articles/12296620144919-The-Connect-Plan",
  [PlanTier.GROW]:
    "https://help.getjobber.com/hc/en-us/articles/360050124513-The-Grow-Plan",
};

export const FEATURE_LINKS = {
  twoWaySms:
    "https://help.getjobber.com/hc/en-us/articles/360051087154-Two-Way-Text-Messaging",
  qbo: "https://help.getjobber.com/hc/en-us/articles/10485704193687-How-to-Connect-Jobber-and-QuickBooks-Online-NEW-QuickBooks-Integration",
} as const;

export function capitalizeFirstLetter(string: string): string {
  if (typeof string !== "string") {
    throw new TypeError("Expected a string");
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export interface DowngradeDisclaimerProps {
  planDowngradingTo: PlanTier;
  planDowngradingFrom: PlanTier;
}

export function DowngradeDisclaimer({
  planDowngradingTo,
  planDowngradingFrom,
}: DowngradeDisclaimerProps): React.ReactElement {
  const { formatMessage: t } = useIntl();

  const isGrowToCoreDowngrade =
    planDowngradingFrom === PlanTier.GROW &&
    planDowngradingTo === PlanTier.CORE;
  const planLink = isGrowToCoreDowngrade
    ? [PLAN_HELP_LINKS[planDowngradingFrom], PLAN_HELP_LINKS.CONNECT]
    : [PLAN_HELP_LINKS[planDowngradingFrom]];
  const showQboDisclaimer =
    planDowngradingTo === PlanTier.CORE &&
    (planDowngradingFrom === PlanTier.GROW ||
      planDowngradingFrom === PlanTier.CONNECT);
  const showTwoWaySmsDisclaimer =
    planDowngradingFrom === PlanTier.GROW &&
    (planDowngradingTo === PlanTier.CONNECT ||
      planDowngradingTo === PlanTier.CORE);

  return (
    <IntlProvider>
      <div
        data-testid="downgradeDisclaimer"
        className={styles.downgradeDisclaimerCard}
      >
        <Content>
          <Heading level={4}>
            <Icon name="alert" size="large" />
            {t(messages.reminder, {
              planDowngradingFrom: capitalizeFirstLetter(planDowngradingFrom),
              planDowngradingTo: capitalizeFirstLetter(planDowngradingTo),
            })}
          </Heading>
          <ul className={styles.disclaimerList}>
            <Content spacing="smaller">
              {planLink && (
                <li key="featureLossDisclaimer">
                  {t(messages.featureLossDisclaimer, {
                    planDowngradingFrom: (
                      <a href={planLink[0]} target="_blank" rel="noreferrer">
                        {t(messages.planName, {
                          planName: capitalizeFirstLetter(planDowngradingFrom),
                        })}
                      </a>
                    ),
                    growToCore: isGrowToCoreDowngrade,
                    growToCorePlanName: (
                      <a href={planLink[1]} target="_blank" rel="noreferrer">
                        {t(messages.growToCorePlanName)}
                      </a>
                    ),
                  })}
                </li>
              )}
              {showTwoWaySmsDisclaimer && (
                <li key="twoWaySmsDisclaimer">
                  {t(messages.twoWaySmsDisclaimer, {
                    twoWaySmsLink: (
                      <a
                        href={FEATURE_LINKS.twoWaySms}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t(messages.twoWaySmsLink)}
                      </a>
                    ),
                  })}
                </li>
              )}
              {showQboDisclaimer && (
                <li key="qboDisclaimer">
                  {t(messages.qboDisclaimer, {
                    qboLink: (
                      <a
                        href={FEATURE_LINKS.qbo}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t(messages.qboLink)}
                      </a>
                    ),
                  })}
                </li>
              )}
              <li key="specialtyPricingDisclaimer">
                {t(messages.specialtyPricingDisclaimer)}
              </li>
            </Content>
          </ul>
          <Text>
            {t(messages.haveQuestions, {
              chatWithUsLink: (
                <Button
                  label={t(messages.chatWithUsButton)}
                  onClick={openChatWidget}
                  variation="subtle"
                  type="secondary"
                  size="small"
                />
              ),
              termsOfService: (
                <a
                  href="https://getjobber.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t(messages.termsOfService)}
                </a>
              ),
            })}
          </Text>
        </Content>
      </div>
    </IntlProvider>
  );
}

export function openChatWidget() {
  Amplitude.TRACK_EVENT("CTA Clicked", {
    name: "chat_with_us_button",
    source: "checkout_downgrade_disclaimer",
  });
  Intercom.EXECUTE("showNewMessage");
}
