import React, { useState } from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  ConnectToGrowTrialBeginModal,
  ConnectToGrowTrialBeginSource,
} from "jobber/connectToGrowTrial/components/ConnectToGrowTrialBeginModal/ConnectToGrowTrialBeginModal";

// DEVNOTE: this is for use exclusively for Lite, which utilizes the onboarding page (erb)
export function LiteToGrowTrialStartModalWrapper() {
  const [
    shouldShowConnectToGrowTrialBeginModal,
    setShouldShowConnectToGrowTrialBeginModal,
  ] = useState(true);

  return (
    // DEVNOTE: despite this component already having an APIProvider, the console throws an
    // Invariant Violation error if it's not wrapped in another APIProvider
    <IntlProvider>
      <APIProvider>
        <ConnectToGrowTrialBeginModal
          source={ConnectToGrowTrialBeginSource.DEEPLINKED}
          showModal={shouldShowConnectToGrowTrialBeginModal}
          dismissModal={() => setShouldShowConnectToGrowTrialBeginModal(false)}
        />
      </APIProvider>
    </IntlProvider>
  );
}
