import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import type { MessageFormat } from "jobber/setupWizard/types";
import { messages } from "./messages";

const { formatMessage } = createIntl();

export const companyImage = () => screen.findByAltText("Company image");
export const companyImageAlt = async () => {
  const image = await companyImage();
  return image.getAttribute("alt");
};
export const companyImageSrc = async () => {
  const image = await companyImage();
  return image.getAttribute("src");
};
export const queryCompanyImage = () => screen.queryByAltText("Company image");
export const revenuePageCopy = () =>
  screen.findByText(formatMessage(messages.revenuePageDefaultCopy));
export const backgroundImage = (text: MessageFormat) =>
  screen.findByAltText(formatMessage(text));

export const maskingTapeFact = async () => {
  const headings = await screen.findAllByRole("heading", { level: 1 });
  return headings[1];
};

export const maskingTapeFactText =
  "You can get paid 4x faster with Jobber's online payments.";
