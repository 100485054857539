import { useMutation } from "@apollo/client";
import { MICRO_SURVEY_FEEDBACK_CREATE_MUTATION } from "./MicroSurveyFeedbackCreate.graphql";

export function useMicroSurveyFeedbackCreate() {
  const [sendMicroSurveyFeedback, { loading }] = useMutation(
    MICRO_SURVEY_FEEDBACK_CREATE_MUTATION,
  );

  interface createMicroSurveyFeedbackProps {
    description?: string;
    rating?: number;
    feature: string;
  }

  const createMicroSurveyFeedback = async ({
    description,
    rating,
    feature,
  }: createMicroSurveyFeedbackProps) => {
    return sendMicroSurveyFeedback({
      variables: {
        input: { description, rating, feature },
      },
    });
  };

  return { createMicroSurveyFeedback, loading };
}
