import React from "react";
import { useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import classNames from "classnames";
import styles from "./ErrorBanner.module.css";
import { messages } from "./messages";

export interface ErrorBannerProps {
  isVisible: boolean;
  errorMessage?: string | null;
  bottomBar?: boolean;
}

export function ErrorBanner({
  isVisible,
  errorMessage,
  bottomBar = true,
}: ErrorBannerProps): JSX.Element {
  const { formatMessage } = useIntl();

  if (!isVisible) {
    return <></>;
  }

  const className = bottomBar
    ? classNames(styles.bannerContainer, styles.bannerContainerBottomBar)
    : classNames(styles.bannerContainer);

  return (
    <div className={className}>
      <Banner
        type={"error"}
        primaryAction={{
          label: formatMessage(messages.refreshButton),
          onClick: () => window.location.reload(),
        }}
      >
        {errorMessage ? errorMessage : formatMessage(messages.genericError)}
      </Banner>
    </div>
  );
}
