import { defineMessages } from "react-intl";

export const messages = defineMessages({
  manageAchLimits: {
    id: "managed_accounts.settings.manageAchLimits",
    defaultMessage: "Manage ACH limits",
    description: "Manage ACH limits header",
  },
  achLimitIncrease: {
    id: "managed_accounts.settings.achLimitIncrease",
    defaultMessage: "ACH Limit Increase",
    description: "ACH limit increase header",
  },
  manageAchLimitsDescriptionAllowed: {
    id: "managed_accounts.settings.manageAchLimitsDescriptionAllowed",
    defaultMessage:
      "ACH bank payments will be temporarily paused if the payment exceeds the available amount. Request an increase if these limits do not meet your needs.",
    description: "Manage ACH limits description for allowed SP's",
  },
  manageAchLimitsDescriptionNotAllowed: {
    id: "managed_accounts.settings.manageAchLimitsDescriptionNotAllowed",
    defaultMessage:
      "The option to request a limit increase will become available as you process more transactions over time.",
    description: "Manage ACH limits description for not allowed SP's",
  },
  requestLimitIncreaseButton: {
    id: "managed_accounts.settings.requestLimitIncreaseButton",
    defaultMessage: "Request Limit Increase",
    description: "Request limit increase button",
  },
  requestLimitIncreaseSubmitted: {
    id: "managed_accounts.settings.requestLimitIncreaseSubmitted",
    defaultMessage: "Request for increase submitted",
    description: "Request limit increase submitted message",
  },
  requestLimitIncreaseDeclined: {
    id: "managed_accounts.settings.requestLimitIncreaseDeclined",
    defaultMessage: "Request for increase declined",
    description: "Request limit increase declined message",
  },
  requestApproved: {
    id: "managed_accounts.settings.requestApproved",
    defaultMessage: "Request approved",
    description: "Request approved message",
  },
  requestPending: {
    id: "managed_accounts.settings.requestPending",
    defaultMessage: "Request pending",
    description: "Request pending message",
  },
  requestDeclined: {
    id: "managed_accounts.settings.requestDeclined",
    defaultMessage: "Request declined",
    description: "Request declined message",
  },
  requestedOn: {
    id: "managed_accounts.settings.requestedOn",
    defaultMessage: "Requested on {date}",
    description: "Requested on message with date",
  },
  requestedLimitApproved: {
    id: "managed_accounts.settings.requestedLimitApproved",
    defaultMessage:
      "You were recently approved for a limit increase. You aren't eligible for another limit increase at this time. Try again later.",
    description: "Requested limit approved message",
  },
  requestedLimitProcessing: {
    id: "managed_accounts.settings.requestedLimitProcessing",
    defaultMessage:
      "Please allow up to 10 business days for processing your request to increase transfer limit.",
    description: "Requested limit processing message",
  },
  requestedLimitDeclined: {
    id: "managed_accounts.settings.requestedLimitDeclined",
    defaultMessage:
      "You aren’t eligible for a limit increase at this time. Try again later.",
    description: "Requested limit declined message",
  },
  learnMoreAboutACHButton: {
    id: "managed_accounts.settings.learnMoreAboutACHButton",
    defaultMessage: "Learn about ACH limits",
    description: "Learn more about ACH button",
  },
  requestLimitIncreaseSubmittedError: {
    id: "managed_accounts.settings.requestLimitIncreaseSubmittedError",
    defaultMessage:
      "There was an error submitting your for limit increase request",
    description: "Request limit increase submitted error message",
  },
});
