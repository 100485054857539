import React from "react";
import { Banner } from "@jobber/components";
import { useIntl } from "react-intl";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { messages } from "../messages";

interface ErrorLayoutProps {
  hasDeliveryMethodError?: boolean;
  hasGeneralError?: boolean;
}

export function ErrorLayout({
  hasDeliveryMethodError,
  hasGeneralError,
}: ErrorLayoutProps) {
  const { formatMessage } = useIntl();

  return (
    <PrivacyMask disabled>
      {hasDeliveryMethodError && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.deliveryMethodError)}
        </Banner>
      )}

      {hasGeneralError && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.generalError)}
        </Banner>
      )}
    </PrivacyMask>
  );
}
