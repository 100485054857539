import React from "react";
import { FormattedMessage } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { ElongatedCircle } from "./components/ElongatedCircle";
import { messages } from "./messages";

export const facts = {
  defaultFact: {
    copy: (
      <FormattedMessage
        {...messages.defaultFact}
        values={{
          h1: chunks => <Heading level={1}>{chunks}</Heading>,
          span: chunks => <ElongatedCircle>{chunks}</ElongatedCircle>,
        }}
      />
    ),
  },
};

export const revenueFacts = {
  defaultFact: {
    copy: (
      <FormattedMessage
        {...messages.revenueDefaultFact}
        values={{
          h1: chunks => <Heading level={1}>{chunks}</Heading>,
          span: chunks => <ElongatedCircle>{chunks}</ElongatedCircle>,
        }}
      />
    ),
  },
};

export const companyFacts = {
  defaultFact: {
    copy: (
      <FormattedMessage
        {...messages.companyDefaultFact}
        values={{
          h1: chunks => <Heading level={1}>{chunks}</Heading>,
          span: chunks => <ElongatedCircle>{chunks}</ElongatedCircle>,
        }}
      />
    ),
  },
};
