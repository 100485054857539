import React, { useEffect, useMemo, useRef } from "react";
import { Heading } from "@jobber/components/Heading";
import { Checkbox } from "@jobber/components/Checkbox";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { strFormatDate } from "@jobber/components/FormatDate";
import classNames from "classnames";
import { RecurringSelect } from "jobber/workOrders/components/RecurringSelect";
import { Sg1DatePicker } from "jobber/payments_react/Sg1DatePicker";
import {
  type Recurrence,
  RecurrenceOptions,
  type RecurrenceRule,
  type ScheduleEnd,
  type SchedulerState,
} from "./types";
import styles from "./Scheduler.module.css";
import { ScheduleEndInput } from "./components/SelectingEndDate/ScheduleEndInput";
import { ScheduleTimeInput } from "./ScheduleTimeInput";
import { RecurringScheduleDropdown } from "./components/RecurringScheduleDropdown";
import { computeRecurrence } from "./computeRecurrence";
import { ScheduleSummary } from "./ScheduleSummary";
import { computeOccurrence } from "./computeOccurrence";

export interface SchedulerProps {
  scheduleLater: boolean;
  startDate: Date;
  startTime: Date | undefined;
  endTime: Date | undefined;
  anyTimeOfDay: boolean;
  recurrence: Recurrence;
  selectedRecurringOption: RecurrenceOptions;
  summaryString?: string;
  calendarIsHidden: boolean;
  onHideCalendar(): void;
  onChange(state: Partial<SchedulerState>): void;
}

type TitleProps = Pick<SchedulerProps, "onHideCalendar" | "calendarIsHidden">;

const Title = (props: TitleProps) => {
  return (
    <div className={styles.title}>
      <div>Schedule</div>
      <Button
        label={`${props.calendarIsHidden ? "Show" : "Hide"} Calendar`}
        size={"small"}
        variation="subtle"
        type="tertiary"
        onClick={props.onHideCalendar}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line max-statements
export const Scheduler = (props: SchedulerProps) => {
  const startTimeInputRef = useRef<HTMLInputElement | null>(null);
  const endTimeInputRef = useRef<HTMLInputElement | null>(null);

  const onChangeStartDate = (date: Date | undefined) =>
    props.onChange({ startDate: date || new Date() });

  const onChangeScheduleTime = ({ start, end }: { start: Date; end: Date }) =>
    props.onChange({
      startTime: start,
      endTime: end,
      anyTimeOfDay: !(start || end),
    });

  const toggleScheduleLater = () =>
    props.onChange({ scheduleLater: !props.scheduleLater });

  const toggleAnyTime = () =>
    props.onChange({
      anyTimeOfDay: !props.anyTimeOfDay,
      startTime: props.anyTimeOfDay ? props.startTime : undefined,
      endTime: props.anyTimeOfDay ? props.endTime : undefined,
    });

  // When the user fills in an hour but tabs away before filling
  // out a minute the DOM input[type=time] does not execute
  // any event callbacks and ReactJS state remains unchanged
  // Because we want to reset the fields when the checkbox for
  // "Anytime on day of visit" is clicked here we explicitly set the
  // value to an empty string so that it also clears any potential
  // unfinshihed values
  useEffect(() => {
    if (props.anyTimeOfDay) {
      if (startTimeInputRef.current) {
        startTimeInputRef.current.value = "";
      }

      if (endTimeInputRef.current) {
        endTimeInputRef.current.value = "";
      }
    }
  }, [props.anyTimeOfDay]);

  const getRecurrenceRule = (option: RecurrenceOptions) => {
    if (option === RecurrenceOptions.AsNeeded) {
      return props.recurrence;
    }

    return computeRecurrence(props.startDate, props.recurrence, option);
  };

  // Schedule Ends
  const onChangeScheduleEnds = (ends: ScheduleEnd) => {
    props.onChange({
      recurrence: {
        ...props.recurrence,
        ends,
      },
    });
  };

  const onChangeRecurringOptions = (nextSelectedPreset: RecurrenceOptions) =>
    props.onChange({
      selectedRecurringOption: nextSelectedPreset,
      recurrence: getRecurrenceRule(nextSelectedPreset),
    });

  const onRecurrenceChange = (newRecurrence: {
    ends: ScheduleEnd;
    rule: RecurrenceRule;
  }) =>
    props.onChange({
      recurrence: {
        ...newRecurrence,
      },
    });

  const schedule = useMemo(
    () => computeOccurrence(props.startDate, props.recurrence),
    [props.startDate, props.recurrence],
  );

  const containerClass = classNames(
    styles.container,
    props.scheduleLater ? styles.disabled : "",
  );

  const showAdditionalDetail =
    (props.recurrence.ends.type === "duration" ||
      (props.recurrence.ends.type === "date" &&
        props.recurrence.ends.date > props.startDate)) &&
    props.selectedRecurringOption !== RecurrenceOptions.AsNeeded &&
    schedule.lastOccurrence !== undefined;

  return (
    <div className={containerClass}>
      <Card
        // @ts-ignore
        title={
          <Title
            onHideCalendar={props.onHideCalendar}
            calendarIsHidden={props.calendarIsHidden}
          />
        }
      >
        <Content>
          <Checkbox
            label={"Schedule later"}
            checked={props.scheduleLater}
            onChange={toggleScheduleLater}
          />
          <Content spacing="small">
            <Heading level={5}>Dates</Heading>
            <div className={styles.datePicker}>
              <Sg1DatePicker
                data-testid="job-scheduler-start-date"
                placeholder="Start date"
                date={props.startDate}
                onChange={onChangeStartDate}
                disabled={props.scheduleLater}
              />
            </div>
          </Content>
          <Content spacing="small">
            <ScheduleEndInput
              ends={props.recurrence.ends}
              onChange={onChangeScheduleEnds}
              disabled={props.scheduleLater}
            />
          </Content>
          {(props.recurrence.ends.type === "duration" ||
            (props.recurrence.ends.type === "date" &&
              props.recurrence.ends.date > props.startDate)) && (
            <>
              <Content spacing="small">
                <RecurringScheduleDropdown
                  startDate={props.startDate}
                  recurringOption={props.selectedRecurringOption}
                  onChange={onChangeRecurringOptions}
                  disabled={props.scheduleLater}
                />
              </Content>
              {props.selectedRecurringOption === RecurrenceOptions.Custom && (
                <Content spacing="small">
                  <RecurringSelect
                    recurrenceRule={props.recurrence.rule}
                    recurrenceEnds={props.recurrence.ends}
                    disabled={props.scheduleLater}
                    onRecurrenceChange={onRecurrenceChange}
                  />
                </Content>
              )}
            </>
          )}
          <Content spacing="small">
            <Heading level={5}>Times</Heading>
            <Checkbox
              label={"Anytime on day of visit"}
              checked={props.anyTimeOfDay}
              onChange={toggleAnyTime}
              disabled={props.scheduleLater}
            />
            <ScheduleTimeInput
              start={props.startTime}
              end={props.endTime}
              isValid={true}
              startInputRef={startTimeInputRef}
              endInputRef={endTimeInputRef}
              onChange={onChangeScheduleTime}
              disabled={props.scheduleLater}
            />
          </Content>
          {showAdditionalDetail && (
            <Content spacing="small">
              <ScheduleSummary
                disabled={props.scheduleLater}
                startDate={
                  typeof schedule.firstOccurrence === "string"
                    ? "-"
                    : strFormatDate(schedule.firstOccurrence)
                }
                endDate={
                  schedule.lastOccurrence instanceof Date
                    ? strFormatDate(schedule.lastOccurrence)
                    : "-"
                }
                totalOccurences={schedule.totalOccurrence}
                summaryString={
                  schedule.summaryString
                    ? `Visits ${schedule.summaryString}`
                    : ""
                }
              />
            </Content>
          )}
        </Content>
      </Card>
    </div>
  );
};
