import { useCallback, useEffect } from "react";
import { useCampaignWizardContext } from "jobber/campaigns/contexts/CampaignWizardProvider";
import { Segment, Template } from "~/utilities/API/graphql";
import type {
  ClientSegmentFragment,
  CommsCampaignQuery,
} from "~/utilities/API/graphql";
import { isEnumValue } from "jobber/campaigns/utils";
import { usePreventEditCampaign } from "jobber/campaigns/hooks/usePreventEditCampaign/usePreventEditCampaign";
import { getRecommendedClientSegment } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentOptions/utils";
import { getClientsCriteriaFromSegment } from "jobber/campaigns/utils/segmentCriteriaUtils";

interface useStateInitializationProps {
  clientSegment?: ClientSegmentFragment;
  setClientSegment: (clientSegment: ClientSegmentFragment | undefined) => void;
  data?: CommsCampaignQuery;
  templateType?: string;
  onCampaignNotFound: () => void;
  loading: boolean;
}

export function useStateInitialization({
  clientSegment,
  setClientSegment,
  data,
  templateType,
  onCampaignNotFound,
  loading,
}: useStateInitializationProps) {
  usePreventEditCampaign({ campaignStatus: data?.commsCampaign?.status });

  const initialClientSegment = data?.commsCampaign?.clientSegment;

  let campaignTemplateType = templateType as Template; // We will redirect if this is not a valid Template
  if (
    data?.commsCampaign?.templates &&
    data.commsCampaign.templates.nodes.length > 0
  ) {
    campaignTemplateType = data.commsCampaign.templates.nodes[0].type;
  }

  if (
    campaignTemplateType !== undefined &&
    !isEnumValue(Template, campaignTemplateType)
  ) {
    onCampaignNotFound();
  }

  const {
    campaignContent: { setTemplateType },
    campaignSegment: {
      setNotDirty,
      setSelectedSegmentType,
      updateAllClientsAdditionalCriteria,
      updatePastClientsAdditionalCriteria,
      updateUpcomingClientsAdditionalCriteria,
      setAllClientsSegmentCriteria,
      setPastClientsSegmentCriteria,
      setUpcomingClientsSegmentCriteria,
    },
  } = useCampaignWizardContext();

  const initializeSegmentState = useCallback(() => {
    switch (initialClientSegment?.type) {
      case Segment.ALL_CLIENTS:
        updateAllClientsAdditionalCriteria({
          type: "setState",
          payload: {
            additionalCriteria: initialClientSegment?.additionalCriteria?.nodes,
          },
        });
        setAllClientsSegmentCriteria(
          getClientsCriteriaFromSegment({
            clientSegmentType: initialClientSegment?.type,
            criteria: initialClientSegment?.criteria.nodes || [],
            additionalCriteria:
              initialClientSegment?.additionalCriteria?.nodes || [],
          }),
        );
        return;
      case Segment.PAST_CLIENTS:
        updatePastClientsAdditionalCriteria({
          type: "setState",
          payload: {
            additionalCriteria: initialClientSegment?.additionalCriteria?.nodes,
          },
        });
        setPastClientsSegmentCriteria(
          getClientsCriteriaFromSegment({
            clientSegmentType: initialClientSegment?.type,
            criteria: initialClientSegment?.criteria.nodes || [],
            additionalCriteria:
              initialClientSegment?.additionalCriteria?.nodes || [],
          }),
        );
        return;
      case Segment.UPCOMING_CLIENTS:
        updateUpcomingClientsAdditionalCriteria({
          type: "setState",
          payload: {
            additionalCriteria: initialClientSegment?.additionalCriteria?.nodes,
          },
        });
        setUpcomingClientsSegmentCriteria(
          getClientsCriteriaFromSegment({
            clientSegmentType: initialClientSegment?.type,
            criteria: initialClientSegment?.criteria.nodes || [],
            additionalCriteria:
              initialClientSegment?.additionalCriteria?.nodes || [],
          }),
        );
        return;
    }
  }, [
    initialClientSegment?.additionalCriteria?.nodes,
    initialClientSegment?.criteria.nodes,
    initialClientSegment?.type,
    setAllClientsSegmentCriteria,
    setPastClientsSegmentCriteria,
    setUpcomingClientsSegmentCriteria,
    updateAllClientsAdditionalCriteria,
    updatePastClientsAdditionalCriteria,
    updateUpcomingClientsAdditionalCriteria,
  ]);

  useEffect(() => {
    if (campaignTemplateType) {
      setTemplateType(campaignTemplateType);
    }
  }, [campaignTemplateType, setTemplateType]);

  useEffect(() => {
    if (!clientSegment && initialClientSegment) {
      setClientSegment(initialClientSegment);
      setSelectedSegmentType(initialClientSegment?.type);
      initializeSegmentState();
      setNotDirty();
    } else if (!loading && !initialClientSegment && campaignTemplateType) {
      const recommendedDefault =
        getRecommendedClientSegment(campaignTemplateType);
      setSelectedSegmentType(recommendedDefault, true);
    }
  }, [
    initialClientSegment,
    clientSegment,
    setNotDirty,
    setSelectedSegmentType,
    campaignTemplateType,
    loading,
    setClientSegment,
    initializeSegmentState,
  ]);

  return { campaignTemplateType };
}
