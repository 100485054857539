import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Banner } from "@jobber/components/Banner";
import { debounce } from "lodash";
import { Glimmer } from "@jobber/components/Glimmer";
import { Form } from "@jobber/components/Form";
import { useFormState } from "@jobber/hooks/useFormState";
import classnames from "classnames";
import { useInView } from "@jobber/hooks/useInView";
import type {
  IntervalUnits,
  PastClientsCriteriaInput,
} from "~/utilities/API/graphql";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { DrawerMode } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentSideDrawer";
import { useIntervalValidation } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useIntervalValidation/useIntervalValidation";
import { BaseCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/BaseCriteria";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { AdditionalCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria";
import { SEGMENT_CLIENT_DEBOUNCE_TIME } from "jobber/campaigns/constants";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import styles from "./PastClientsSegmentEdit.module.css";
import { messages } from "./messages";
import { INTERVAL_UNIT_OPTIONS } from "./constants";

export interface PastClientsSegmentEditProps {
  segmentCriteria: PastClientsCriteriaInput;
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    value: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  setDrawerState: React.Dispatch<React.SetStateAction<DrawerMode>>;
  onSave: (segmentCriteria: PastClientsCriteriaInput) => void;
  closeSideDrawer: () => void;
  saveError?: string;
  loading?: boolean;
  pageSize?: number;
}

const clientSegmentDataConditionalVariables = {
  isPastClientDrawer: true,
};

export function PastClientsSegmentEdit({
  segmentCriteria,
  additionalCriteria,
  updateAdditionalCriteria,
  setDrawerState,
  closeSideDrawer,
  onSave,
  saveError,
  loading,
  pageSize = 20,
}: PastClientsSegmentEditProps): JSX.Element {
  const { formatMessage } = useIntl();

  const [interval, setInterval] = useState<number>(segmentCriteria.interval);
  const [unit, setUnit] = useState<IntervalUnits>(segmentCriteria.unit);

  const { validationMessage, setValidationMessage, validateInterval } =
    useIntervalValidation();

  const [{ isValid, isDirty }, setFormState] = useFormState();

  const isFormValid = useCallback(() => {
    return (!isDirty || isValid) && validateInterval(interval) === true;
  }, [interval, isValid, validateInterval, isDirty]);

  const {
    refetch: fetchData,
    fullReload,
    error: refreshError,
    clientSegmentsData,
  } = useClientSegmentData({
    conditionalVariables: clientSegmentDataConditionalVariables,
  });

  const errorMessage = useMemo(() => {
    const refreshErrorMessage = refreshError
      ? formatMessage(messages.refreshErrorMessage)
      : "";
    const saveErrorMessage = saveError
      ? formatMessage(messages.saveErrorMessage)
      : "";
    return saveErrorMessage || refreshErrorMessage;
  }, [formatMessage, refreshError, saveError]);

  const debounceFetchData = useMemo(
    () =>
      debounce((newSegmentCriteria: PastClientsCriteriaInput) => {
        const {
          clientTagCriteria,
          lineItemCriteria,
          jobTypeCriteria,
          ...newCriteria
        } = newSegmentCriteria;
        fetchData({
          first: pageSize,
          after: btoa("0"),
          ...{
            ...newCriteria,
            pastClientTagCriteria: clientTagCriteria,
            pastClientLineItemCriteria: lineItemCriteria,
            pastClientJobTypeCriteria: jobTypeCriteria,
          },
        });
      }, SEGMENT_CLIENT_DEBOUNCE_TIME),
    [fetchData, pageSize],
  );

  useEffect(() => {
    if (unit && interval && isFormValid()) {
      debounceFetchData({
        unit,
        interval,
        clientTagCriteria:
          parseAdditionalCriteria(additionalCriteria).clientTagCriteria,
        lineItemCriteria:
          parseAdditionalCriteria(additionalCriteria).lineItemCriteria,
        jobTypeCriteria:
          parseAdditionalCriteria(additionalCriteria).jobTypeCriteria,
      });
    }
  }, [
    unit,
    interval,
    validationMessage,
    debounceFetchData,
    isFormValid,
    additionalCriteria,
  ]);

  const [ref, bodyHasBeenScrolled] = useInView<HTMLDivElement>();

  return (
    <PrivacyMask disabled>
      <div className={styles.clientSegmentDrawer}>
        <div
          className={classnames(styles.drawerHeaderNoLeftPad, {
            [styles.scrollable]: !bodyHasBeenScrolled,
          })}
        >
          <Heading level={3}>
            <Button
              variation="subtle"
              type="tertiary"
              icon="arrowLeft"
              ariaLabel="arrowLeft"
              onClick={() => setDrawerState(DrawerMode.View)}
            />
            {formatMessage(messages.clientSegmentEditSideDrawerTitle)}
          </Heading>
          <Button
            variation="subtle"
            type="tertiary"
            icon="remove"
            ariaLabel="close"
            onClick={closeSideDrawer}
          />
        </div>
        <div className={styles.drawerFormContent}>
          <div ref={ref}></div>
          <Form
            onStateChange={setFormState}
            onSubmit={() =>
              onSave({
                unit: unit,
                interval: interval,
                clientTagCriteria:
                  parseAdditionalCriteria(additionalCriteria).clientTagCriteria,
                jobTypeCriteria:
                  parseAdditionalCriteria(additionalCriteria).jobTypeCriteria,
                lineItemCriteria:
                  parseAdditionalCriteria(additionalCriteria).lineItemCriteria,
              })
            }
          >
            <Content spacing="large">
              {errorMessage && <Banner type="error">{errorMessage}</Banner>}
              <Content>
                <BaseCriteria
                  segmentCriteria={segmentCriteria}
                  setInterval={setInterval}
                  validationMessage={validationMessage}
                  setValidationMessage={setValidationMessage}
                  validateInterval={validateInterval}
                  setUnit={setUnit}
                  intervalUnitOptions={INTERVAL_UNIT_OPTIONS}
                  preFieldsDescription={formatMessage(
                    messages.pastClientsDescription1,
                  )}
                  postFieldsDescription={formatMessage(
                    messages.pastClientsDescription2,
                  )}
                />
                <AdditionalCriteria
                  criteria={additionalCriteria}
                  updateAdditionalCriteria={(
                    value: AdditionalCriteriaReducerActions,
                  ) => updateAdditionalCriteria(value, true)}
                />
              </Content>
              <Text>
                {fullReload ? (
                  <Glimmer.Text />
                ) : (
                  <>
                    <Emphasis variation="bold">
                      {clientSegmentsData.pastClients?.total} clients{" "}
                    </Emphasis>
                    {formatMessage(messages.pastClientsDescription3)}
                  </>
                )}
              </Text>
              <Button
                type="primary"
                fullWidth={true}
                label={formatMessage(
                  messages.clientSegmentEditSideDrawerUpdate,
                )}
                loading={loading}
                submit={true}
              />
            </Content>
          </Form>
        </div>
      </div>
    </PrivacyMask>
  );
}
