import { useCallback, useState } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import { useHandleUpdatedClientSegment } from "jobber/campaigns/hooks/useHandleUpdatedClientSegment";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { type ClientSegmentFragment, Segment } from "~/utilities/API/graphql";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
  CAMPAIGNS_TEMPLATE_EDIT_PATH,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/views/CampaignRecipientsPage/components/SelectClientSegment/messages";

export interface UseSaveOnDirtyArgs {
  campaignId?: string;
  initialClientSegment: ClientSegmentFragment | undefined;
  onNextNavigation: () => void;
  setIsInErrorState: (isInErrorState: boolean) => void;
  renderExitConfirmationModal: boolean;
  setRenderExitConfirmationModal: (newValue: boolean) => void;
}
export function useSaveOnDirty({
  initialClientSegment,
  onNextNavigation,
  setIsInErrorState,
  renderExitConfirmationModal,
  setRenderExitConfirmationModal,
}: UseSaveOnDirtyArgs) {
  const {
    campaignSegment: {
      selectedSegmentType,
      pastClientsAdditionalCriteria,
      allClientsAdditionalCriteria,
      upcomingClientsAdditionalCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
      isDirty,
      segmentCriteriaFormRef,
      isSegmentCriteriaFormValid,
    },
  } = useCampaignWizardContext();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { formatMessage } = useIntl();

  const { updateClientSegment, loadingDefaultTemplate } =
    useHandleUpdatedClientSegment({
      clientSegment: initialClientSegment,
      segmentType: selectedSegmentType,
    });

  const [updateSegmentCriteriaLoading, setUpdateSegmentCriteriaLoading] =
    useState(false);

  const getNewSegmentCriteria = useCallback(() => {
    switch (selectedSegmentType) {
      case Segment.ALL_CLIENTS:
        return {
          clientTagCriteria: parseAdditionalCriteria(
            allClientsAdditionalCriteria,
          ).clientTagCriteria,
          clientTypeCriteria: parseAdditionalCriteria(
            allClientsAdditionalCriteria,
          ).clientTypeCriteria,
          jobTypeCriteria: parseAdditionalCriteria(allClientsAdditionalCriteria)
            .jobTypeCriteria,
          lineItemCriteria: parseAdditionalCriteria(
            allClientsAdditionalCriteria,
          ).lineItemCriteria,
          jobStatusCriteria: parseAdditionalCriteria(
            allClientsAdditionalCriteria,
          ).jobStatusCriteria,
        };
      case Segment.PAST_CLIENTS:
        return {
          interval: pastClientsSegmentCriteria.interval,
          unit: pastClientsSegmentCriteria.unit,
          clientTagCriteria: parseAdditionalCriteria(
            pastClientsAdditionalCriteria,
          ).clientTagCriteria,
          jobTypeCriteria: parseAdditionalCriteria(
            pastClientsAdditionalCriteria,
          ).jobTypeCriteria,
          lineItemCriteria: parseAdditionalCriteria(
            pastClientsAdditionalCriteria,
          ).lineItemCriteria,
        };
      case Segment.UPCOMING_CLIENTS:
        return {
          interval: upcomingClientsSegmentCriteria.interval,
          unit: upcomingClientsSegmentCriteria.unit,
          clientTagCriteria: parseAdditionalCriteria(
            upcomingClientsAdditionalCriteria,
          ).clientTagCriteria,
          jobTypeCriteria: parseAdditionalCriteria(
            upcomingClientsAdditionalCriteria,
          ).jobTypeCriteria,
          lineItemCriteria: parseAdditionalCriteria(
            upcomingClientsAdditionalCriteria,
          ).lineItemCriteria,
          jobStatusCriteria: parseAdditionalCriteria(
            upcomingClientsAdditionalCriteria,
          ).jobStatusCriteria,
        };
      default:
        throw new Error("Invalid segment type");
    }
  }, [
    allClientsAdditionalCriteria,
    pastClientsAdditionalCriteria,
    pastClientsSegmentCriteria.interval,
    pastClientsSegmentCriteria.unit,
    selectedSegmentType,
    upcomingClientsAdditionalCriteria,
    upcomingClientsSegmentCriteria.interval,
    upcomingClientsSegmentCriteria.unit,
  ]);

  const saveOnNext = useCallback(async () => {
    if (!isDirty) {
      onNextNavigation();
      return;
    }

    if (updateSegmentCriteriaLoading || loadingDefaultTemplate) {
      return;
    }

    if (segmentCriteriaFormRef?.current !== null) {
      segmentCriteriaFormRef?.current?.submit();
      if (!isSegmentCriteriaFormValid) {
        return;
      }
    }

    setUpdateSegmentCriteriaLoading(true);

    await updateClientSegment({
      newSegmentCriteria: getNewSegmentCriteria(),
      // eslint-disable-next-line max-statements
      onSuccess: ({ campaignId }) => {
        const isEditPath = path !== "edit";
        const hasCampaignId = !!campaignId;

        if (isEditPath && hasCampaignId) {
          const newPath = renderExitConfirmationModal
            ? generatePath(CAMPAIGNS_TEMPLATE_EDIT_PATH, { campaignId })
            : generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, { campaignId });

          setRenderExitConfirmationModal(false);
          history.replace(newPath);
        }

        showToast({ message: formatMessage(messages.savedClientSegment) });
        setUpdateSegmentCriteriaLoading(false);

        if (hasCampaignId) {
          const newPath = renderExitConfirmationModal
            ? generatePath(CAMPAIGNS_TEMPLATE_EDIT_PATH, { campaignId })
            : generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, { campaignId });

          setRenderExitConfirmationModal(false);
          history.push(newPath);
        } else {
          onNextNavigation();
        }
      },
      onError: () => {
        setUpdateSegmentCriteriaLoading(false);
        setIsInErrorState(true);
      },
    });
  }, [
    isDirty,
    updateSegmentCriteriaLoading,
    loadingDefaultTemplate,
    segmentCriteriaFormRef,
    updateClientSegment,
    getNewSegmentCriteria,
    onNextNavigation,
    isSegmentCriteriaFormValid,
    path,
    formatMessage,
    renderExitConfirmationModal,
    setRenderExitConfirmationModal,
    history,
    setIsInErrorState,
  ]);
  return {
    saveOnNext,
    updateSegmentCriteriaLoading,
  };
}
