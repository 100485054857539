import { fireEvent, screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { messages as pastClientsEditMessages } from "jobber/campaigns/views/SelectClientSegmentPage/components/PastClientsSegmentEdit/messages";
import { messages as setAutomationRulesMessages } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/messages";
import { messages as bottomBarMessages } from "jobber/campaigns/components/Bottombar/messages";
import { messages as selectClientSegmentMessages } from "./components/SelectClientSegment/messages";

// selectors
export const getClientSegmentPageTitle = () => {
  return screen.getByText(
    selectClientSegmentMessages.clientSegmentPageTitle.defaultMessage,
  );
};

export const getAllClientsSegmentCard = () => {
  return screen.getByText(
    selectClientSegmentMessages.allClientsTitle.defaultMessage,
  );
};

export const getUpcomingClientsSegmentCard = () => {
  return screen.getByText(
    selectClientSegmentMessages.upcomingVisitClientsTitle.defaultMessage,
  );
};

export const getUpcomingClientsIntervalInput = () => {
  return screen.getByDisplayValue("30");
};

export const getUpcomingClientsErrorMessage = () => {
  return screen.getByText("Enter a number between 1 and 500");
};

export const getClientSegmentSideDrawerTitle = () => {
  return screen.getByText(
    selectClientSegmentMessages.clientSegmentSideDrawerTitle.defaultMessage,
  );
};

export const getPastClientsEditSideDrawerTitle = () => {
  return screen.getByText(
    pastClientsEditMessages.clientSegmentEditSideDrawerTitle.defaultMessage,
  );
};

export const getMultipleConditionErrors = () => {
  return screen.getByText(
    setAutomationRulesMessages.mutlipleConditionErrors.defaultMessage,
  );
};

export const getSetAutomationRulesTitle = () => {
  return screen.getByText(
    selectClientSegmentMessages.automationsTitle.defaultMessage,
  );
};

export const getAutomatedQuoteFollowUpsAreActiveBannerText = () => {
  return screen.getByText(
    "Automated quote follow-ups are active. The last follow-up is set at 30 days after a quote is sent.",
  );
};

export const getPreviewClientSegmentSideDrawerTitle = () => {
  return screen.getByText(
    selectClientSegmentMessages.previewClientSegmentSideDrawerTitle
      .defaultMessage,
  );
};

export const getEditButton = () => {
  return screen.getByRole("button", {
    name: selectClientSegmentMessages.edit.defaultMessage,
  });
};

export const getRemoveSideDrawerButton = () => {
  return screen.getByTestId("remove");
};

export const getEditCriteriaButton = () => {
  return screen.getByRole("button", {
    name: selectClientSegmentMessages.clientSegmentSideDrawerButton
      .defaultMessage,
  });
};

export const getSideDrawerBackButton = () => {
  return screen.getByTestId("arrowLeft");
};

export const getContinueToContentButton = () => {
  return screen.getByRole("button", {
    name: bottomBarMessages.continueToContent.defaultMessage,
  });
};

// solo actions

export const iClickAllClientsSegmentCard = () => {
  return userEvent.click(getAllClientsSegmentCard());
};

export const iClickUpcomingClientsSegmentCard = () => {
  return userEvent.click(getUpcomingClientsSegmentCard());
};

export const iClickEditButton = () => {
  return userEvent.click(getEditButton());
};

export const iClickRemoveSideDrawerButton = () => {
  return userEvent.click(getRemoveSideDrawerButton());
};

export const iClickEditCriteriaButton = () => {
  return userEvent.click(getEditCriteriaButton());
};

export const iClickSideDrawerBackButton = () => {
  return userEvent.click(getSideDrawerBackButton());
};

export const iChangeTheUpcomingClientsIntervalInput = (value: string) => {
  fireEvent.change(getUpcomingClientsIntervalInput(), {
    target: { value: value },
  });
};

export const iClickContinueToContentButton = () => {
  return userEvent.click(getContinueToContentButton());
};
