import { defineMessages } from "react-intl";

export const messages = defineMessages({
  pageSubHeading: {
    id: "campaigns.clientSegmentPage.pageSubHeading",
    defaultMessage:
      "Choose which clients you want to send your email campaign to",
    description:
      "Subheading at the top of the client segment page for one-off campaigns",
  },
});
