import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

// selectors

export function getAllClientsSegmentInput() {
  return screen.getByDisplayValue("ALL_CLIENTS");
}

export function getAllClientsSegmentLabel() {
  return screen.getByTestId("ALL_CLIENTS label");
}

export function getUpcomingClientsSegmentInput() {
  return screen.getByDisplayValue("UPCOMING_CLIENTS");
}

export function getPastClientsSegmentInput() {
  return screen.getByDisplayValue("PAST_CLIENTS");
}

// solo actions

export function iClickAllClientsSegment() {
  return userEvent.click(getAllClientsSegmentInput());
}
