import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { ImageType, SetupWizardStepProps } from "jobber/setupWizard/types";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import companyImgSrc from "@images/signup/company-step.jpg";
import { companyFacts } from "jobber/setupWizard/components/MaskingTapeFact/facts";
import { SplitNames, useSplit } from "utilities/split";
import { messages } from "./messages";
import { CompanyPageForm } from "./components/CompanyPageForm";
import { MaskingTapeFact } from "../MaskingTapeFact";

export const rightHandSideImage: ImageType = {
  alt: messages.serviceProviderAltText,
  src: companyImgSrc,
};

export function CompanyPage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.SetupWizardWebsiteField],
  });
  const showWebsiteField = getTreatmentValue(
    SplitNames.SetupWizardWebsiteField,
    "second_page",
  );

  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.company,
    });
  }, []);

  const companyPageCopy = showWebsiteField
    ? formatMessage(messages.companyPageWithWebsiteCopy)
    : formatMessage(messages.companyPageDefaultCopy);

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={formatMessage(messages.companyPageDefaultHeading)}
          copy={companyPageCopy}
          form={
            <CompanyPageForm
              {...stepProps}
              showWebsiteField={showWebsiteField}
            />
          }
        />
      }
      rightComponent={
        <MediaColumn backgroundImage={rightHandSideImage}>
          <MaskingTapeFact fact={companyFacts.defaultFact.copy} />
        </MediaColumn>
      }
    />
  );
}
