import { fireEvent, screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages as googleCompanyAutocompleteMessages } from "jobber/setupWizard/components/GoogleCompanyAutocomplete/messages";
import { mockSetupWizardStepProps } from "jobber/setupWizard/testHelpers";
import type { Industry } from "~/utilities/API/graphql";
import { messages } from "./messages";

const { formatMessage } = createIntl();
const companyNamePlaceholder = formatMessage(messages.companyNamePlaceholder);
const companyNameAutocompletePlaceholder = formatMessage(
  googleCompanyAutocompleteMessages.companyNamePlaceholder,
);
const companyNameError = formatMessage(messages.companyNameError);
const industryPlaceholder = formatMessage(messages.industryPlaceholder);
const industryError = formatMessage(messages.industryError);
const websitePlaceholder = formatMessage(messages.websitePlaceholder);

export const companyNameInput = () =>
  screen.findByRole("textbox", {
    name: companyNamePlaceholder,
  });
export const companyNameInputLabel = () =>
  screen.findByLabelText(companyNamePlaceholder);
export const companyNameAutocompleteInput = () =>
  screen.findByRole("textbox", {
    name: companyNameAutocompletePlaceholder,
  });
export const companyNameAutocompleteInputLabel = () =>
  screen.findByLabelText(companyNameAutocompletePlaceholder);
export const companyNameAutocompleteInputWrapper = () =>
  screen.findByTestId("autocomplete-company-input");
export const queryCompanyNameAutocompleteInputWrapper = () =>
  screen.queryByTestId("autocomplete-company-input");
export const companyDescription = (description: string) =>
  screen.findByText(description);
export const changeCompanyNameInput = async (value: string) =>
  fireEvent.change(await companyNameInput(), {
    target: { value },
  });
export const companyNameAutocompleteOption = (option: string) =>
  screen.findByText(option);
export const companyNameErrorText = () => screen.findByText(companyNameError);
export const manualCompanyNameOption = () =>
  screen.findByText(
    `+ ${formatMessage(googleCompanyAutocompleteMessages.manualCompanyNameLabel)}`,
  );
export const clickCompanyOption = (option: HTMLElement) =>
  userEvent.click(option);
export const focusCompanyNameAutocompleteInput = async () =>
  fireEvent.focus(await companyNameAutocompleteInput());
export const changeCompanyNameAutocompleteInput = async (value: string) =>
  fireEvent.change(await companyNameAutocompleteInput(), {
    target: { value },
  });
export const pressEnter = async (option: HTMLElement) =>
  fireEvent(
    option,
    new KeyboardEvent("keydown", {
      key: "Enter",
      bubbles: true,
      cancelable: false,
    }),
  );

export const industryInput = () =>
  screen.findByRole("textbox", {
    name: industryPlaceholder,
  });
export const industryInputLabel = () =>
  screen.findByLabelText(industryPlaceholder);
export const industryErrorText = () => screen.findByText(industryError);
export const industryDisplayName = (name: string) => screen.findByText(name);
export const industryCategory = (category: string) =>
  screen.findByText(category);
export const clickIndustryInput = async () =>
  userEvent.click(await industryInput());
export const clickIndustryOption = async (option: HTMLElement) =>
  userEvent.click(option);
export const typeIndustry = async (industry: Industry) =>
  userEvent.type(await industryInput(), industry);

export const websiteInput = () =>
  screen.findByRole("textbox", {
    name: websitePlaceholder,
  });
export const queryWebsiteInput = () =>
  screen.queryByRole("textbox", {
    name: websitePlaceholder,
  });
export const enterWebsite = async (website: string) =>
  fireEvent.change(await websiteInput(), {
    target: { value: website },
  });
export const urlErrorText = () =>
  screen.findByText(messages.urlError.defaultMessage);

export const nextCTA = () =>
  screen.findByText(mockSetupWizardStepProps.navigation.nextCtaCopy);
export const clickNextCTA = async () => userEvent.click(await nextCTA());
