/* eslint-disable max-statements */
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  Autocomplete,
  Button,
  Content,
  Form,
  InputText,
  InputValidation,
} from "@jobber/components";
import type { Option } from "@jobber/components/Autocomplete";
import type { Industry } from "~/utilities/API/graphql";
import { Routes } from "jobber/setupWizard/types";
import { SplitNames, useSplit } from "utilities/split";
import type {
  AutocompleteCompany,
  IndustryCategory,
  SetupWizardStepProps,
} from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import {
  generateLastSubmittedStepQandA,
  safelyExtractAnswer,
} from "jobber/setupWizard/utils";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import { googleAutocompleteTrackingData } from "jobber/setupWizard/components/GoogleCompanyAutocomplete/helpers";
import { messages as googleCompanyAutocompleteMessages } from "jobber/setupWizard/components/GoogleCompanyAutocomplete/messages";
import { messages } from "./messages";
import styles from "../../SetupWizardForms.module.css";
import { GoogleCompanyAutocomplete } from "../../GoogleCompanyAutocomplete";

export function CompanyPageForm({
  navigation,
  industryMap,
  onMobileWeb,
  signupConsentData,
  showWebsiteField,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.GoogleCompanyAutocomplete],
  });
  const googleCompanyLookupEnabled = getTreatmentValue(
    SplitNames.GoogleCompanyAutocomplete,
  );
  const { isCompanyNameManual, wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [companyName, setCompanyName] = useState<string | undefined>(
    wizardData.companyName ?? "",
  );
  const [autocompleteCompany, setAutocompleteCompany] = useState<
    AutocompleteCompany | undefined
  >();
  const [website, setWebsite] = useState<string>(() => {
    return safelyExtractAnswer("website", wizardData.questionsAndAnswers);
  });
  const [industry, setIndustry] = useState<Option | undefined>(
    getHeadingOptions(industryMap)
      .flatMap(option => option.options)
      .find(option => option.value === wizardData?.industry),
  );

  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  const handleSubmit = async () => {
    if (!companyName || !industry) {
      setShowValidationErrors(true);
      return;
    }

    if (
      googleCompanyLookupEnabled &&
      autocompleteCompany &&
      !isCompanyNameManual
    ) {
      const trackingData = googleAutocompleteTrackingData(autocompleteCompany);
      Amplitude.TRACK_EVENT(
        ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete.eventName,
        {
          experiment:
            ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete.experiment,
          interaction:
            ANALYTICS_EVENTS.interactedWithGoogleCompanyAutocomplete.interaction
              .submittedGoogleCompanyData,
          source: JSON.stringify(trackingData),
        },
      );
    }

    if (website && autocompleteCompany?.website !== website) {
      Amplitude.TRACK_EVENT("Interacted with Experiment", {
        experiment: "setup_wizard_website_field",
        interaction: "Submitted website",
        source: "Second page",
        website: website,
      });
    }

    const dataToSend = {
      companyName,
      industry: industry?.value as Industry | undefined,
      questionsAndAnswers: [
        { question: "website", answer: website },
        ...(autocompleteCompany?.placeId
          ? [
              {
                question: "google_place_id",
                answer: autocompleteCompany.placeId,
              },
            ]
          : []),
        generateLastSubmittedStepQandA(Routes.company),
      ],
    };

    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  const handleCompanyNameChange = (name: string) => {
    setCompanyName(name);
  };

  const handleCompanyAutocompleteChange = (company: AutocompleteCompany) => {
    setAutocompleteCompany(company.placeId ? company : undefined);
    setCompanyName(company.name);
    setWebsite(company.website ?? "");
  };

  const handleIndustryChange = (selectedIndustry: Option) => {
    setIndustry(selectedIndustry);
    updateWizardData(
      { industry: selectedIndustry?.value as Industry | undefined },
      false,
      undefined,
      true,
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Content spacing="large">
        <Content spacing="minuscule">
          {googleCompanyLookupEnabled && !isCompanyNameManual ? (
            <GoogleCompanyAutocomplete
              invalid={!companyName && showValidationErrors}
              onChange={handleCompanyAutocompleteChange}
              value={autocompleteCompany?.placeId}
              valueLabel={companyName}
              signupConsentCountry={signupConsentData.consentRegion}
              fallback={
                <InputText
                  clearable="while-editing"
                  name="companyName"
                  placeholder={formatMessage(
                    googleCompanyLookupEnabled
                      ? googleCompanyAutocompleteMessages.companyNamePlaceholder
                      : messages.companyNamePlaceholder,
                  )}
                  value={companyName}
                  onChange={handleCompanyNameChange}
                  size="large"
                  invalid={!companyName && showValidationErrors}
                />
              }
            />
          ) : (
            <InputText
              clearable="while-editing"
              name="companyName"
              placeholder={formatMessage(
                googleCompanyLookupEnabled
                  ? googleCompanyAutocompleteMessages.companyNamePlaceholder
                  : messages.companyNamePlaceholder,
              )}
              value={companyName}
              onChange={handleCompanyNameChange}
              size="large"
              invalid={!companyName && showValidationErrors}
            />
          )}
          {!companyName && showValidationErrors && (
            <InputValidation
              message={formatMessage(messages.companyNameError)}
            />
          )}
        </Content>
        <Content spacing="minuscule">
          <Autocomplete
            allowFreeForm={false}
            clearable={"while-editing"}
            initialOptions={getHeadingOptions(industryMap)}
            value={industry}
            name="industry"
            placeholder={formatMessage(messages.industryPlaceholder)}
            onChange={handleIndustryChange}
            size="large"
            getOptions={text => getOptions(text, industryMap)}
            invalid={!industry && showValidationErrors}
          />
          {!industry && showValidationErrors && (
            <InputValidation message={formatMessage(messages.industryError)} />
          )}
        </Content>
        {showWebsiteField && (
          <InputText
            name="website"
            placeholder={formatMessage(messages.websitePlaceholder)}
            value={website}
            onChange={(input: string) => setWebsite(input)}
            size="large"
            onFocus={() =>
              Amplitude.TRACK_EVENT("Interacted with Experiment", {
                experiment: "setup_wizard_website_field",
                interaction: "Focused on website field",
                source: "Second page",
              })
            }
            validations={{
              pattern: {
                value: /\.([a-zA-Z]{2,})\/?$/,
                message: formatMessage(messages.urlError),
              },
            }}
          />
        )}
        <div className={styles.button}>
          <Button
            id={"companyPageSubmit"}
            fullWidth={onMobileWeb}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}

const getHeadingOptions = (industryMap: IndustryCategory[]) => {
  return industryMap.map(obj => {
    return {
      label: obj.category,
      options: obj.industries.map(industry => ({
        value: industry.industryEnum,
        label: industry.displayName,
      })),
    };
  });
};

function getOptions(text: string, industryMap: IndustryCategory[]): Option[] {
  const headingOptions = getHeadingOptions(industryMap);

  if (text === "") {
    return headingOptions;
  }

  const filterRegex = new RegExp(text, "i");

  return headingOptions.map(section => ({
    ...section,
    options: section.options.filter(option => option.label.match(filterRegex)),
  }));
}
