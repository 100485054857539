import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useClientSegmentTable } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentTable/useClientSegmentTable";
import { messages } from "jobber/campaigns/views/CampaignRecipientsPage/components/SelectClientSegment/messages";
import { ClientSegmentTable } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentTable/ClientSegmentTable";
import { Segment } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import styles from "./ClientSegmentDrawer.module.css";

export interface ClientSegmentViewProps {
  segmentName: string;
  selectedSegment: Segment;
  closeSideDrawer(): void;
}

export function ClientSegmentView({
  segmentName,
  selectedSegment,
  closeSideDrawer,
}: ClientSegmentViewProps) {
  const {
    campaignSegment: {
      allClientsSegmentCriteria,
      allClientsAdditionalCriteria,
      pastClientsSegmentCriteria,
      pastClientsAdditionalCriteria,
      upcomingClientsSegmentCriteria,
      upcomingClientsAdditionalCriteria,
    },
  } = useCampaignWizardContext();
  const segmentCriterias = useMemo(() => {
    return {
      allClientsSegmentCriteria: {
        ...allClientsSegmentCriteria,
        ...parseAdditionalCriteria(allClientsAdditionalCriteria),
      },
      pastClientsSegmentCriteria: {
        ...pastClientsSegmentCriteria,
        ...parseAdditionalCriteria(pastClientsAdditionalCriteria),
      },
      upcomingClientsSegmentCriteria: {
        ...upcomingClientsSegmentCriteria,
        ...parseAdditionalCriteria(upcomingClientsAdditionalCriteria),
      },
    };
  }, [
    allClientsAdditionalCriteria,
    allClientsSegmentCriteria,
    pastClientsAdditionalCriteria,
    pastClientsSegmentCriteria,
    upcomingClientsAdditionalCriteria,
    upcomingClientsSegmentCriteria,
  ]);

  const { fetchData, fullReload, loadingMore, refetch, clientSegmentsData } =
    useClientSegmentData({
      conditionalVariables: {
        isPastClientDrawer: selectedSegment === Segment.PAST_CLIENTS,
        isAllClientDrawer: selectedSegment === Segment.ALL_CLIENTS,
        isUpcomingClientDrawer: selectedSegment === Segment.UPCOMING_CLIENTS,
      },
      segmentCriterias,
      segmentType: selectedSegment,
    });

  const {
    getPaginationProps,
    clientSegmentSortingState,
    resetPagination,
    clientSegment,
    handleSortingChange,
  } = useClientSegmentTable({
    selectedSegment: selectedSegment,
    refetch: refetch,
    fetchData: fetchData,
    clientSegments: clientSegmentsData,
    loadingState: { fullReload, loadingMore },
  });

  return (
    <div className={styles.clientSegmentDrawer}>
      <PrivacyMask disabled>
        <DrawerHeader onClose={closeSideDrawerFn} />

        <div className={styles.segmentsCriteriaContainer}>
          <div>
            <Heading level={4}>{segmentName}</Heading>
            <SegmentClientsTotal
              loading={fullReload}
              total={clientSegment.total}
            />
          </div>
        </div>
      </PrivacyMask>

      <PrivacyMask>
        <div className={styles.tableContainer}>
          <ClientSegmentTable
            pagination={getPaginationProps(clientSegment.total || 0)}
            loading={fullReload || loadingMore}
            clientData={clientSegment.clientData}
            sorting={{
              manualSorting: true,
              state: clientSegmentSortingState,
              onSortingChange: handleSortingChange,
            }}
          />
        </div>
      </PrivacyMask>
    </div>
  );

  function closeSideDrawerFn() {
    closeSideDrawer();
    resetPagination();
  }
}

interface DrawerHeaderProps {
  onClose: () => void;
}
function DrawerHeader({ onClose }: DrawerHeaderProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.drawerHeader}>
      <Heading level={3}>
        {formatMessage(messages.previewClientSegmentSideDrawerTitle)}
      </Heading>
      <Button
        variation="subtle"
        type="tertiary"
        icon="remove"
        ariaLabel="close"
        onClick={onClose}
      />
    </div>
  );
}

interface SegmentCountProps {
  loading?: boolean;
  total?: number;
}
function SegmentClientsTotal({ loading, total }: SegmentCountProps) {
  const { formatMessage } = useIntl();

  if (loading) return <Glimmer />;
  return (
    <Text variation="subdued">
      {formatMessage(messages.clients, {
        totalCount: total,
      })}
    </Text>
  );
}
