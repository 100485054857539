import React from "react";
import { Card, Content, Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { messages } from "./messages";
import { UpcomingPayouts } from "./UpcomingPayouts";
import { useUpcomingPayouts } from "./useUpcomingPayouts";
import { JobberMoneyPayouts } from "./components/JobberMoneyPayouts/JobberMoneyPayouts";
import { LoadingLayout } from "../LoadingLayout";

interface UpcomingPayoutsLoaderProps {
  instantPayoutDebitCardInfo?: CreditCard;
  instantPayoutFeeMultiplier: string;
}

export const UpcomingPayoutsLoader = (
  props: UpcomingPayoutsLoaderProps,
): React.ReactElement => {
  const { data, loading, refetch } = useUpcomingPayouts();
  const { formatMessage } = useIntl();
  let cardContent;

  if (loading) {
    cardContent = <LoadingLayout />;
  } else if (data.showJobberMoneyPayouts) {
    const { lastPayoutAmount, availableBalance } = data;
    cardContent = (
      <JobberMoneyPayouts
        availableBalance={availableBalance}
        lastPayoutAmount={lastPayoutAmount}
      />
    );
  } else {
    const {
      expectedPayouts,
      processingPayout,
      instantPayout,
      instantPayoutRequestedLast24Hours,
      hasValidPayoutDebitCard,
    } = data;
    cardContent = (
      <UpcomingPayouts
        expectedPayouts={expectedPayouts}
        processingPayout={processingPayout}
        instantPayout={instantPayout}
        instantPayoutDebitCardInfo={props.instantPayoutDebitCardInfo}
        instantPayoutFeeMultiplier={props.instantPayoutFeeMultiplier}
        instantPayoutRequestedLast24Hours={instantPayoutRequestedLast24Hours}
        hasValidPayoutDebitCard={hasValidPayoutDebitCard}
        refetchValues={refetch}
      />
    );
  }

  return (
    <Card>
      <Content>
        <Heading level={3}>
          {formatMessage(messages.upcomingPayoutsHeading)}
        </Heading>
        {cardContent}
      </Content>
    </Card>
  );
};
