import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Glimmer } from "@jobber/components/Glimmer";
import React from "react";
import styles from "./MarketingSuiteAddon.module.css";

export function MarketingSuiteAddonLoadingGlimmer() {
  return (
    <div
      className={styles.glimmerContainer}
      data-testid="Marketing-Suite-Loading-Glimmer"
    >
      <Content>
        <Card>
          <Content spacing="large">
            <Glimmer.Header />
            <div className={styles.glimmerCard}>
              <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`}>
                <GlimmerField short={true} />
              </div>
              <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`}>
                <GlimmerField short={true} />
              </div>
              <div className={`${styles.glimmerCol} ${styles.glimmerColLarge}`}>
                <GlimmerField short={true} />
              </div>
            </div>
            <div className={`${styles.glimmerRow} ${styles.glimmerColLarge}}`}>
              <GlimmerField short={true} />
            </div>
          </Content>
        </Card>
      </Content>
    </div>
  );
}

export interface GlimmerFieldProps {
  short?: boolean;
}
export const GlimmerField = ({ short }: GlimmerFieldProps) => {
  const classes = `${styles.glimmerField} ${
    short ? styles.glimmerFieldShort : ""
  }`;
  return (
    <div className={classes}>
      <Glimmer size={"base"} />
    </div>
  );
};
