import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "billing.checkout.marketingSuiteAddon.title",
    defaultMessage: "Marketing Suite Add-On",
    description: "Title of Marketing Suite Addon component",
  },
  description: {
    id: "billing.checkout.marketingSuiteAddon.description",
    defaultMessage: "Includes all tools and 1:1 product coach training",
    description: "Description of Marketing Suite Addon component",
  },
  price: {
    id: "billing.checkout.marketingSuiteAddon.value",
    defaultMessage: `\${price}`,
    description: "Value of Marketing Suite Addon component",
  },
  monthly: {
    id: "billing.checkout.marketingSuiteAddon.valuePerMonth",
    defaultMessage: "/mo",
    description: "Value per month of Marketing Suite Addon component",
  },
  removeLabel: {
    id: "billing.checkout.marketingSuiteAddon.removeLabel",
    defaultMessage: "Remove Marketing Suite",
    description: "Remove label of button",
  },
  addLabel: {
    id: "billing.checkout.marketingSuiteAddon.addLabel",
    defaultMessage: "Add Marketing Suite",
    description: "Add label of button",
  },
  campaignsAddonTitle: {
    id: "billing.checkout.marketingSuiteAddon.campaignsAddonTitle",
    defaultMessage: "Campaigns",
    description: "Title of Campaigns Addon component",
  },
  campaignsAddonDescription: {
    id: "billing.checkout.marketingSuiteAddon.campaignsAddonDescription",
    defaultMessage:
      "Drive repeat business with easy, effective email campaigns",
    description: "Description of Campaigns Addon component",
  },
  reviewsAddonTitle: {
    id: "billing.checkout.marketingSuiteAddon.reviewsAddonTitle",
    defaultMessage: "Reviews",
    description: "Title of Reviews Addon component",
  },
  reviewsAddonDescription: {
    id: "billing.checkout.marketingSuiteAddon.reviewsAddonDescription",
    defaultMessage:
      "Double your 5-star reviews to build trust and stand out in a crowd",
    description: "Description of Reviews Addon component",
  },
  referralsAddonTitle: {
    id: "billing.checkout.marketingSuiteAddon.referralsAddonTitle",
    defaultMessage: "Referrals",
    description: "Title of Referrals Addon component",
  },
  referralsAddonDescription: {
    id: "billing.checkout.marketingSuiteAddon.referralsAddonDescription",
    defaultMessage:
      "Let your customers bring business to you with automated referrals",
    description: "Description of Referrals Addon component",
  },
  testimonialQuote: {
    id: "billing.checkout.marketingSuiteAddon.testimonialQuote",
    defaultMessage: `"Thanks to Jobber, we've more than doubled our reviews and got about $25,000 worth of jobs after sending our first campaign."`,
    description: "Testimonial quote",
    author: "Stephen Jobe",
    company: "Jobe & Sons Plumbing",
  },
  businessData: {
    id: "billing.checkout.marketingSuiteAddon.businessData",
    defaultMessage:
      "Businesses using Marketing Suite have seen their yearly revenue increase an average of 37%",
    description: "Business data",
  },
});
