import { defineMessages } from "react-intl";

export const messages = defineMessages({
  downgradePromoModalTitle: {
    id: "downgradePage.downgradePromoModal.modalTitle",
    defaultMessage: "Special final offer",
    description: "Downgrade promo modal title",
  },
  downgradePromoModalPrimaryCtaLabel: {
    id: "downgradePage.downgradePromoModal.primaryCtaLabel",
    defaultMessage: "Accept Offer",
    description: "Primary CTA Label for accepting offer",
  },
  downgradePromoModalSecondaryCtaLabel: {
    id: "downgradePage.downgradePromoModal.secondaryCtaLabel",
    defaultMessage: "No Thanks",
    description: "Secondary CTA Label for not accepting offer",
  },
  downgradePromoModalDescription: {
    id: "downgradePage.downgradePromoModal.modalDescription",
    defaultMessage:
      "Keep your subscription at a lower cost to explore all of what {planName} has to offer.",
    description: "Downgrade promo modal description",
  },
  downgradePromoModalOfferMonthlyBillingAmount: {
    id: "downgradePage.downgradePromoModal.monthlyOffer",
    defaultMessage: "Save {saveAmount}",
    description: "Downgrade promo modal offer for monthly billing",
  },
  downgradePromoModalMonthlyOfferPercentage: {
    id: "downgradePage.downgradePromoModal.monthlyOfferPercentage",
    defaultMessage: "40%",
    description: "Downgrade promo modal monthly offer percentage",
  },
  downgradePromoModalOfferMonthlyDuration: {
    id: "downgradePage.downgradePromoModal.monthlyDuration",
    defaultMessage: "for your next 3 months",
    description: "Downgrade promo modal offer duration in months",
  },
  downgradePromoModalOfferAnnualBillingAmount: {
    id: "downgradePage.downgradePromoModal.annualOffer",
    defaultMessage: "Save {saveAmount}",
    description: "Downgrade promo modal offer for annual billing",
  },
  downgradePromoModalAnnualOfferPercentage: {
    id: "downgradePage.downgradePromoModal.annualOfferPercentage",
    defaultMessage: "10%",
    description: "Downgrade promo modal annual offer percentage",
  },
  downgradePromoModalOfferAnnualDuration: {
    id: "downgradePage.downgradePromoModal.annualDuration",
    defaultMessage: "for one year with annual billing",
    description: "Downgrade promo modal offer duration in years",
  },
  downgradePromoModalOfferEnrolmentIneligibleError: {
    id: "downgradePage.downgradePromoModal.enrolmentIneligibleError",

    defaultMessage:
      "Sorry, your account is ineligible for this offer. Please {contact} for support.",
    description:
      "Error message when promotion enrolment fails due to ineligibility",
  },
  downgradePromoModalOfferEnrolmentFailedError: {
    id: "downgradePage.downgradePromoModal.enrolmentFailedError",
    defaultMessage:
      "We were unable to redeem this offer to your account. Please try again. If this issue persists, {contact} for support.",
    description: "Error message when promotion enrolment fails",
  },
  downgradePromoModalOfferEnrolmentErrorContactUs: {
    id: "downgradePage.downgradePromoModal.enrolmentErrorContactUs",
    defaultMessage: "contact us",
    description: "Contact message when promotion enrolment fails",
  },
  downgradePromoModalOfferEnrolmentErrorIntercomPrompt: {
    id: "downgradePage.downgradePromoModal.enrolmentErrorIntercomPrompt",
    defaultMessage: "I am having errors redeeming the special final offer.",
    description: "Intercom prompt message when promotion enrolment fails",
  },
  downgradePromoModalOfferEnrolmentSuccessRedirectPath: {
    id: "downgradePage.downgradePromoModal.enrolmentSuccessRedirectPath",
    defaultMessage:
      "/accounts/billing_info/account_and_billing?downgrade_promo_accepted=true",
    description: "Redirect path when promotion enrolment succeeds",
  },
});
