import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { messages } from "jobber/campaigns/components/ErrorBanner/messages";

// Getters
export function getGenericErrorMessage() {
  return screen.getByText(messages.genericError.defaultMessage);
}

export function getRefreshButtonText() {
  return screen.getByText(messages.refreshButton.defaultMessage);
}

// Query
export function queryGenericErrorMessage() {
  return screen.queryByText(messages.genericError.defaultMessage);
}

// Actions

export function iClickTheRefreshButton() {
  return userEvent.click(getRefreshButtonText());
}
