import React from "react";
import { InputGroup } from "@jobber/components/InputGroup";
import { InputTime } from "@jobber/components/InputTime";
import styles from "./styles.module.css";

interface TimeInputsProps {
  disabled: boolean;
  startTime?: Date;
  onStartTimeChange: (updatedStartTime: Date | undefined) => void;
  endTime?: Date;
  onEndTimeChange: (updatedEndTime: Date | undefined) => void;
}

export const SchedulingTimeInputs = ({
  disabled,
  endTime,
  onEndTimeChange,
  onStartTimeChange,
  startTime,
}: TimeInputsProps) => {
  return (
    <div className={styles.timesContainer}>
      <InputGroup flowDirection="horizontal">
        <div>
          <InputTime
            disabled={disabled}
            onChange={onStartTimeChange}
            placeholder="Start time"
            value={startTime}
          />
        </div>
        <div>
          <InputTime
            disabled={disabled}
            onChange={onEndTimeChange}
            placeholder="End time"
            value={endTime}
          />
        </div>
      </InputGroup>
    </div>
  );
};
