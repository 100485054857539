import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import type { MessageFormat } from "../../types";

const { formatMessage } = createIntl();

export const companyImage = () => screen.findByAltText("Company image");
export const companyImageAlt = async () => {
  const image = await companyImage();
  return image.getAttribute("alt");
};
export const companyImageSrc = async () => {
  const image = await companyImage();
  return image.getAttribute("src");
};

export const businessPageHeading = (text: MessageFormat) =>
  screen.findByText(formatMessage(text));
export const businessPageCopy = (text: string) => screen.findByText(text);
