import { fireEvent, screen } from "@testing-library/react";
import { breadCrumbMessages } from "jobber/campaigns/components/Breadcrumbs";
import { messages } from "./messages";

// Selectors
export function getBreadcrumbTitle() {
  return screen.getByText(breadCrumbMessages.recipientsLabel.defaultMessage);
}

export function getErrorSavingClientSegmentMessage() {
  return screen.getByText(messages.errorSavingClientSegment.defaultMessage);
}

export function queryErrorSavingClientSegmentMessage() {
  return screen.queryByText(messages.errorSavingClientSegment.defaultMessage);
}

// Actions
export function iClickOnCancelButton() {
  fireEvent.click(screen.getByText("Cancel"));
}

export function iClickOnReviewCampaignButton() {
  fireEvent.click(screen.getByText("Review Campaign"));
}
