import { secondsToHours, secondsToMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export function unroundedSecondsToHours(seconds: number) {
  const secondsInHour = 3600;
  return seconds / secondsInHour;
}

export function unroundedMinutesToHours(minutes: number) {
  const minutesInHour = 60;
  return minutes / minutesInHour;
}

export function unroundedSecondsToMinutes(seconds: number) {
  const secondsInMinute = 60;
  return seconds / secondsInMinute;
}

export function createDateWithoutSeconds() {
  const newDate = new Date();
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
}

// Formats seconds in H:mm format
export function formatSecondsToHours(seconds: number) {
  const hours = secondsToHours(seconds);
  const minutes = secondsToMinutes(seconds) % 60;

  const zeroPad = (num: number) => String(num).padStart(2, "0");
  // FormattedHours
  return `${hours}:${zeroPad(minutes)}`;
}

export function secondsToFlooredHourAndMinutes(seconds: number) {
  const hours = Math.floor(secondsToHours(seconds));
  const minutes = Math.floor(secondsToMinutes(seconds) % 60);

  return { hours, minutes };
}

export function hoursAndMinutesToSeconds(hours: number, minutes: number) {
  const secondsInHour = 3600;
  const secondsInMinute = 60;
  return hours * secondsInHour + minutes * secondsInMinute;
}

export function getAccountZonedTime(date: Date, tz: string) {
  return utcToZonedTime(date, tz);
}

export function getUTCFromAccountZonedTime(date: Date | undefined, tz: string) {
  return date ? zonedTimeToUtc(date, tz) : undefined;
}

export function changeTimeOnDate(date: Date, time: Date) {
  date.setHours(time.getHours());
  date.setMinutes(time.getMinutes());
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function isTimeBefore(date1: Date, date2: Date) {
  const timeDifference = date1.getHours() < date2.getHours();
  const minutesDifference =
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() <= date2.getMinutes();

  return timeDifference || minutesDifference;
}
