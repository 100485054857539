import React, { type MutableRefObject, useRef, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { useMutation } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { useOnMount } from "@jobber/hooks/useOnMount";
import { Content } from "@jobber/components";
import type { ActivateConnectToGrowTrialMutation } from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ACTIVATE_GROW_TRIAL } from "jobber/connectToGrowTrial/ConnectToGrowTrial.graphql";
import { FeatureCarouselPlanEnum } from "~/components/Expansion/FeatureCarousel/featureCarouselPlans";
import { UpgradeWaveFeatureCarousel } from "~/components/Expansion/UpgradeWaveFeatureCarousel/UpgradeWaveFeatureCarousel";
import { messages } from "./messages";

export enum ConnectToGrowTrialBeginSource {
  AUTOTRIGGERED = "auto-triggered",
  DEEPLINKED = "deeplinked",
  UPGRADE_PAGE = "upgrade-page",
}

export interface ConnectToGrowTrialBeginModalProps {
  source: ConnectToGrowTrialBeginSource;
  showModal: boolean;
  dismissModal: () => void;
}

function ErrorBanner() {
  const { formatMessage: t } = useIntl();
  return (
    <Content spacing={"large"}>
      <Banner type="error" icon="alert" dismissible={false}>
        {t(messages.modalDefaultError)}
      </Banner>
    </Content>
  );
}

export function ConnectToGrowTrialBeginModal({
  source,
  showModal,
  dismissModal,
}: ConnectToGrowTrialBeginModalProps) {
  const { formatMessage: t } = useIntl();
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const ctaName = "connect_to_grow_trial_pre_trial_home_modal";
  const [activateGrowTrial, { loading }] =
    useMutation<ActivateConnectToGrowTrialMutation>(ACTIVATE_GROW_TRIAL, {
      variables: {
        input: {
          deeplinked: source === ConnectToGrowTrialBeginSource.DEEPLINKED,
        },
      },
    });
  const [error, setError] = useState(false);

  useOnMount(() => {
    if (showModal) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: ctaName,
        source,
      });
    }
  });

  return (
    <APIProvider>
      <CallToAction ref={ctaRef} ctaName={ctaName}>
        <Modal
          open={showModal}
          size="large"
          dismissible={true}
          title=""
          onRequestClose={handleModalDismissal}
          primaryAction={{
            label: t(messages.primaryButtonLabel),
            onClick: handleStartFreeTrial,
            loading: loading,
          }}
          secondaryAction={{
            label: t(messages.secondaryButtonLabel),
            onClick: handleModalDismissal,
          }}
        >
          {error && <ErrorBanner />}
          <UpgradeWaveFeatureCarousel plan={FeatureCarouselPlanEnum.Grow} />
        </Modal>
      </CallToAction>
    </APIProvider>
  );

  function handleModalDismissal() {
    dismissCTA(ctaRef, { source })();
    dismissModal();
  }

  async function handleStartFreeTrial() {
    await activateGrowTrial({
      onCompleted({ activateConnectToGrowTrial: { userErrors } }) {
        if (!userErrors.length) {
          convertCTA(ctaRef, { source })();
          window.location.href = "/home";
          dismissModal();
        } else {
          setError(true);
        }
      },
      onError() {
        setError(true);
      },
    });
  }
}
