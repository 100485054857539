import React from "react";
import { useIntl } from "react-intl";
import {
  Content,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
} from "@jobber/components";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import { messages } from "../messages";
import styles from "../Receivables.module.css";
import { EventTypes, trackEventWithAmplitude } from "../../../utils";

export interface PercentageOfUpcomingJobsOnAutopayProps {
  percentageOfUpcomingJobsOnAutopay?: number;
}

export const PercentageOfUpcomingJobsOnAutopay = ({
  percentageOfUpcomingJobsOnAutopay,
}: PercentageOfUpcomingJobsOnAutopayProps) => {
  const { formatMessage } = useIntl();

  return (
    <Content type={"section"} spacing={"small"}>
      <Flex template={["shrink", "shrink"]} gap="smallest">
        <Text size="small">
          {formatMessage(messages.percentageOfUpcomingJobsOnAutopayHeading)}
        </Text>
        <Tooltip
          preferredPlacement="bottom"
          message={formatMessage(
            messages.percentageOfUpcomingJobsOnAutopayToolTip,
          )}
        >
          <span
            onMouseEnter={() => {
              trackEventWithAmplitude({
                eventType: EventTypes.Tooltip,
                eventSource: formatMessage(
                  messages.percentageOfUpcomingJobsOnAutopayToolTip,
                ),
              });
            }}
          >
            <Icon name={"help"} size={"small"} />
          </span>
        </Tooltip>
      </Flex>
      <Heading level={2}>
        {percentageOfUpcomingJobsOnAutopay != null
          ? formatMessage(messages.percentageOfUpcomingJobsOnAutopay, {
              percentageOfUpcomingJobsOnAutopay,
            })
          : "-"}
      </Heading>
      {percentageOfUpcomingJobsOnAutopay != null && (
        <span className={styles.buttonContainer}>
          <TextActionButton
            variation="subtle"
            label={formatMessage(
              messages.percentageOfUpcomingJobsOnAutopayUrlLabel,
            )}
            onClick={() => {
              trackEventWithAmplitude({
                eventType: EventTypes.Link,
                eventSource: formatMessage(
                  messages.percentageOfUpcomingJobsOnAutopayUrlLabel,
                ),
              });
            }}
            url="https://help.getjobber.com/hc/en-us/articles/360036931633-Automatic-Payments"
          />
        </span>
      )}
    </Content>
  );
};
