import React from "react";
import { render, screen, within } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { MemoryRouter } from "react-router-dom";
import { MockedProvider } from "@apollo/client/testing";
import {
  BREAKPOINT_SIZES,
  mockViewportWidth,
} from "@jobber/hooks/useBreakpoints";
import { IntlProvider } from "@translations/IntlProvider";
import { SetupGuide } from "./SetupGuide";
import { SetupGuideLoader } from "./SetupGuide.loader";
import {
  googleBusinessAccountDisconnectErrorMock,
  googleBusinessAccountDisconnectMock,
  googleBusinessAccountEditMock,
  googleProfilesSubscriptionMock,
  jobFollowUpsEditMock,
} from "./mocks";
import { marketingReviewsSubscriptionMock } from "../../mocks";

interface SetupGuideTestProps {
  accountSetup?: boolean;
  hasFeature?: boolean;
  isConnected?: boolean;
}

const { cleanup, setViewportWidth } = mockViewportWidth();

afterEach(() => {
  cleanup();
});

jest.mock("components/JobberOnline/jobberOnline");
jest.mock("~/utilities/API/APIProvider");

jest.mock("@jobber/components/ProgressBar", () => {
  return {
    ProgressBar: jest.fn(({ currentStep }) => {
      return <div data-testid="testProgressBar">{currentStep}</div>;
    }),
  };
});

Object.defineProperty(window, "location", { value: jest.fn() });

interface RenderPageProps {
  routePath?: string;
  withDisconnectError?: boolean;
}

export function renderPage({
  routePath = "/reviews",
  withDisconnectError = false,
}: RenderPageProps = {}) {
  setViewportWidth(BREAKPOINT_SIZES.sm);
  render(
    <MockedProvider
      mocks={
        withDisconnectError
          ? [googleBusinessAccountDisconnectErrorMock()]
          : [
              googleBusinessAccountDisconnectMock(),
              googleProfilesSubscriptionMock(),
              googleBusinessAccountEditMock(),
              jobFollowUpsEditMock(),
            ]
      }
    >
      <MemoryRouter initialEntries={[routePath]}>
        <IntlProvider>
          <SetupGuide
            companyName="Test Company"
            routing={{ base: "/" }}
            isConnected={false}
          />
        </IntlProvider>
      </MemoryRouter>
    </MockedProvider>,
  );
}

export function renderLoader({
  accountSetup = false,
  hasFeature = true,
  isConnected = false,
}: SetupGuideTestProps = {}) {
  setViewportWidth(BREAKPOINT_SIZES.sm);
  render(
    <MockedProvider
      mocks={[
        googleProfilesSubscriptionMock(),
        marketingReviewsSubscriptionMock(),
        googleBusinessAccountDisconnectMock(),
        googleBusinessAccountEditMock(),
        jobFollowUpsEditMock(),
      ]}
    >
      <SetupGuideLoader
        accountSetup={accountSetup}
        routing={{ base: "/" }}
        companyName="Test Company"
        hasFeature={hasFeature}
        isConnected={isConnected}
        recurlyPublicKey="test-recurly-key"
        isMobileBilled={false}
        addonSetIdentifier={"reviews"}
        salesforceTrackingId={"test-salesforce-id"}
        requiresPaidPlan={false}
      />
    </MockedProvider>,
  );
}

export async function showsErrorBanner() {
  return within(
    await screen.findByText("Something went wrong. Please try again later."),
  );
}

export async function showsSetupGuide() {
  return within(screen.getByTestId("setup-guide"));
}

export async function showsReviewsPage() {
  return within(screen.getAllByText("Reviews")[0]);
}

export async function showsPurchasePage() {
  return within(screen.getByText("Get more 5-star reviews with Reviews"));
}

export async function showsProfilePage() {
  return within(await screen.findByText("The Dankery"));
}

export async function showsPersonalizedPage() {
  return within(screen.getByText("Automatic messages"));
}

export async function showsConnectPage() {
  return within(screen.getByText("Connect your profile to start setup"));
}

export async function showCompleteSetupPage() {
  return within(screen.getByText("Managing reviews"));
}

export async function showConnectedPage() {
  return within(screen.getByText("Your profile is connected"));
}

export async function showsBuildingDashboardPage() {
  return within(screen.getByText("Building your dashboard"));
}

export function doesNotShowBackButton() {
  return screen.queryByLabelText("Go back") === null;
}

export async function showsBackButton() {
  return within(screen.getByLabelText("Go back"));
}

export function showsProgressBarAtStep({ step }: { step: number }) {
  return screen.getByTestId("testProgressBar").textContent === step.toString();
}

export function navigatesTo(path: string) {
  return window.location.href.includes(path);
}

export function clickConnect() {
  userEvent.click(screen.getByText("Connect"));
}

export function clickDisconnect() {
  userEvent.click(screen.getByText("Disconnect"));
}

export function clickProfile() {
  userEvent.click(screen.getByText("The Dankery"));
}

export function clickContinue() {
  userEvent.click(screen.getByText("Continue"));
}

export function clickCompleteSetup() {
  userEvent.click(screen.getByText("Complete Setup"));
}

export function goBack() {
  userEvent.click(screen.getByLabelText("Go back"));
}
