import React from "react";
import { Box, Content, Divider, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import { formatCurrency } from "utilities/formatCurrency";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type {
  AchUsage,
  JobberPaymentsAchLimits,
} from "~/utilities/API/graphql";
import { ACHUsageProgressBar } from "./ACHUsageProgressBar";
import { messages } from "./messages";
import styles from "./styles.module.css";

export function ACHUsage({
  usage,
  achLimits,
}: {
  usage: AchUsage;
  achLimits: JobberPaymentsAchLimits;
}) {
  const { formatMessage } = useIntl();
  const { currencySymbol } = useAccount();

  const singleTransactionLimit = (achLimits.singleTransactionLimit || 0) / 100;
  const twentyFourHourTransactionLimit =
    (achLimits.twentyFourHourTransactionLimit || 0) / 100;
  const monthTransactionLimit = (achLimits.monthTransactionLimit || 0) / 100;

  return (
    <Content>
      <div className={styles.achUsageSection}>
        {singleTransactionLimit && (
          <div
            className={styles.progressBarRow}
            data-testid="singleTransactionSection"
          >
            <Text>
              {formatMessage(messages.singleTransactionLimit, {
                amount: formatCurrency(
                  singleTransactionLimit,
                  currencySymbol,
                  0,
                ),
              })}
            </Text>
          </div>
        )}
        <Divider />
        {twentyFourHourTransactionLimit && (
          <div className={styles.progressBarRow}>
            <Box
              width={"100%"}
              height={"grow"}
              data-testid="twentyFourHourSection"
            >
              <Text>
                {formatMessage(messages.twentyFourHourLimit, {
                  amount: formatCurrency(
                    twentyFourHourTransactionLimit,
                    currencySymbol,
                    0,
                  ),
                })}
              </Text>
            </Box>
            <ACHUsageProgressBar
              currentUsage={(usage.last24Hours ?? 0) / 100}
              totalLimit={twentyFourHourTransactionLimit}
            />
          </div>
        )}
        <Divider />
        {monthTransactionLimit && (
          <div className={styles.progressBarRow}>
            <Box width={"100%"} height={"grow"} data-testid="thirtyDaySection">
              <Text>
                {formatMessage(messages.thirtyDayLimit, {
                  amount: formatCurrency(
                    monthTransactionLimit,
                    currencySymbol,
                    0,
                  ),
                })}
              </Text>
            </Box>
            <ACHUsageProgressBar
              currentUsage={(usage.last30Days ?? 0) / 100}
              totalLimit={monthTransactionLimit}
            />
          </div>
        )}
        <Divider />
      </div>
    </Content>
  );
}
