import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages } from "./messages";

const { formatMessage } = createIntl();

export function reminderText(
  planDowngradingFrom: string,
  planDowngradingTo: string,
) {
  return screen.getByText(
    formatMessage(messages.reminder, {
      planDowngradingFrom,
      planDowngradingTo,
    }),
  );
}

export function featureLossText(
  planDowngradingFrom: string,
  growToCore: boolean,
  growToCorePlanName: string,
) {
  const listItems = screen.getAllByRole("listitem");

  return (
    listItems.find(item => {
      const expectedText = formatMessage(messages.featureLossDisclaimer, {
        planDowngradingFrom: formatMessage(messages.planName, {
          planName: planDowngradingFrom,
        }),
        growToCore: growToCore,
        growToCorePlanName: growToCorePlanName,
      });
      return item.textContent?.includes(expectedText);
    }) || null
  );
}

export function twoWaySmsDisclaimerText() {
  const listItems = screen.getAllByRole("listitem");
  return (
    listItems.find(item => {
      const expectedText = formatMessage(messages.twoWaySmsDisclaimer, {
        twoWaySmsLink: formatMessage(messages.twoWaySmsLink),
      });
      return item.textContent?.includes(expectedText);
    }) || null
  );
}

export function qboDisclaimerText() {
  const listItems = screen.getAllByRole("listitem");
  return (
    listItems.find(item => {
      const expectedText = formatMessage(messages.qboDisclaimer, {
        qboLink: formatMessage(messages.qboLink),
      });
      return item.textContent?.includes(expectedText);
    }) || null
  );
}

export function specialtyPricingDisclaimerText() {
  return screen.queryByText(formatMessage(messages.specialtyPricingDisclaimer));
}

export function haveQuestionsText() {
  return screen.getByText(
    formatMessage(messages.haveQuestions, {
      chatWithUsLink: formatMessage(messages.chatWithUsButton),
    }),
  );
}

export function chatWithUsButton() {
  return screen.getByText(formatMessage(messages.chatWithUsButton));
}

export function iClickChatWithUsLink() {
  userEvent.click(chatWithUsButton());
}
