import React from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { messages } from "./messages";

export interface ClientSegmentNavigationModalArgs {
  showModal: boolean;
  toggleModal: (toggle: boolean) => void;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
}

export function ClientSegmentNavigationModal({
  showModal,
  toggleModal,
  onPrimaryAction,
  onSecondaryAction,
}: ClientSegmentNavigationModalArgs) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      title={formatMessage(messages.clientSegmentNavigationModalTitle)}
      open={showModal}
      onRequestClose={() => toggleModal(false)}
      primaryAction={{
        label: formatMessage(
          messages.clientSegmentNavigationModalPrimaryAction,
        ),
        variation: "work",
        onClick: onPrimaryAction,
      }}
      secondaryAction={{
        label: formatMessage(
          messages.clientSegmentNavigationModalSecondaryAction,
        ),
        variation: "work",
        type: "secondary",
        onClick: onSecondaryAction,
      }}
      tertiaryAction={{
        label: formatMessage(
          messages.clientSegmentNavigationModalTertiaryAction,
        ),
        variation: "learning",
        onClick: () => toggleModal(false),
      }}
    >
      <Content>
        <Text>{formatMessage(messages.clientSegmentNavigationModalText)}</Text>
      </Content>
    </Modal>
  );
}
