import { gql } from "@apollo/client";

export const CLIENT_REFERRAL_FEATURE_QUERY = gql`
  query ClientReferralsFeature {
    feature(key: "marketing_client_referrals") {
      enabled
      available
    }
  }
`;

export const CLIENT_QUERY = gql`
  query client($clientId: EncodedId!) {
    client(id: $clientId) {
      id
      name
      secondaryName
    }
  }
`;

export const LEAD_SOURCES_QUERY = gql`
  query leadSources {
    leadSources {
      nodes {
        id
        isCustom
        label
      }
    }
  }
`;

export const CLIENT_LEAD_SOURCE_UPDATE_MUTATION = gql`
  mutation clientLeadSourceEditMutation(
    $clientId: EncodedId!
    $input: ClientEditInput!
  ) {
    clientEdit(clientId: $clientId, input: $input) {
      client {
        id
        sourceAttribution {
          sourceText
        }
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const CREATE_CUSTOM_LEAD_SOURCE_MUTATION = gql`
  mutation customLeadSourceCreate($input: CustomLeadSourceInput!) {
    customLeadSourceCreate(input: $input) {
      customLeadSource {
        id
        label
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const DELETE_CUSTOM_LEAD_SOURCE_MUTATION = gql`
  mutation customLeadSourceDelete($id: EncodedId!) {
    customLeadSourceDelete(id: $id) {
      deletedCustomLeadSource {
        id
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const EDIT_CUSTOM_LEAD_SOURCE_MUTATION = gql`
  mutation customLeadSourceEdit(
    $id: EncodedId!
    $input: CustomLeadSourceInput!
  ) {
    customLeadSourceEdit(id: $id, input: $input) {
      customLeadSource {
        id
        label
      }
      userErrors {
        message
        path
      }
    }
  }
`;
