import React from "react";
import type { File } from "~/utilities/API/graphql";
import { EditContent } from "jobber/campaigns/views/CampaignsContentPage/components/EditContent";
import { EmailPreview } from "jobber/campaigns/components/EmailPreview";
import type { CampaignContentRefs } from "jobber/campaigns/views/CampaignsContentPage/types";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import styles from "./CampaignContent.module.css";

interface CampaignContentProps {
  loading: boolean;
  refs: CampaignContentRefs;
  fromEmail: string;
  textRewriteLoading: boolean;
  setTextRewriteLoading: (loading: boolean) => void;
}

export function CampaignContent({
  loading,
  refs,
  fromEmail,
  setTextRewriteLoading,
  textRewriteLoading,
}: CampaignContentProps): JSX.Element {
  const { campaignContent } = useCampaignWizardContext();

  const handleSubject = (newSubject: string) => {
    campaignContent.setSubject(newSubject, true);
  };

  const handleHeader = (newHeader: string) => {
    campaignContent.setHeader(newHeader, true);
  };

  const handleHeaderImage = (newHeaderImage?: File) => {
    campaignContent.setHeaderImage(newHeaderImage, true);
  };

  const handleShowLogo = (showLogo: boolean) => {
    campaignContent.setShowLogo(showLogo, true);
  };

  return (
    <div className={styles.contentPageContainer}>
      <div
        className={styles.editContainer}
        data-testid={"CampaignContentEditor"}
      >
        <EditContent
          subject={campaignContent.subject}
          header={campaignContent.header}
          body={campaignContent.body}
          showLogo={campaignContent.showLogo}
          setSubject={handleSubject}
          setHeader={handleHeader}
          setBody={campaignContent.setBody}
          setShowLogo={handleShowLogo}
          templateType={campaignContent.templateType}
          variables={campaignContent.variables}
          loading={loading}
          textRewriteLoading={textRewriteLoading}
          setTextRewriteLoading={setTextRewriteLoading}
          headerImage={campaignContent.headerImage}
          setHeaderImage={handleHeaderImage}
          refs={refs}
        />
      </div>
      <div
        className={styles.previewContainer}
        data-testid={"CampaignEmailPreview"}
      >
        <EmailPreview
          headerImageUrl={campaignContent.headerImage?.url}
          subject={campaignContent.subject}
          fromEmail={fromEmail}
          header={campaignContent.header}
          body={campaignContent.body}
          showLogo={campaignContent.showLogo}
          buttonColor={campaignContent.buttonColor}
          buttonText={campaignContent.buttonText}
          ctaButtonEnabled={campaignContent.ctaButtonEnabled}
          loading={textRewriteLoading || loading}
          variables={campaignContent.variables}
          referralLink={campaignContent.referralLink}
        />
      </div>
    </div>
  );
}
