import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { IntlProvider } from "@translations/IntlProvider";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import { APIProvider } from "~/utilities/API/APIProvider";
import type {
  BillingCycleName,
  CheckoutAccountAddonsInfoQuery,
  CheckoutAccountAddonsInfoQueryVariables,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { withSplitClient } from "utilities/split";
import { filterAddons } from "jobber/billing/features/Checkout/Checkout.loader";
import { CheckoutExperiment } from "./CheckoutExperiment";
import { CHECKOUT_ACCOUNT_ADDONS_INFO } from "../../Checkout.graphql";

interface CheckoutLoaderProps {
  recurlyPublicKey: string;
  planSetIdentifier: string;
  billingCycleName: BillingCycleName;
  preselectedAddons?: string[];
  country: string;
}

export const CheckoutLoader = withSplitClient(WrappedCheckoutLoader);

function WrappedCheckoutLoader(checkoutLoaderProps: CheckoutLoaderProps) {
  return (
    <IntlProvider>
      <APIProvider>
        <InternalCheckoutLoader {...checkoutLoaderProps} />
      </APIProvider>
    </IntlProvider>
  );
}

function InternalCheckoutLoader({
  recurlyPublicKey,
  planSetIdentifier,
  billingCycleName,
  preselectedAddons,
  country,
}: CheckoutLoaderProps) {
  const [subscriptionAddons, setSubscriptionAddons] = useState<
    SubscriptionAddonPreview[]
  >([]);

  const { loading: loadingAddons } = useQuery<
    CheckoutAccountAddonsInfoQuery,
    CheckoutAccountAddonsInfoQueryVariables
  >(CHECKOUT_ACCOUNT_ADDONS_INFO, {
    variables: { planSetIdentifier },
    onCompleted: data => setSubscriptionAddons(filterAddons(data)),
    onError: error => {
      Rollbar.EXECUTE(
        `Unable to fetch add-ons for plan set identifier: ${planSetIdentifier}`,
        new Error(`CheckoutLoader: ${error.message}`),
      );
    },
  });

  return (
    <CheckoutExperiment
      recurlyPublicKey={recurlyPublicKey}
      planSetIdentifier={planSetIdentifier}
      billingCycleName={billingCycleName}
      subscriptionAddons={subscriptionAddons}
      loadingAddons={loadingAddons}
      preselectedAddons={preselectedAddons}
      country={country}
    />
  );
}
