import { defineMessages } from "react-intl";

export const messages = defineMessages({
  baseCriteriaSubheader: {
    id: "campaigns.baseCriteria.subheader.",
    defaultMessage: "Client criteria",
    description: "Subheader for the segment criteria",
  },
  intervalInputWholeNumberError: {
    id: "campaigns.baseCriteria.intervalInput.wholeNumberError.old",
    defaultMessage: "Enter a whole number",
    description:
      "Inline error shown when the input is not populated, or there is a decimal number",
  },
  intervalInputBetweenMinAndMaxError: {
    id: "campaigns.baseCriteria.intervalInput.betweenMinAndMax.old",
    defaultMessage: "Enter a number between {min} and {max}",
    description:
      "Inline error shown when the input is not populated, or there is a decimal number",
  },
});
