import type { MutableRefObject } from "react";
import React, { useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Page } from "@jobber/components/Page";
import { Spinner } from "@jobber/components/Spinner";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Banner } from "@jobber/components";
import { useIntl } from "react-intl";
import { useOwnershipData } from "jobber/features/Settings/views/CommsSettings/hooks/useOwnershipData";
import type { CommsSettingsPageDataQuery } from "~/utilities/API/graphql";
import { APIProvider } from "~/utilities/API/APIProvider";
import { jobberOnline } from "components/JobberOnline/jobberOnline";
import {
  CallToAction,
  type CallToActionRef,
  dismissCTA,
  trackAnalytics,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Banner as CTABanner } from "~/jobber/CTABanner/Banner";
import { BannerSlot } from "~/jobber/CTABanner/BannerSlot";
import { BannerDismiss } from "~/jobber/CTABanner/BannerDismiss";
import { IntlProvider } from "@translations/IntlProvider";
import smsMessagingTrialBanner from "@images/smsUpgradeCTAImage.png";
import { COMMS_SETTINGS_PAGE_QUERY } from "./CommsSettings.graphql";
import { CommsSettings } from "./CommsSettings";
import {
  COMMS_SETTINGS_ERROR_MESSAGE,
  EMAILS_AND_TEXT_MESSAGES_HEADER,
  EMAILS_HEADER,
} from "./constants";
import { messages } from "./messages";

export function CommsSettingsWrapper({
  upgradePath,
}: {
  upgradePath?: string;
}) {
  return (
    <APIProvider>
      <IntlProvider>
        <CommsSettingsLoader upgradePath={upgradePath} />
      </IntlProvider>
    </APIProvider>
  );
}

function CommsSettingsLoader({ upgradePath }: { upgradePath?: string }) {
  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery<CommsSettingsPageDataQuery>(COMMS_SETTINGS_PAGE_QUERY);

  const { formatMessage } = useIntl();

  const {
    fetchOwnerships,
    ownerships,
    loading: isGlobalOwnershipsLoading,
    error,
  } = useOwnershipData();

  useEffect(() => {
    fetchOwnerships();
  }, []);

  const smsAvailable = jobberOnline.features.smsMessaging.available;
  const header = jobberOnline.features.smsMessaging.available
    ? EMAILS_AND_TEXT_MESSAGES_HEADER
    : EMAILS_HEADER;
  const ctaRef = useRef<CallToActionRef>(
    null,
  ) as MutableRefObject<CallToActionRef>;

  const dismissBanner = () => {
    dismissCTA(ctaRef)();
  };
  const ctaClicked = () => {
    trackAnalytics("CTA Clicked", "sms_messaging_trial_cta");
  };

  return (
    <Page title={header}>
      {(queryLoading || isGlobalOwnershipsLoading) && <Spinner />}
      {!queryLoading && !isGlobalOwnershipsLoading && (
        <>
          <Content spacing="large">
            <Text size="large">
              Customize emails {smsAvailable && "and text messages "} sent to
              your clients.
            </Text>
          </Content>
          <CallToAction ctaName="sms_messaging_trial_cta" ref={ctaRef}>
            <CTABanner rowBreakpoint="large">
              <BannerSlot variant="image" padded>
                <img
                  src={smsMessagingTrialBanner}
                  alt={formatMessage(messages.ctaBannerImageAlt)}
                  style={{ objectFit: "contain" }}
                />
              </BannerSlot>
              <BannerSlot.Content>
                <Content>
                  <Heading level={4}>
                    {formatMessage(messages.ctaBannerHeader)}
                  </Heading>
                  <Text>{formatMessage(messages.ctaBannerBody)}</Text>
                  <Button
                    variation="learning"
                    type="secondary"
                    label={formatMessage(messages.ctaBannerButtonLabel)}
                    onClick={ctaClicked}
                    url={upgradePath}
                  />
                </Content>
              </BannerSlot.Content>
              <BannerDismiss onDismiss={dismissBanner} />
            </CTABanner>
          </CallToAction>
          {queryError && (
            <Banner type={"error"}>{COMMS_SETTINGS_ERROR_MESSAGE}</Banner>
          )}
          <CommsSettings
            data={data}
            globalOwnershipsData={{
              ownerships: ownerships || [],
              error: error,
            }}
          />
        </>
      )}
    </Page>
  );
}
