import { useCallback } from "react";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { Intercom } from "utilities/chat";
import { useCloseDrawer } from "jobber/jobberAssistant/hooks/useCloseDrawer";
import { useCreateIntercomConversation } from "jobber/jobberAssistant/hooks/useCreateIntercomConversation";
import { useHandOffContext } from "jobber/jobberAssistant/hooks/useHandOffContext";
import { IntercomConversationRoute } from "~/utilities/API/graphql";

export const useStartChatWithSuccess = (requestId: string) => {
  const closeChatSidebar = useCloseDrawer();
  const {
    createIntercomConversation,
    loading: createIntercomConversationLoading,
  } = useCreateIntercomConversation();
  const { handOffContext, handOffContextLoading } = useHandOffContext({
    requestId,
  });

  const loading = createIntercomConversationLoading || handOffContextLoading;

  const startChatWithSuccess = useCallback(
    async (
      message: string,
      route: IntercomConversationRoute = IntercomConversationRoute.SUPPORT,
    ) => {
      try {
        Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
          name: "chat_with_success_clicked",
          requestId,
          source: route,
        });

        const intercomConversationId = await createIntercomConversation(
          message,
          requestId,
          route,
        );
        if (intercomConversationId) {
          await handOffContext(intercomConversationId);
          callSupportChatTwice("showConversation", intercomConversationId);
          return intercomConversationId;
        } else {
          callSupportChatTwice("showNewMessage", message);
        }
      } catch {
        callSupportChatTwice("showNewMessage", message);
        // eslint-disable-next-line no-console
        console.error("Failed to create intercom conversation");
      } finally {
        closeChatSidebar?.();
      }
    },
    [closeChatSidebar, createIntercomConversation, handOffContext, requestId],
  );

  return {
    startChatWithSuccess,
    loading,
  };
};

function callSupportChatTwice(methodName: string, argument: string) {
  /* We intentionally call the method twice because there is a bug in the Intercom,
     JS API that sometimes causes the Intercom modal to open to the wrong screen.
     A bug for this has been filed with Intercom, but for now a workaround is to
     call the API twice.
  */
  Intercom.EXECUTE(methodName, argument);
  Intercom.EXECUTE(methodName, argument);
}
