import { createContext } from "react";

export interface CtaStatus {
  hasDismissed: boolean;
}

export type CtaName =
  | "instant_payouts_eligible_prompt"
  | "jobber_payments_tips_cta"
  | "jobber_google_reviews_25_milestone_cta"
  | "jobber_google_reviews_50_milestone_cta"
  | "jobber_google_reviews_100_milestone_cta"
  | "jobber_google_reviews_200_plus_milestone_cta"
  | "new_close_invoice_dialog_banner"
  | "wisetack_inline_signup_cta"
  | "sample_data_template_cta"
  | "new_home_welcome_card_dismissed"
  | "customize_branding_insight_card_cta"
  | "quotes_upgrade_markups_cta"
  | "first_invoice_creation_jpay_modal_cta"
  | "jobber_payments_invoices_prompt"
  | "jobber_payments_invoices_tips_prompt"
  | "jobber_payments_client_prompt"
  | "email_reply_to_cta"
  | "job_costing_profit_bar_help"
  | "job_cost_discovery_trial_card_cta"
  | "location_timers_cta"
  | "sample_quote_preview_cta"
  | "quote_create_upload_logo_cta"
  | "custom_fields_quote"
  | "show_campaigns_banner"
  | "sc_network_recommendation_card_cta"
  | "quote_template_limit";
export interface CallToActionContextType {
  ctas: Record<string, CtaStatus | undefined>;
}

export const CallToActionContext = createContext<CallToActionContextType>({
  ctas: {},
});
CallToActionContext.displayName = "CallToActionContext";
