import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Flex, Heading, Icon, Tooltip } from "@jobber/components";
import { useIntl } from "react-intl";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import {
  EventTypes,
  trackEventWithAmplitude,
} from "jobber/managed_accounts/FinancialInsightsSection/utils";
import { messages } from "./messages";
import styles from "./JobberMoneyPayouts.module.css";

interface JobberMoneyPayoutsProps {
  lastPayoutAmount?: number;
  availableBalance?: number;
}

export function JobberMoneyPayouts({
  lastPayoutAmount,
  availableBalance,
}: JobberMoneyPayoutsProps) {
  const { formatMessage } = useIntl();
  const currencyFormatter = useFormatCurrency();

  const [jobberMoneyAccountStatementReportPath] = useUrls(
    "jobberMoneyAccountStatementReportPath",
  );

  return (
    <>
      <Content spacing="smaller">
        <Text size="small" variation="subdued">
          {formatMessage(messages.jobberMoneyPayoutsDescription)}
        </Text>
      </Content>

      <Content spacing="smaller">
        <Flex template={["shrink", "shrink"]} gap="smallest">
          <Text size="small">
            {formatMessage(messages.jobberMoneyPayoutsLastPayout)}
          </Text>
          <Tooltip
            preferredPlacement="bottom"
            message={formatMessage(
              messages.jobberMoneyPayoutsLastPayoutTooltip,
            )}
          >
            <span
              onMouseEnter={() => {
                trackEventWithAmplitude({
                  eventType: EventTypes.Tooltip,
                  eventSource: "Jobber Money last payouts",
                });
              }}
            >
              <Icon name="help" size="small" />
            </span>
          </Tooltip>
        </Flex>
        <Heading level={2}>
          {lastPayoutAmount != null
            ? currencyFormatter(lastPayoutAmount, false)
            : "-"}
        </Heading>
      </Content>

      <Content spacing="smaller">
        <Text size="small">
          {formatMessage(messages.jobberMoneyPayoutsAvailableBalance)}
        </Text>
        <Heading level={2}>
          {availableBalance != null
            ? currencyFormatter(availableBalance, false)
            : "-"}
        </Heading>
        <span className={styles.buttonContainer}>
          <TextActionButton
            variation="subtle"
            label={formatMessage(messages.jobberMoneyPayoutsReportLink)}
            onClick={() => {
              trackEventWithAmplitude({
                eventType: EventTypes.Link,
                eventSource: "View Jobber Money account statement report",
              });
            }}
            url={jobberMoneyAccountStatementReportPath}
            size={"small"}
          />
        </span>
      </Content>
    </>
  );
}
