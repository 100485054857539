import { useMutation } from "@apollo/client";
import type {
  IntercomConversationCreateMutation,
  IntercomConversationCreateMutationVariables,
  IntercomConversationRoute,
} from "~/utilities/API/graphql";
import { INTERCOM_CONVERSATION_CREATE } from "./IntercomConversationCreate.graphql";

export const useCreateIntercomConversation = () => {
  const [createConversation, { loading }] = useMutation<
    IntercomConversationCreateMutation,
    IntercomConversationCreateMutationVariables
  >(INTERCOM_CONVERSATION_CREATE);

  const createIntercomConversation = async (
    message: string,
    jobberAssistantRequestId: string,
    conversationRouting: IntercomConversationRoute,
  ) => {
    const result = await createConversation({
      variables: {
        input: {
          userInitiatedMessage: message,
          uniqueIdentifier: jobberAssistantRequestId,
          conversationRouting: conversationRouting,
        },
      },
    });
    return result.data?.intercomConversationCreate?.conversationId;
  };

  return {
    createIntercomConversation,
    loading,
  };
};
