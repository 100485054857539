import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Glimmer } from "@jobber/components/Glimmer";
import { Flex } from "@jobber/components/Flex";
import { Heading } from "@jobber/components/Heading";
import { Segment } from "~/utilities/API/graphql";
import { messages } from "./messages";
import styles from "./SelectableSegment.module.css";

export interface SelectableSegmentProps {
  children?: React.ReactNode;
  heading: string;
  isSelected: boolean;
  segmentValue: string | number;
  name?: string;
  onChange: (newValue: Segment) => void;
  recommended?: boolean;
  loading?: boolean;
}
export function SelectableSegment({
  children,
  heading,
  isSelected,
  name,
  segmentValue,
  onChange,
  recommended,
  loading,
}: SelectableSegmentProps) {
  const { formatMessage } = useIntl();

  const getSegmentDescription = (segmentType: Segment) => {
    switch (segmentType) {
      case Segment.ALL_CLIENTS:
        return formatMessage(messages.allClientsSegmentCardDescription);
      case Segment.PAST_CLIENTS:
        return formatMessage(messages.pastClientsSegmentCardDescription);
      case Segment.UPCOMING_CLIENTS:
        return formatMessage(messages.upcomingClientsSegmentCardDescription);
      default:
        return "";
    }
  };

  const cardRecommended = () => {
    return (
      recommended && (
        <div className={styles.iconAndStatusContainer}>
          <Flex align="center" template={["grow", "shrink"]}>
            <Flex
              align="center"
              gap="smaller"
              template={["shrink", "shrink", "shrink"]}
            >
              <InlineLabel color="lightBlue">
                {formatMessage(messages.recommended)}
              </InlineLabel>
            </Flex>
          </Flex>
        </div>
      )
    );
  };

  const cardHeading = () => {
    return heading ? (
      <Heading level={4}>{heading}</Heading>
    ) : (
      <Glimmer.Text lines={3} />
    );
  };

  const cardDescription = () => {
    const description = getSegmentDescription(segmentValue as Segment);
    if (description) {
      return <Text>{description}</Text>;
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContent}>
        <Glimmer.Text />
      </div>
    );
  }

  return (
    <label data-testid={`${segmentValue} label`} className={styles.container}>
      <input
        id={segmentValue.toString()}
        type="radio"
        name={name}
        className={styles.radio}
        checked={isSelected}
        onChange={() => onChange(segmentValue as Segment)}
        value={segmentValue}
      />

      <div className={styles.segmentContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.innerContentContainer}>
            {cardRecommended()}
            <div className={styles.descriptionContainer}>
              {cardHeading()}
              {isSelected ? (
                <div className={styles.childContainer}>{children}</div>
              ) : (
                cardDescription()
              )}
            </div>
          </div>
        </div>
      </div>
    </label>
  );
}
