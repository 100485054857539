import { defineMessages } from "react-intl";

export const messages = defineMessages({
  pastClientsDescription1: {
    id: "campaigns.clientSegmentPage.pastClientsDescription1",
    defaultMessage: "Last job was completed more than",
    description: "Text fragment explaining the time interval inputs",
  },
  pastClientsDescription2: {
    id: "campaigns.clientSegmentPage.pastClientsDescription2",
    defaultMessage: "with no upcoming jobs scheduled",
    description: "Text fragment explaining the time interval inputs",
  },
  yearsAgoLabel: {
    id: "campaigns.clientSegmentPage.pastClientsYearsAgoLabel",
    defaultMessage: "Year(s) ago",
    description: "Label for the Years ago input",
  },
  monthsAgoLabel: {
    id: "campaigns.clientSegmentPage.monthsAgoLabel",
    defaultMessage: "Month(s) ago",
    description: "Label for the Months ago input",
  },
  weeksAgoLabel: {
    id: "campaigns.clientSegmentPage.weeksAgoLabel",
    defaultMessage: "Week(s) ago",
    description: "Label for the Weeks ago input",
  },
  daysAgoLabel: {
    id: "campaigns.clientSegmentPage.daysAgoLabel",
    defaultMessage: "Day(s) ago",
    description: "Label for the Days ago input",
  },
  clients: {
    id: "campaigns.clientSegmentPage.pastClientsSegment.clients",
    defaultMessage:
      "{totalCount} {totalCount, plural, one {client} other {clients}} ",
    description: "clients label",
  },
});
