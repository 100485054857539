import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Glimmer } from "@jobber/components/Glimmer";
import { debounce } from "lodash";
import type {
  IntervalUnits,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import type { ClientSegmentConditionalVariables } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { AdditionalCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/SelectClientSegmentPage/components/AdditionalCriteria/types";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import { SEGMENT_CLIENT_DEBOUNCE_TIME } from "jobber/campaigns/constants";
import { useIntervalValidation } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/hooks/useIntervalValidation/useIntervalValidation";
import { BaseCriteria } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/components/BaseCriteria/BaseCriteria";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import styles from "./UpcomingClientsSegmentEdit.module.css";
import { messages } from "./messages";
import { INTERVAL_UNIT_OPTIONS } from "./constants";

export interface UpcomingClientsSegmentEditProps {
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  pageSize?: number;
  openSideDrawer?: () => void;
}

const clientSegmentDataConditionalVariables: Partial<ClientSegmentConditionalVariables> =
  {
    isUpcomingClientDrawer: true,
  };

export function UpcomingClientsSegmentEdit({
  additionalCriteria,
  updateAdditionalCriteria,
  pageSize = 20,
  openSideDrawer,
}: UpcomingClientsSegmentEditProps): JSX.Element {
  const { formatMessage } = useIntl();

  const { validationMessage, setValidationMessage, validateInterval } =
    useIntervalValidation();

  const {
    campaignSegment: {
      isSegmentCriteriaFormValid,
      isDirty,
      upcomingClientsSegmentCriteria,
      setUpcomingClientsSegmentCriteria,
    },
  } = useCampaignWizardContext();

  const interval = upcomingClientsSegmentCriteria.interval;
  const unit = upcomingClientsSegmentCriteria.unit;
  const setInterval = (newInterval: number) =>
    setUpcomingClientsSegmentCriteria(
      { ...upcomingClientsSegmentCriteria, interval: newInterval },
      true,
    );
  const setUnit = (newUnit: IntervalUnits) =>
    setUpcomingClientsSegmentCriteria(
      { ...upcomingClientsSegmentCriteria, unit: newUnit },
      true,
    );

  const isFormValid = useCallback(() => {
    return (
      (!isDirty || isSegmentCriteriaFormValid) &&
      validateInterval(interval) === true
    );
  }, [interval, isSegmentCriteriaFormValid, validateInterval, isDirty]);

  const {
    refetch: fetchData,
    fullReload,
    clientSegmentsData,
  } = useClientSegmentData({
    conditionalVariables: clientSegmentDataConditionalVariables,
  });

  const debounceFetchData = useMemo(
    () =>
      debounce((newSegmentCriteria: UpcomingClientsCriteriaInput) => {
        const { clientTagCriteria, lineItemCriteria, jobTypeCriteria } =
          newSegmentCriteria;
        fetchData({
          first: pageSize,
          after: btoa("0"),
          upcomingClientInterval: newSegmentCriteria.interval,
          upcomingClientUnit: newSegmentCriteria.unit,
          upcomingClientLineItemCriteria: lineItemCriteria,
          upcomingClientTagCriteria: clientTagCriteria,
          upcomingClientJobTypeCriteria: jobTypeCriteria,
        });
      }, SEGMENT_CLIENT_DEBOUNCE_TIME),
    [fetchData, pageSize],
  );

  useEffect(() => {
    if (unit && interval && isFormValid()) {
      const { clientTagCriteria, jobTypeCriteria, lineItemCriteria } =
        parseAdditionalCriteria(additionalCriteria);
      debounceFetchData({
        unit,
        interval,
        clientTagCriteria,
        lineItemCriteria,
        jobTypeCriteria,
      });
    }
  }, [
    unit,
    interval,
    validationMessage,
    debounceFetchData,
    isFormValid,
    additionalCriteria,
  ]);

  return (
    <div className={styles.clientSegmentCriteriaContainer}>
      <Content spacing="large">
        <Content>
          <BaseCriteria
            segmentCriteria={{ unit, interval }}
            setInterval={setInterval}
            validationMessage={validationMessage}
            setValidationMessage={setValidationMessage}
            validateInterval={validateInterval}
            setUnit={setUnit}
            preFieldsDescription={formatMessage(
              messages.upcomingClientsDescription1,
            )}
            intervalUnitOptions={INTERVAL_UNIT_OPTIONS}
          />
          <AdditionalCriteria
            criteria={additionalCriteria}
            updateAdditionalCriteria={(
              value: AdditionalCriteriaReducerActions,
            ) => updateAdditionalCriteria(value, true)}
          />
        </Content>
        <Text>
          {fullReload ? (
            <Glimmer.Text />
          ) : (
            <TextActionButton
              label={formatMessage(messages.clients, {
                totalCount: clientSegmentsData.upcomingClients?.total,
              })}
              onClick={openSideDrawer}
            />
          )}
        </Text>
      </Content>
    </div>
  );
}
