import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { SetupGuideWithProviders } from "./SetupGuide";
import type { SetupGuideLoaderProps } from "./types";
import { ReviewsPage } from "../ReviewsPage";
import { PurchasePage } from "../PurchasePage";

export function SetupGuideLoader({
  accountSetup,
  routing,
  companyName,
  hasFeature,
  isConnected,
  recurlyPublicKey,
  addonSetIdentifier,
  isMobileBilled,
  salesforceTrackingId,
  requiresPaidPlan,
}: SetupGuideLoaderProps) {
  if (!hasFeature) {
    return (
      <PurchasePage
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        isMobileBilled={isMobileBilled}
        salesforceTrackingId={salesforceTrackingId}
        requiresPaidPlan={requiresPaidPlan}
      />
    );
  }

  return accountSetup ? (
    <ReviewsPage />
  ) : (
    <IntlProvider>
      <SetupGuideWithProviders
        routing={routing}
        companyName={companyName}
        isConnected={isConnected}
      />
    </IntlProvider>
  );
}
