import React, { type MutableRefObject, useRef, useState } from "react";
import { Button, Content, Heading, Popover, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";

interface SendSmsButtonsProps {
  smsEnabled: boolean;
  handleSendComm?: () => void;
  loading?: boolean;
}

export function SendSmsButtons(props: SendSmsButtonsProps) {
  return (
    <IntlProvider>
      <SendSmsButtonsInternal {...props} />
    </IntlProvider>
  );
}

function SendSmsButtonsInternal({
  smsEnabled,
  handleSendComm,
  loading,
}: SendSmsButtonsProps) {
  const { formatMessage } = useIntl();
  const popoverRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [showPopover, setShowPopover] = useState(false);
  const upgradePageUrl = "/accounts/billing_info/pricing";

  const submitButton = handleSendComm ? (
    // only the Request Card on File SMS uses this button as of writing this component
    <Button label="Send" onClick={handleSendComm} loading={loading}></Button>
  ) : (
    <button
      className="button button--green js-spinOnClick js-formSubmit"
      data-form="form.sendToClientDialogSms"
    >
      {formatMessage(messages.sendSmsButton)}
    </button>
  );

  const sendButton = smsEnabled ? (
    submitButton
  ) : (
    <div
      ref={popoverRef}
      role="button"
      tabIndex={0}
      onMouseEnter={() => setShowPopover(true)}
      onFocus={() => setShowPopover(true)}
    >
      <Popover
        attachTo={popoverRef}
        open={showPopover}
        preferredPlacement="top"
        onRequestClose={() => setShowPopover(false)}
      >
        <Content>
          <Heading level={3}>
            {formatMessage(messages.upgradeToSendHeading)}
          </Heading>
          <Text>{formatMessage(messages.upgradeToSendText)}</Text>
          <Button
            variation="learning"
            type="secondary"
            label={formatMessage(messages.viewPlansButton)}
            url={upgradePageUrl}
          />
        </Content>
      </Popover>

      <Button
        variation="learning"
        icon="lock"
        label={formatMessage(messages.sendSmsButton)}
      />
    </div>
  );

  return (
    <div className="dialog-actions u-paddingSmall u-paddingRightNone u-paddingBottomNone">
      {/* We can't use Atlantis component here because the form is defined in the erb file.
          This component should be rewritten in React starting from app/views/comms/sms/show.dialog.erb
          or before, when the modal is created */}
      <button className="button button--greyBlue button--ghost js-closeDialog">
        {formatMessage(messages.cancel)}
      </button>

      {sendButton}
    </div>
  );
}
