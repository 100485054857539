import { IntervalUnits } from "~/utilities/API/graphql";
import { messages } from "./messages";
import type { BaseCriteriaProps } from "../../../BaseCriteria";

export const INTERVAL_UNIT_OPTIONS: BaseCriteriaProps["intervalUnitOptions"] = [
  {
    label: messages.yearsLabel,
    value: IntervalUnits.YEARS,
  },
  {
    label: messages.monthsLabel,
    value: IntervalUnits.MONTHS,
  },
  {
    label: messages.weeksLabel,
    value: IntervalUnits.WEEKS,
  },
  {
    label: messages.daysLabel,
    value: IntervalUnits.DAYS,
  },
];
