import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redirectToPricingPageButton: {
    id: "billing.landing.redirectToPricingPageButton",
    defaultMessage: "See plans and pricing",
    description: "Button text to redirect to pricing page",
  },
  redirectToPricingPageFooter: {
    id: "billing.landing.redirectToPricingPageFooter",
    defaultMessage:
      "{addonSetName} is available as an add-on to Jobber plans. You must select a plan before adding {addonSetName}.",
    description: "Footer text for redirect to pricing page button",
  },
});
