import React, {
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from "react";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { BillingAddressDisplay } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/components/BillingAddressDisplay/BillingAddressDisplay";
import { BillingAddressEdit } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/components/BillingAddressEdit";
import type { BillingAddressType } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/BillingAddress.d";
import { BillingAddressPreview } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/components/BillingAddressPreview/BillingAddressPreview";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { messages } from "jobber/billing/components/EditBillingInfo/components/BillingAddress/messages";
import styles from "./ExperimentalBillingAddressStyles.module.css";

export interface BillingAddressProps {
  shouldShowDisplay: boolean;
  showPreview: boolean;
  billingAddress: BillingAddressType;
  trackingDetails: TrackingDetails;
  setBillingAddress: React.Dispatch<React.SetStateAction<BillingAddressType>>;
  onValidationError(error: FieldErrorState): void;
}

export function ExperimentalBillingAddress(props: BillingAddressProps) {
  const {
    shouldShowDisplay,
    showPreview,
    billingAddress,
    trackingDetails,
    setBillingAddress,
    onValidationError,
  } = props;

  const [showDisplay, setShowDisplay] = useState(shouldShowDisplay);

  if (showPreview && showDisplay) {
    return (
      <BillingAddressPreview
        billingAddress={billingAddress}
        onClickAction={setShowDisplay}
      />
    );
  }

  return (
    <Content>
      <BillingAddressHeader
        showButton={showDisplay}
        onClickAction={setShowDisplay}
      />
      {showDisplay ? (
        <BillingAddressDisplay billingAddress={billingAddress} />
      ) : (
        <BillingAddressEdit
          billingAddress={billingAddress}
          trackingDetails={trackingDetails}
          setBillingAddress={setBillingAddress}
          onValidationError={onValidationError}
        />
      )}
    </Content>
  );
}

function BillingAddressHeader({
  showButton,
  onClickAction,
}: {
  showButton: boolean;
  onClickAction: Dispatch<SetStateAction<boolean>>;
}) {
  const { formatMessage } = useIntl();
  const { submitting } = useContext(PurchaseFormContext);

  return (
    <>
      {showButton && (
        <div className={styles.billingAddressHeaderContainer}>
          <Heading level={4}>{formatMessage(messages.cardTitle)}</Heading>
          <Button
            ariaLabel={"Edit billing address"}
            type="tertiary"
            label={formatMessage(messages.editButton)}
            icon="arrowRight"
            iconOnRight={true}
            size="small"
            disabled={submitting}
            onClick={() => onClickAction(false)}
          />
        </div>
      )}
    </>
  );
}
