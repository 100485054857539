import { type Dispatch, type SetStateAction, useEffect } from "react";
import type { MessageCenterActionType } from "../MessageCenterContext";

interface OpenChatDrawerToConversationEventDetail {
  conversationId: string;
  recipient: {
    id: string;
    name: string;
    contactInfo: string;
  };
  title: string;
}

export const useHandleOpenToConversationEvent = (
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  dispatch: Dispatch<MessageCenterActionType>,
) => {
  useEffect(() => {
    const handleEvent = (
      event: CustomEvent<OpenChatDrawerToConversationEventDetail>,
    ) => {
      const detail = event.detail;
      setIsOpen(true);
      dispatch({
        type: "SHOW_CHAT",
        conversationId: detail.conversationId,
        recipient: detail.recipient,
        title: detail.title,
      });
    };

    window.addEventListener("jobber.openChatDrawerToConversation", handleEvent);
    return () =>
      window.removeEventListener(
        "jobber.openChatDrawerToConversation",
        handleEvent,
      );
  }, [setIsOpen, dispatch]);
};
