import { createMachine } from "xstate";
import type { SchedulingRecommendationEvents } from "../types";

export const recommendationMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QCcwGMD2BbLYB2EAhgC4CWGeAsoWgBal5gB0sdkArgDZgDKYnAMwDEPAMIAJAKIARAKoAZSQH0eAeVUA5STwAqAbQAMAXUSgADhlikyFUyAAeiAEwBGAKxM3AZgDsXpwAsbgEGBi4AbH4ANCAAnoi+TkwuBgCcbqleABxuWalBXm4AvkUxqJg4+EQ2VDT0jCxsEFy8-MJiUnKKSqLyqjza+sZ2FlY1do4Irh7efoHBoRHRcc5hTOGROV4B+QZubu4lZejYuAQk5LV0DMystBzcPBgUcMQiEjIKygPyAGKGJiQIFG1kuE2cbiSTnC7lCWQiaTyMXiCH8SRcASyAQCPh8Lh84S8+KOIHKpyqFwo1GuDTuD14z0YsDeHU+3V6-UGAJGllBtiBk3CTi8TCyi0xYq84QyLmRCSc6Mx2JcKT82K8JLJlXONWp9VuTRaok4lle706XxU6i0um5QJB4wFiCFIrFYQlBilMrlqJ86LS4VCAXCqWFTgJmpO2uqlz1N0a92a3GNpuZ5rZ30kfzt5l5jtAgsDnkC-jcBh8qR8ARcXh98OSWT9YoCwq8qSyXg1JLwGAgcDsWrOMapdRuPLGYKdCAAtOEfbPIxUh5SrvqE-S+IJx3y8OCEC2faGmMHQx3cY3go3F+SdbHR7TDY9Ga9t-mHIgAtlPAYstCnOXCmyVIfU7Axj3CHEXC2UMfA7a9oxXOMH0TI0TVgF97TzScC0QHJwiYXEUiyQlfCgmsfX-HwmCcA5-B2bF0n2EoSiAA */
  id: "recommendationMachine",

  predictableActionArguments: true,

  schema: {
    events: {} as SchedulingRecommendationEvents,
  },

  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  tsTypes: {} as import("./recommendation.machine.typegen").Typegen0,

  initial: "scheduleSelf",

  states: {
    scheduleSelf: {
      on: {
        SCHEDULE_SOONEST: {
          target: "scheduleSoonest",
        },
        SCHEDULE_CLOSEST: {
          target: "scheduleClosest",
        },
      },
    },
    scheduleSoonest: {
      on: {
        SCHEDULE_SELF: {
          target: "scheduleSelf",
        },
        SCHEDULE_CLOSEST: {
          target: "scheduleClosest",
        },
      },
    },
    scheduleClosest: {
      on: {
        SCHEDULE_SOONEST: {
          target: "scheduleSoonest",
        },
        SCHEDULE_SELF: {
          target: "scheduleSelf",
        },
      },
    },
  },
});

export type RecommendationMachine = typeof recommendationMachine;
