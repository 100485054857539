import { useScheduleCardState } from "./useScheduleCardState";
import { setTimeToMidnightOnDate } from "../utils";
import type { RepeatOption } from "../../OneOffScheduler";
import type { OnChange } from "../types";

const useDateAndTimeChangeHandlers = (onChange: (type: OnChange) => void) => {
  const handleStartDateChange = (startDate: Date | undefined) => {
    onChange({
      action: "changeStartDate",
      startDate: startDate ? setTimeToMidnightOnDate(startDate) : undefined,
    });
  };

  const handleEndDateChange = (endDate: Date | undefined) => {
    onChange({
      action: "changeEndDate",
      endDate: endDate ? setTimeToMidnightOnDate(endDate) : undefined,
    });
  };

  const handleStartTimeChange = (updatedStartTime: Date | undefined) => {
    onChange({
      action: "changeStartTime",
      startTime: updatedStartTime,
    });
  };

  const handleEndTimeChange = (updatedEndTime: Date | undefined) => {
    onChange({
      action: "changeEndTime",
      endTime: updatedEndTime,
    });
  };

  const handleStartDateAndTimeChange = (startTime: Date) => {
    handleStartDateChange(startTime);
    handleStartTimeChange(startTime);
  };

  const handleEndDateAndTimeChange = (endTime: Date) => {
    handleEndDateChange(endTime);
    handleEndTimeChange(endTime);
  };

  return {
    handleStartDateChange,
    handleEndDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleStartDateAndTimeChange,
    handleEndDateAndTimeChange,
  };
};

const useSchedulingInputChangeHandlers = (
  onChange: (type: OnChange) => void,
) => {
  const handleRecurrenceSelection = (value: RepeatOption["value"]) => {
    onChange({
      action: "selectRepeatOption",
      repeatOption: value,
    });
  };

  const handleScheduleLater = (isUnscheduled: boolean) => {
    onChange({
      action: isUnscheduled ? "scheduleLater" : "scheduled",
    });
  };

  const handleAddUnscheduledVisit = (addUnscheduledVisit: boolean) => {
    onChange({
      action: addUnscheduledVisit
        ? "addUnscheduledVisit"
        : "noUnscheduledVisit",
    });
  };

  const handleVisitDurationChange = (updatedDuration: number) => {
    onChange({
      action: "selectVisitDuration",
      updatedDuration,
    });
  };

  const handleSchedulingInputTeamMemberChange = (teamMemberIds: string[]) => {
    onChange({
      action: "changeSchedulingInputTeamMembers",
      teamMemberIds,
    });
  };

  return {
    handleRecurrenceSelection,
    handleScheduleLater,
    handleAddUnscheduledVisit,
    handleVisitDurationChange,
    handleSchedulingInputTeamMemberChange,
  };
};

export const useScheduleCardChangeHandlers = () => {
  const { onChange } = useScheduleCardState();
  const dateHandlers = useDateAndTimeChangeHandlers(onChange);
  const inputHandlers = useSchedulingInputChangeHandlers(onChange);

  return {
    ...dateHandlers,
    ...inputHandlers,
  };
};
