import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { SetupWizardStepProps } from "jobber/setupWizard/types";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { MaskingTapeFact } from "jobber/setupWizard/components/MaskingTapeFact";
import { revenueFacts } from "jobber/setupWizard/components/MaskingTapeFact/facts";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { getIndustrySpecificContent } from "jobber/setupWizard/utils";
import { messages as setupWizardMessages } from "jobber/setupWizard/messages";
import { messages } from "./messages";
import { RevenuePageForm } from "./components/RevenuePageForm";
import { Review } from "../Review";

export function RevenuePage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.revenue,
    });
  }, []);

  const { wizardData, companyPhotos, companyReviews } =
    useContext(SetupWizardContext);
  const {
    revenuePage: { backgroundImage },
  } = getIndustrySpecificContent(wizardData.industry);

  const companyPhoto = companyPhotos[1];
  const bgImg = {
    alt: companyPhoto
      ? setupWizardMessages.companyImageAlt
      : backgroundImage.alt,
    src: companyPhoto ?? backgroundImage.src,
  };
  const companyReview = companyReviews[1];

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={formatMessage(messages.revenuePageDefaultHeading)}
          copy={formatMessage(messages.revenuePageDefaultCopy)}
          form={<RevenuePageForm {...stepProps} />}
        />
      }
      rightComponent={
        <MediaColumn backgroundImage={bgImg} hasOverlay={!!companyPhoto}>
          {companyReview ? (
            <Review>
              <Review.MaskingTapeContent>
                <Review.Text text={companyReview.text} />
              </Review.MaskingTapeContent>
              <Review.AdditionalInfo>
                <Review.Rating rating={companyReview.rating} />
                <Review.Author
                  companyName={wizardData.companyName ?? ""}
                  name={companyReview.authorName}
                />
              </Review.AdditionalInfo>
            </Review>
          ) : (
            <MaskingTapeFact fact={revenueFacts.defaultFact.copy} />
          )}
        </MediaColumn>
      }
    />
  );
}
