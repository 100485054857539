import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "subscriptionAddons.removeAddonModal.modalTitle",
    defaultMessage: "Remove {addonName}?",
    description: "Modal title",
  },
  cancel: {
    id: "subscriptionAddons.removeAddonModal.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button label",
  },
  remove: {
    id: "subscriptionAddons.removeAddonModal.remove",
    defaultMessage: "Remove",
    description: "Remove button label",
  },
  resubscribe: {
    id: "subscriptionAddons.removeAddonModal.resubscribe",
    defaultMessage: "Resubscribe",
    description: "Resubscribe button label",
  },
  removeAriaLabel: {
    id: "subscriptionAddons.removeAddonModal.removeAriaLabel",
    defaultMessage: "Remove { addonName }",
    description: "Remove button aria label",
  },
  resubscribeAriaLabel: {
    id: "subscriptionAddons.removeAddonModal.resubscribeAriaLabel",
    defaultMessage: "Resubscribe to { addonName }",
    description: "Resubscribe button aria label",
  },
  removalInformation: {
    id: "subscriptionAddons.removeAddonModal.removalInformation",
    defaultMessage:
      "This subscription will be cancelled immediately. You'll lose access to {addonRemovalDetails}, including:",
    description: "Information about what happens when you remove an addon",
  },
  campaigns: {
    id: "subscriptionAddons.removeAddonModal.Campaigns",
    defaultMessage: "Campaigns",
    description: "The Campaigns add-on you will lose",
  },
  referrals: {
    id: "subscriptionAddons.removeAddonModal.Referrals",
    defaultMessage: "Referrals",
    description: "The Referrals add-on you will lose",
  },
  reviews: {
    id: "subscriptionAddons.removeAddonModal.Reviews",
    defaultMessage: "Reviews",
    description: "The Reviews add-on you will lose",
  },
  marketingSuiteDetails: {
    id: "subscriptionAddons.removeAddonModal.marketingSuiteDetails",
    defaultMessage: "all add-ons (Reviews, Referrals, and Campaigns)",
    description: "The addons you will lose if you remove the marketing suite",
  },
  removalCreditMessage: {
    id: "subscriptionAddons.removeAddonModal.removalCreditMessage",
    defaultMessage:
      "If you have an unused portion of this subscription, you will receive a credit towards your next bill.",
    description: "Information about credits given when removing an addon",
  },
  campaignsFeature1: {
    id: "subscriptionAddons.removeAddonModal.campaignsFeature1",
    defaultMessage: "creating and sending campaigns",
    description: "A feature that will be lost when removing campaigns",
  },
  campaignsFeature2: {
    id: "subscriptionAddons.removeAddonModal.campaignsFeature2",
    defaultMessage: "viewing and tracking past campaigns",
    description: "A feature that will be lost when removing campaigns",
  },
  referralsFeature1: {
    id: "subscriptionAddons.removeAddonModal.referralsFeature1",
    defaultMessage: "automating incentives for referrals",
    description: "A feature that will be lost when removing referrals",
  },
  referralsFeature2: {
    id: "subscriptionAddons.removeAddonModal.referralsFeature2",
    defaultMessage: "tracking and reporting on referrals",
    description: "A feature that will be lost when removing referrals",
  },
  reviewsFeature1: {
    id: "subscriptionAddons.removeAddonModal.reviewsFeature1",
    defaultMessage: "automatic review requests",
    description: "A feature that will be lost when removing reviews",
  },
  reviewsFeature2: {
    id: "subscriptionAddons.removeAddonModal.reviewsFeature2",
    defaultMessage: "viewing and tracking reviews data",
    description: "A feature that will be lost when removing reviews",
  },
  marketingSuiteFeature1: {
    id: "subscriptionAddons.removeAddonModal.marketingSuiteFeature1",
    defaultMessage: "Reviews: automatically request reviews from customers",
    description: "A feature that will be lost when removing marketing suite",
  },
  marketingSuiteFeature2: {
    id: "subscriptionAddons.removeAddonModal.marketingSuiteFeature2",
    defaultMessage:
      "Referrals: track and reward customers that refer your business",
    description: "A feature that will be lost when removing marketing suite",
  },
  marketingSuiteFeature3: {
    id: "subscriptionAddons.removeAddonModal.marketingSuiteFeature3",
    defaultMessage:
      "Campaigns: create and send campaigns to engage with your customers",
    description: "A feature that will be lost when removing marketing suite",
  },
  addonRemoved: {
    id: "subscriptionAddons.removeAddonModal.addonRemoved",
    defaultMessage: "Removed {addonName}",
    description: "Message displayed when an addon is removed",
  },
  addonPurchasedIndividually: {
    id: "subscriptionAddons.removeAddonModal.addonPurchasedIndividually",
    defaultMessage: "All add-ons may be purchased individually.",
    description: "Message displayed when the marketing suite addon is removed",
  },
  // This is used when the GQL mutation fails, but we don't get the error message back from the userErrors
  genericError: {
    id: "subscriptionAddons.removeAddonModal.genericError",
    defaultMessage:
      "An unexpected error occurred, please contact us at 1-888-721-1115",
    description: "Generic error message when removing an addon fails",
  },
});
