import React, { useMemo } from "react";
import type { IconColorNames, IconNames } from "@jobber/design";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import classNames from "classnames";
import { Glimmer, Markdown } from "@jobber/components";
import type {
  AiAssistantAction,
  AiAssistantImage,
  AiAssistantQuestionInterpretation,
  AiAssistantSimplePlot,
  AiAssistantVisual,
} from "~/utilities/API/graphql";
import { AiAssistantActionIntent } from "~/utilities/API/graphql";
import { SupportQuestionFollowup } from "./SupportQuestionFollowup";
import styles from "./ConversationListItem.module.css";
import { Feedback } from "./Feedback";
import { ConversationPills } from "./ConversationPills";
import { LinkPreview } from "./LinkPreview";
import { ChatIcon } from "./ChatIcon";
import { QuestionInterpretation } from "./QuestionInterpretation";
import { DataVisualization } from "./dataVisualization";
import { SupportEscalationOptions } from "./SupportEscalationOptions";
import { ExistingSupportConversation } from "./ExistingSupportConversation";
import { trackExternalLinkOpen } from "../utils";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

export enum messageType {
  LOADING = "loading",
  ERROR = "error",
  REQUEST = "request",
  RESPONSE = "response",
  PARTIAL_RESPONSE = "partial_response",
  SYSTEM = "system",
}

export interface Followup {
  displayText: string;
  prompt: string;
  loading: boolean;
  disabled?: boolean;
}

export interface ActionFollowup {
  displayText: string;
  prompt: string;
  metadata: string;
}

export interface ConversationListItemModel {
  readonly actions: AiAssistantAction[];
  readonly content: string;
  readonly footnotes?: string[];
  readonly icon?: IconNames;
  readonly iconColor?: IconColorNames;
  readonly id: string;
  readonly requestId: string;
  readonly type: messageType;
  readonly onClick?: (prompt: PromptType) => void;
  readonly visuals: AiAssistantVisual[];
  readonly followups: Followup[];
  readonly showFeedbackOptions: boolean;
  readonly questionInterpretation:
    | AiAssistantQuestionInterpretation
    | undefined;
  readonly disabled?: boolean;
}

export interface ConversationListItemProps {
  readonly item: ConversationListItemModel;
}

export function ConversationListItem({ item }: ConversationListItemProps) {
  const { content, icon, iconColor, type } = item;

  return (
    <div className={styles.conversationListItem}>
      {type === messageType.REQUEST ? (
        <Request content={content} />
      ) : type === messageType.ERROR ? (
        <Error content={content} icon={icon} iconColor={iconColor} />
      ) : (
        <Response item={item} />
      )}
    </div>
  );
}

interface RequestProps {
  readonly content: string;
}

const Request = ({ content }: RequestProps) => {
  return <div className={styles.requestContainer}>{content}</div>;
};

interface ErrorProps {
  readonly content: string;
  readonly icon?: IconNames;
  readonly iconColor?: IconColorNames;
}

const Error = ({ content, icon, iconColor }: ErrorProps) => {
  return (
    <div className={classNames(styles.requestContainer, styles.error)}>
      {icon && iconColor && (
        <div className={styles.icon}>
          <Icon name={icon} color={iconColor} />
        </div>
      )}
      <div>{content}</div>
    </div>
  );
};

export const Response = ({ item }: ConversationListItemProps) => {
  return (
    <div className={styles.responseContainer}>
      <ChatIcon type={item.type} />
      {item.type !== messageType.LOADING ? (
        <ResponseWithContent item={item} />
      ) : (
        <Glimmer.Text />
      )}
    </div>
  );
};

const ResponseWithContent = ({ item }: ConversationListItemProps) => {
  const {
    actions,
    content,
    footnotes,
    requestId,
    onClick,
    visuals,
    followups,
    questionInterpretation,
  } = item;

  const images = useMemo(
    () =>
      visuals.filter(
        (v): v is AiAssistantImage =>
          v.__typename === "AiAssistantImage" && !!v.imageUrl,
      ),
    [visuals],
  );

  const graph = useMemo(
    () =>
      visuals.find(
        (v): v is AiAssistantSimplePlot =>
          v.__typename === "AiAssistantSimplePlot",
      ),
    [visuals],
  );

  return (
    <div className={styles.responseContentColumn}>
      <QuestionInterpretation interpretation={questionInterpretation} />
      <Markdown
        onLinkClick={({ href }) => trackExternalLinkOpen(href, "inline")}
        content={content}
        basicUsage={false}
        externalLink={true}
      />
      {graph && <DataVisualization parameters={graph} />}
      {images.map((image, index) => (
        <LinkPreview
          key={index}
          title={image.title}
          url={image.imageUrl!}
          altText={image.altText}
          supportingLinkUrl={
            image.supportingLinkUrl
              ? new URL(image.supportingLinkUrl)
              : undefined
          }
        />
      ))}
      {actions.map((action, index) => {
        const commonProps = {
          key: index,
          requestId,
          message: action.metadata ?? "",
        };
        switch (action.intent) {
          case AiAssistantActionIntent.SHOW_SUPPORT_PILLS:
            return (
              <SupportQuestionFollowup {...commonProps} onClick={onClick} />
            );
          case AiAssistantActionIntent.LIVE_HELP_DIALOG:
          case AiAssistantActionIntent.TRANSFER_TO_SUPPORT:
            return (
              <SupportEscalationOptions
                {...commonProps}
                supportEscalationPrompt={action.metadata || ""}
                followups={(action.followups as ActionFollowup[]) ?? []}
              />
            );
          case AiAssistantActionIntent.SHOW_SUPPORT_ESCALATION:
            return (
              <ExistingSupportConversation
                key={index}
                conversationId={action.metadata || ""}
                followups={(action.followups as ActionFollowup[]) ?? []}
              />
            );
          default:
            return undefined;
        }
      })}
      {footnotes && footnotes.length > 0 && (
        <Text variation="subdued" size="small">
          <Emphasis variation="italic">{footnotes.join(" ")}</Emphasis>
        </Text>
      )}
      {followups.length > 0 && onClick && (
        <ConversationPills prompts={followups} onClick={onClick} />
      )}
      {item.showFeedbackOptions && item.type == messageType.RESPONSE && (
        <Feedback requestId={requestId} />
      )}
    </div>
  );
};
