import { cloneDeep } from "lodash";
import type { ArrivalWindow } from "jobber/google_lsa/MerchantConfiguration";
import { getDateFromTime } from "utilities/date-time";

interface AddRow {
  type: "addRow";
}

interface RemoveRow {
  type: "removeRow";
  rowIndex: number;
}

interface UpdateRow {
  type: "updateRow";
  rowIndex: number;
  data: ArrivalWindow;
}

interface Reset {
  type: "reset";
  data: ArrivalWindow[];
}

export type Action = AddRow | RemoveRow | UpdateRow | Reset;

function addRow(state: ArrivalWindow[]): ArrivalWindow[] {
  const newState = cloneDeep(state);
  newState.push({
    startAt: getDateFromTime(9, 0),
    endAt: getDateFromTime(11, 0),
    dayOfWeekMap: {
      sunday: false,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
    },
  });
  return newState;
}

function removeRow(state: ArrivalWindow[], action: RemoveRow): ArrivalWindow[] {
  const newState = cloneDeep(state);
  newState.splice(action.rowIndex, 1);
  return newState;
}

function updateRow(state: ArrivalWindow[], action: UpdateRow): ArrivalWindow[] {
  const newState: ArrivalWindow[] = cloneDeep(state);
  newState[action.rowIndex] = action.data;
  return newState;
}

// Create a working copy of the windows that we can edit freely without
// worrying about affecting state outside the edit window.
export function initReducer(windows: ArrivalWindow[]) {
  return cloneDeep(windows);
}

export function arrivalWindowReducer(state: ArrivalWindow[], action: Action) {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.data);
    case "addRow":
      return addRow(state);
    case "removeRow":
      return removeRow(state, action);
    case "updateRow":
      return updateRow(state, action);
    default:
      throw new Error();
  }
}
