import React from "react";
import { Banner } from "@jobber/components/Banner";
import { handleCloseRailsDialog } from "utilities/bridges/handleCloseRailsDialog";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  RecipientPicker,
  type RecipientPickerProps,
} from "jobber/recipientPicker/components/EmailRecipientPicker/RecipientPicker";
import type { MutationErrors } from "~/utilities/API/graphql";
import { EditSmsBody, type PropsEditSmsBody } from "../EditSmsBody";
import { useSendRequestCardOnFileMessage } from "../useSendRequestCardOnFileMessage";
import { SendSmsButtons } from "../SendSmsButtons";

export interface EditSmsBodyProps extends PropsEditSmsBody {
  /** Client ID */
  clientId: string;
}

export interface SendSmsCommProps {
  editSmsBody: EditSmsBodyProps;
  recipientPicker: RecipientPickerProps;
  smsEnabled: boolean;
}
export function SendSmsComm(props: SendSmsCommProps) {
  return (
    <APIProvider>
      <InnerSendSmsComm {...props} />
    </APIProvider>
  );
}

function InnerSendSmsComm({
  editSmsBody,
  recipientPicker,
  smsEnabled,
}: SendSmsCommProps) {
  const [errors, setErrors] = React.useState<MutationErrors[]>([]);
  const [recipients, setRecipients] = React.useState<string[]>(
    recipientPicker.preloadedRecipients,
  );

  const { sendRequestCardOnFileMessage, loading } =
    useSendRequestCardOnFileMessage();

  async function handleSendComm() {
    const result = await sendRequestCardOnFileMessage({
      recipients: recipients,
      clientId: editSmsBody.clientId,
    });
    const mutationErrors: MutationErrors[] =
      result?.data?.sendRequestCardOnFileMessage.userErrors || [];

    if (mutationErrors.length > 0) {
      setErrors(mutationErrors);
      return;
    }

    if (mutationErrors.length === 0 && !loading) {
      handleCloseRailsDialog();
    }
  }

  function handleOnChange(value: string[]) {
    setRecipients([...value]);
  }
  function buildErrorString(err: MutationErrors[]) {
    return err.map((e: MutationErrors, index: number) => {
      return (
        <p key={index} className="u-marginBottomNone">
          {e.message}
        </p>
      );
    });
  }

  return (
    <>
      {errors.length > 0 && (
        <div className="u-marginBottomSmall">
          <Banner type="error" dismissible={false}>
            {buildErrorString(errors)}
          </Banner>
        </div>
      )}
      <RecipientPicker
        dropdownRecipients={recipientPicker.dropdownRecipients}
        preloadedRecipients={recipients}
        inputName="recipients[]"
        placeholderSingular="Type a mobile number"
        placeholderPlural="Type or select mobile number"
        restrictInput={false}
        showLabel={true}
        onChange={handleOnChange}
      />
      <EditSmsBody
        defaultBodyMessage={editSmsBody.defaultBodyMessage}
        footerMessage={editSmsBody.footerMessage}
        templateUrl={editSmsBody.templateUrl}
        helpText={editSmsBody.helpText}
        isEditable={editSmsBody.isEditable}
      />
      <SendSmsButtons
        smsEnabled={smsEnabled}
        handleSendComm={handleSendComm}
        loading={loading}
      />
    </>
  );
}
