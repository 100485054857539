import React, { useCallback, useEffect, useMemo } from "react";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { useClientSegmentOptions } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentOptions";
import { useClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentData";
import { Segment } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { getSegmentCriteriasFromSegments } from "jobber/campaigns/utils/segmentCriteriaUtils";
import { SelectableSegments } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/components/SelectableSegments/SelectableSegments";
import { useClientSegmentSideDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/hooks/useClientSegmentSideDrawer/useClientSegmentSideDrawer";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/messages";
import { ClientSegmentDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/components/ClientSegmentDrawer/ClientSegmentDrawer";

interface ClientSegmentInternalsProps {
  loading: boolean;
}

export function ClientSegmentInternals({
  loading,
}: ClientSegmentInternalsProps) {
  const { formatMessage } = useIntl();

  const {
    campaignSegment: {
      selectedSegmentType,
      setSelectedSegmentType,
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    },
    campaignContent: { templateType },
  } = useCampaignWizardContext();

  const {
    openSideDrawer,
    closeSideDrawer,
    isOpen,
    segmentName,
    additionalCriteria,
    segmentCriteria,
    updateAdditionalCriteria,
  } = useClientSegmentSideDrawer();

  const clientSegmentInternalsConditionalVariables = useMemo(
    () => ({
      isUpcomingClientDrawer: selectedSegmentType === Segment.UPCOMING_CLIENTS,
      isPastClientDrawer: selectedSegmentType === Segment.PAST_CLIENTS,
      isAllClientDrawer: selectedSegmentType === Segment.ALL_CLIENTS,
    }),
    [selectedSegmentType],
  );
  const segmentCriterias = useMemo(() => {
    return {
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    };
  }, [
    allClientsSegmentCriteria,
    pastClientsSegmentCriteria,
    upcomingClientsSegmentCriteria,
  ]);

  const { fullReload, loadingMore, refetch, clientSegmentsData } =
    useClientSegmentData({
      conditionalVariables: clientSegmentInternalsConditionalVariables,
      segmentCriterias,
      segmentType: selectedSegmentType,
    });

  const segmentOptions = useClientSegmentOptions({
    clientsCriteriaInput: segmentCriterias,
    clientSegments: clientSegmentsData,
    selectedSegmentType,
    templateType,
  });

  const updateSelectedOption = useCallback(
    (value: Segment) => {
      setSelectedSegmentType(value, true);
    },
    [setSelectedSegmentType],
  );

  useEffect(() => {
    const variables = {
      ...clientSegmentInternalsConditionalVariables,
      ...getSegmentCriteriasFromSegments({
        segmentCriterias: segmentCriterias,
        segmentType: selectedSegmentType,
      }),
      after: btoa("0"),
    };

    refetch(variables);
  }, [
    segmentCriterias,
    selectedSegmentType,
    refetch,
    clientSegmentInternalsConditionalVariables,
  ]);

  function openSideDrawerFn() {
    const heading =
      segmentOptions.find(card => card.type === selectedSegmentType)?.heading ||
      "";
    openSideDrawer(heading);
  }

  return (
    <Content spacing="large">
      <Text>{formatMessage(messages.pageSubHeading)}</Text>
      <SelectableSegments
        segmentOptions={segmentOptions}
        value={selectedSegmentType}
        name="ClientSegment"
        loading={loading || fullReload}
        onChange={updateSelectedOption}
        criteria={{
          additionalCriteria: additionalCriteria,
          updateAdditionalCriteria: updateAdditionalCriteria,
          baseCriteria: segmentCriteria,
        }}
        openSideDrawer={openSideDrawerFn}
      />
      <ClientSegmentDrawer
        isOpen={isOpen}
        closeSideDrawer={closeSideDrawer}
        selectedSegment={selectedSegmentType}
        segmentName={segmentName}
        loadingState={{
          loadingMore: loadingMore,
          fullReload: fullReload,
        }}
      />
    </Content>
  );
}
