import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useHandleUpdatedClientSegment } from "jobber/campaigns/hooks/useHandleUpdatedClientSegment";
import type {
  ClientSegmentFragment,
  IntervalUnits,
  PastClientsCriteriaInput,
  Segment,
} from "~/utilities/API/graphql";
import { CAMPAIGNS_SEGMENT_EDIT_PATH } from "jobber/campaigns/constants";
import type { ClientSegmentData } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentDrawer/ClientSegmentDrawer";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { messages } from "jobber/campaigns/views/CampaignRecipientsPage/components/SelectClientSegment/messages";

interface UseOnEditClientSegmentCriteriaProps {
  clientSegmentData: ClientSegmentData;
  closeSideDrawer?: () => void;
  setClientSegment: (clientSegment: ClientSegmentFragment) => void;
  resetPagination: (variables: {
    unit: IntervalUnits;
    interval: number;
  }) => void;
  segmentType: Segment;
}

export function useOnEditClientSegmentCriteria({
  clientSegmentData,
  setClientSegment,
  closeSideDrawer,
  resetPagination,
  segmentType,
}: UseOnEditClientSegmentCriteriaProps) {
  const { formatMessage } = useIntl();
  const { path } = useRouteMatch();
  const history = useHistory();
  const {
    campaignSegment: { setNotDirty },
  } = useCampaignWizardContext();

  const { updateClientSegment, loadingDefaultTemplate } =
    useHandleUpdatedClientSegment({
      clientSegment: clientSegmentData.rawData,
      segmentType,
    });

  const [updateSegmentCriteriaLoading, setUpdateSegmentCriteriaLoading] =
    useState(false);

  const [saveError, setDrawerError] = useState<string | undefined>();

  async function onEditClientSegment(
    newSegmentCriteria: PastClientsCriteriaInput,
  ) {
    if (updateSegmentCriteriaLoading || loadingDefaultTemplate) {
      return;
    }
    setUpdateSegmentCriteriaLoading(true);
    void updateClientSegment({
      newSegmentCriteria,
      onError: error => {
        setDrawerError(error);
        setUpdateSegmentCriteriaLoading(false);
      },
      onSuccess: newClientSegment => {
        setDrawerError(undefined);
        if (path !== "edit" && newClientSegment.campaignId) {
          history.replace(
            generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, {
              campaignId: newClientSegment.campaignId,
            }),
          );
        }
        setClientSegment(newClientSegment.clientSegment);
        resetPagination(newSegmentCriteria);
        showToast({ message: formatMessage(messages.savedClientSegment) });
        setUpdateSegmentCriteriaLoading(false);
        setNotDirty();
        closeSideDrawer && closeSideDrawer();
      },
    });
  }

  return {
    saveError,
    onEditClientSegment,
    updateSegmentCriteriaLoading,
  };
}
