import type { AutomationRule } from "~/utilities/API/graphql";
import type { AllConditionTypes } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/types";
import { CreateRuleConditionTypes } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/ruleBuilder/types";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { type FromServerCondition, ServerRuleConditionTypes } from "../types";

export function parseConditions(conditions: AllConditionTypes[]) {
  return (
    conditions
      // eslint-disable-next-line max-statements
      .map(condition => {
        if (condition.arguments.type === CreateRuleConditionTypes.TextOnly) {
          // skip these since they will only be present for display purposes
          return false;
        }

        let expression;
        switch (condition.arguments.type) {
          case CreateRuleConditionTypes.ClientTags:
            if (condition.arguments.fields.contains) {
              expression = `[":in",[":literal","${condition.arguments.fields.tag}"],[":var","tag_names",":dig"]]`;
            } else {
              expression = `[":not",[":in",[":literal","${condition.arguments.fields.tag}"],[":var","tag_names",":dig"]]]`;
            }
            return {
              name: ServerRuleConditionTypes.ClientHasTags,
              arguments: { expression },
              operation: "predicate",
            };
          case CreateRuleConditionTypes.QuoteClientTags:
            if (condition.arguments.fields.contains) {
              expression = `[":in",[":literal","${condition.arguments.fields.tag}"],[":var","client_tag_names",":dig"]]`;
            } else {
              expression = `[":not",[":in",[":literal","${condition.arguments.fields.tag}"],[":var","client_tag_names",":dig"]]]`;
            }
            return {
              name: ServerRuleConditionTypes.QuoteClientHasTags,
              arguments: { expression },
              operation: "predicate",
            };
          case CreateRuleConditionTypes.QuoteLineItems:
            if (condition.arguments.fields.includes) {
              expression = `[":in",[":literal",${decodeId(condition.arguments.fields.item as string)}],[":var","line_item_type_ids",":dig"]]`;
            } else {
              expression = `[":not",[":in",[":literal",${decodeId(condition.arguments.fields.item as string)}],[":var","line_item_type_ids",":dig"]]]`;
            }
            return {
              name: ServerRuleConditionTypes.QuoteHasLineItems,
              arguments: { expression },
              operation: "predicate",
            };
          case CreateRuleConditionTypes.LineItems:
            return {
              name: ServerRuleConditionTypes.ClientHasJobLineItems,
              arguments: {
                include: condition.arguments.fields.includes,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                work_item_id: condition.arguments.fields.item,
              },
              operation: "front-loading",
            };
          case CreateRuleConditionTypes.JobType:
            return {
              name: ServerRuleConditionTypes.JobType,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              arguments: { job_type: condition.arguments.fields.type },
              operation: "front-loading",
            };
          case CreateRuleConditionTypes.QuoteStatus:
            if (condition.arguments.fields.contains) {
              expression = `[":in",[":literal","${condition.arguments.fields.status}"],[":var","status",":dig"]]`;
            } else {
              expression = `[":not",[":in",[":literal","${condition.arguments.fields.status}"],[":var","status",":dig"]]]`;
            }
            return {
              name: ServerRuleConditionTypes.QuoteStatus,
              arguments: { expression },
              operation: "predicate",
            };
          case CreateRuleConditionTypes.CloseOnPendingQuotesQuoteStatus:
            if (condition.arguments.fields.contains) {
              expression = `[":in",[":literal","${condition.arguments.fields.status}"],[":var","status",":dig"]]`;
            } else {
              expression = `[":not",[":in",[":literal","${condition.arguments.fields.status}"],[":var","status",":dig"]]]`;
            }
            return {
              name: ServerRuleConditionTypes.CloseOnPendingQuotesQuoteStatus,
              arguments: { expression },
              operation: "predicate",
            };
          case CreateRuleConditionTypes.ClientLeadStatus:
            return {
              name: ServerRuleConditionTypes.ClientLeadStatus,
              arguments: {
                expression: `[":eq",[":literal",${!!condition.arguments.fields.isLead}],[":var","is_client_lead",":dig"]]`,
              },
              operation: "predicate",
            };
        }
      })
      .filter(Boolean)
  );
}

export function sExprParserContains(expression: string) {
  return JSON.parse(expression)[0] === ":not" ? false : true;
}

// Example expression: '[":not",[":in",[":literal","Agent"],[":var","tag_names",":dig"]]]'
// See test for examples
export function sExprParserValueLabel(expression: string) {
  let literal = JSON.parse(expression)[1][1];
  if (!sExprParserContains(expression)) {
    // this parses out the tag name from the expression (could be wrapped in a :not expression)
    literal = literal[1];
  }
  return literal;
}

function encodeId(id: string | number): string {
  return isNaN(id as number) ? (id as string) : btoa(id as string);
}

export function parseIncomingConditions(
  rule: AutomationRule,
  defaultRule: AutomationRule | undefined,
): AllConditionTypes[] {
  const conditions = rule.condition.conditions.map(
    (condition: FromServerCondition): AllConditionTypes => {
      switch (condition.name) {
        case ServerRuleConditionTypes.ClientHasTags:
          return {
            task: "condition",
            arguments: {
              type: CreateRuleConditionTypes.ClientTags,
              fields: {
                contains: sExprParserContains(condition.arguments.expression),
                tag: sExprParserValueLabel(condition.arguments.expression),
              },
            },
          };
        case ServerRuleConditionTypes.QuoteClientHasTags:
          return {
            task: "condition",
            arguments: {
              type: CreateRuleConditionTypes.QuoteClientTags,
              fields: {
                contains: sExprParserContains(condition.arguments.expression),
                tag: sExprParserValueLabel(condition.arguments.expression),
              },
            },
          };
        case ServerRuleConditionTypes.QuoteHasLineItems:
          return {
            task: "condition",
            arguments: {
              type: CreateRuleConditionTypes.QuoteLineItems,
              fields: {
                includes: sExprParserContains(condition.arguments.expression),
                item: encodeId(
                  sExprParserValueLabel(condition.arguments.expression),
                ),
              },
            },
          };
        case ServerRuleConditionTypes.ClientHasJobLineItems:
          return {
            task: "condition",
            arguments: {
              type: CreateRuleConditionTypes.LineItems,
              fields: {
                includes: condition.arguments.include,
                item: condition.arguments.work_item_id,
              },
            },
          };
        case ServerRuleConditionTypes.JobType:
          return {
            task: "condition",
            arguments: {
              type: CreateRuleConditionTypes.JobType,
              fields: {
                type: condition.arguments.job_type,
              },
            },
          };
        case ServerRuleConditionTypes.QuoteStatus:
          return {
            task: "condition",
            isNonDeletable: true,
            arguments: {
              type: CreateRuleConditionTypes.QuoteStatus,
              fields: {
                contains: sExprParserContains(condition.arguments.expression),
                status: sExprParserValueLabel(condition.arguments.expression),
              },
            },
          };
        case ServerRuleConditionTypes.CloseOnPendingQuotesQuoteStatus:
          return {
            task: "condition",
            isNonDeletable: true,
            arguments: {
              type: CreateRuleConditionTypes.CloseOnPendingQuotesQuoteStatus,
              fields: {
                contains: sExprParserContains(condition.arguments.expression),
                status: sExprParserValueLabel(condition.arguments.expression),
              },
            },
          };
        case ServerRuleConditionTypes.ClientLeadStatus:
          return {
            task: "condition",
            isNonDeletable: true,
            arguments: {
              type: CreateRuleConditionTypes.ClientLeadStatus,
              fields: {
                isLead: JSON.parse(condition.arguments.expression)[1][1],
              },
            },
          };
        default:
          throw new Error("Unknown condition type");
      }
    },
  );

  const textOnlyConditions =
    defaultRule?.condition.conditions.filter(
      (condition: AllConditionTypes) =>
        condition.arguments.type === CreateRuleConditionTypes.TextOnly,
    ) || [];
  return [...textOnlyConditions, ...conditions];
}
