import React, { useContext, useEffect, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import type {
  SubscriptionAddonPreview,
  SubscriptionPreview,
} from "~/shared/billing/pricePreview/types";
import type { BillingCycleName } from "~/utilities/API/graphql";
import { useViewport } from "jobber/hooks/useViewport";
import {
  type TrackingDetails,
  trackViewedRecurlyForm,
} from "jobber/billing/utils/trackInteractions";
import {
  calculatePurchaseTotal,
  currentPreview,
} from "~/shared/billing/pricePreview/utils";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import type { CheckoutSummaryData } from "./types";
import { DesktopSummary } from "./components/DesktopSummary";
import { MobileSummary } from "./components/MobileSummary";

const DESKTOP_MAX_SCREEN_WIDTH = 1440;

export interface CheckoutSummaryProps {
  trackingDetails: TrackingDetails;
  selectedBillingCycle: BillingCycleName;
  checkoutSummaryData: CheckoutSummaryData;
  enablePurchaseButton: boolean;
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  displayedPurchaseTotal?: number;
  onSetSelectedBillingCycle: Dispatch<SetStateAction<BillingCycleName>>;
  onSetPurchaseTotal(purchaseTotal: number): void;
  onConfirmSubscription?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  setSelectedAddons: Dispatch<SetStateAction<string[]>>;
}

// eslint-disable-next-line max-statements
export function CheckoutSummary({
  trackingDetails,
  selectedBillingCycle,
  checkoutSummaryData,
  enablePurchaseButton,
  subscriptionAddons,
  selectedAddonCodes,
  displayedPurchaseTotal,
  onSetSelectedBillingCycle,
  onSetPurchaseTotal,
  onConfirmSubscription,
  setSelectedAddons,
}: CheckoutSummaryProps) {
  const context = useContext(PurchaseFormContext);

  const [selectedSubscriptionPreview, setSelectedSubscriptionPreview] =
    useState<SubscriptionPreview>(
      currentPreview(
        checkoutSummaryData.subscriptionPreviewGroup,
        selectedBillingCycle,
      ),
    );
  const { innerWidth } = useViewport();
  const onMobileWeb = innerWidth < DESKTOP_MAX_SCREEN_WIDTH;

  useEffect(() => {
    trackViewedRecurlyForm({
      ...trackingDetails,
      plan: checkoutSummaryData.planInfo?.planTier,
    });
  }, [trackingDetails, checkoutSummaryData.planInfo?.planTier]);

  const shouldShowTax =
    !context.billingAddressChanged &&
    !selectedSubscriptionPreview.isCurrentBillingCycle &&
    selectedSubscriptionPreview.proratedTax != undefined &&
    selectedAddonCodes.every(
      addonCode =>
        subscriptionAddons.find(
          addon => addon.monthlyBillingCycle.addonCode == addonCode,
        )?.previewGroup.monthlyPreview.proratedTax != undefined,
    );

  const purchaseTotal = calculatePurchaseTotal(
    shouldShowTax,
    selectedSubscriptionPreview,
    subscriptionAddons,
    selectedAddonCodes,
  );

  useEffect(() => {
    if (purchaseTotal !== displayedPurchaseTotal) {
      onSetPurchaseTotal(purchaseTotal);
    }
  }, [purchaseTotal, displayedPurchaseTotal, onSetPurchaseTotal]);

  if (onMobileWeb) {
    return (
      <MobileSummary
        planInfo={checkoutSummaryData.planInfo}
        discountInformation={checkoutSummaryData.discountInformation}
        subscriptionPreview={selectedSubscriptionPreview}
        selectedBillingCycle={selectedBillingCycle}
        viewingCurrentPlan={selectedSubscriptionPreview.isCurrentBillingCycle}
        subscribeDisabled={selectedSubscriptionPreview.subscribeDisabled}
        enablePurchaseButton={enablePurchaseButton}
        subscriptionAddons={subscriptionAddons}
        selectedAddonCodes={selectedAddonCodes}
        shouldShowTax={shouldShowTax}
        purchaseTotal={purchaseTotal}
        onSelectBillingCycle={handleBillingCycleChange}
        onConfirmSubscription={onConfirmSubscription}
        setSelectedAddons={setSelectedAddons}
      />
    );
  }

  return (
    <DesktopSummary
      planInfo={checkoutSummaryData.planInfo}
      discountInformation={checkoutSummaryData.discountInformation}
      subscriptionPreview={selectedSubscriptionPreview}
      selectedBillingCycle={selectedBillingCycle}
      viewingCurrentPlan={selectedSubscriptionPreview.isCurrentBillingCycle}
      subscribeDisabled={selectedSubscriptionPreview.subscribeDisabled}
      enablePurchaseButton={enablePurchaseButton}
      subscriptionAddons={subscriptionAddons}
      selectedAddonCodes={selectedAddonCodes}
      shouldShowTax={shouldShowTax}
      purchaseTotal={purchaseTotal}
      onSelectBillingCycle={handleBillingCycleChange}
      onConfirmSubscription={onConfirmSubscription}
      setSelectedAddons={setSelectedAddons}
    />
  );

  function handleBillingCycleChange(billingCycle: BillingCycleName) {
    onSetSelectedBillingCycle(billingCycle);
    setSelectedSubscriptionPreview(
      currentPreview(
        checkoutSummaryData.subscriptionPreviewGroup,
        billingCycle,
      ),
    );
  }
}
