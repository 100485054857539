import { Button } from "@jobber/components/Button";
import React, {
  type MutableRefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useQuery } from "@apollo/client";
import { useUkKycRegistrationData } from "jobber/chat/components/MessageCenterButton/hooks/useUkKycRegistrationData/useUkKycRegistrationData";
import { ChatDrawer } from "jobber/chat/components/ChatDrawer";
import { SideDrawer } from "components/SideDrawer";
import {
  UNREAD_CONVERSATIONS,
  UNREAD_CONVERSATIONS_SUBSCRIPTION,
} from "jobber/chat/components/MessageCenterButton/MessageCenterButton.graphql";
import { MessageCenterNotificationBadge } from "jobber/chat/components/MessageCenterButton/MessageCenterNotificationBadge";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { APIProvider } from "~/utilities/API/APIProvider";
import type {
  UnreadConversationsQuery,
  UpdateUnreadConversationsSubscription,
} from "~/utilities/API/graphql";
import {
  InitialMessageCenterState,
  MessageCenterContext,
  MessageCenterReducer,
} from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import type { AccountContextType } from "~/utilities/contexts/internal/AccountContext";
import styles from "./MessageCenterButton.module.css";
import {
  useHandleOpenToConversationEvent,
  useRegistrationStatus,
} from "./hooks";

export interface MessageCenterButtonProps {
  hasPhoneNumber: boolean;
  accountContext: AccountContextType;
}
function MessageCenterButton({
  hasPhoneNumber,
  accountContext,
}: MessageCenterButtonProps) {
  return (
    <APIProvider>
      <ChatIcon
        hasPhoneNumber={hasPhoneNumber}
        accountContext={accountContext}
      />
    </APIProvider>
  );
}

const SplitWrappedComponent = withSplitClient(MessageCenterButton);
export { SplitWrappedComponent as MessageCenterButton };

// eslint-disable-next-line max-statements
function ChatIcon({
  hasPhoneNumber,
  accountContext,
}: MessageCenterButtonProps) {
  const {
    data,
    loading: isUnreadConversationLoading,
    subscribeToMore,
  } = useQuery<UnreadConversationsQuery>(UNREAD_CONVERSATIONS);
  const [state, dispatch] = useReducer(
    MessageCenterReducer,
    InitialMessageCenterState,
  );
  const [isOpen, setIsOpen] = useState(false);

  const isUkKycRegistrationEnabled = useFeatureFlag(
    SplitNames.CommsUkKycDpnRegistration,
  );

  useHandleOpenToConversationEvent(setIsOpen, dispatch);

  useEffect(() => {
    return subscribeToMore<UpdateUnreadConversationsSubscription>({
      document: UNREAD_CONVERSATIONS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const newCount =
          subscriptionData?.data?.unreadConversations?.totalCount;
        if (newCount == undefined) {
          return prev;
        }

        return Object.assign({}, prev, {
          unreadConversations: {
            totalCount: newCount,
            __typename: "ConversationConnection",
          },
        });
      },
    });
  }, []);

  const loadingRegistration = useRegistrationStatus(dispatch);

  const { loading: isLoadingKycRegistrationData, isUkKycRegistrationRequired } =
    useUkKycRegistrationData(
      dispatch,
      isUkKycRegistrationEnabled,
      accountContext,
    );

  const buttonContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  return (
    <div className={styles.messageCenterButtonContainer}>
      <div ref={buttonContainerRef}>
        <Button
          type="secondary"
          variation="subtle"
          icon={"sms2"}
          ariaLabel={buttonLabel}
          onClick={onButtonPress}
        />
        {!isUnreadConversationLoading && !isLoadingKycRegistrationData && (
          <MessageCenterNotificationBadge
            unreadCount={data?.unreadConversations.totalCount || 0}
            isNotificationAvailable={
              isUkKycRegistrationRequired() && isUkKycRegistrationEnabled
            }
          />
        )}
        <MessageCenterContext.Provider value={[state, dispatch]}>
          <SideDrawer open={isOpen} requestDrawerClose={onCloseSideDrawer}>
            {!loadingRegistration && !isLoadingKycRegistrationData && (
              <ChatDrawer
                hasPhoneNumber={hasPhoneNumber}
                requestDrawerClose={onCloseSideDrawer}
              />
            )}
          </SideDrawer>
        </MessageCenterContext.Provider>
      </div>
    </div>
  );

  function onButtonPress() {
    setIsOpen(!isOpen);
  }

  function setFocusOnMessageCenterButton() {
    const button = buttonContainerRef.current.querySelector(
      `button[aria-label='${buttonLabel}']`,
    ) as HTMLButtonElement;
    button.focus();
  }

  function onCloseSideDrawer() {
    setIsOpen(false);

    // We need the tick to wait for the redraw after the drawer is removed.
    setTimeout(() => {
      setFocusOnMessageCenterButton();
    }, 0);
  }
}
const buttonLabel = "Open Text Message Inbox";
