import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clientSegmentPageTitle: {
    id: "campaigns.selectClientSegment.clientSegmentPageTitle",
    defaultMessage: "Select a client segment",
    description: "Title of select client segment page",
  },
  automationsTitle: {
    id: "campaigns.selectClientSegment.automations.title",
    defaultMessage: "Set automation rules",
    description: "Title of the client segment automations page",
  },
  allClientsTitle: {
    id: "campaigns.selectClientSegment.allClientsTitle",
    defaultMessage: "All clients",
    description: "Title for the all clients segment card",
  },
  upcomingVisitClientsTitle: {
    id: "campaigns.selectClientSegment.upcomingVisitClientsTitle",
    defaultMessage: "Clients with upcoming visits",
    description: "Title for the upcoming visit clients segment card",
  },
  quoteFollowUpBannerCTALabel: {
    id: "campaigns.selectClientSegment.quoteFollowUpBannerCTALabel",
    defaultMessage: "View Settings",
    description: "CTA label for quote follow up automation banner",
  },
  quoteFollowUpBannerMessage: {
    id: "campaigns.selectClientSegment.quoteFollowUpBannerMessage",
    defaultMessage:
      "Automated quote follow-ups are active. The last follow-up is set at {maxQuoteFollowUpSentDays} days after a quote is sent.",
    description: "Banner message for quote follow up automation",
  },
  clientSegmentSideDrawerTitle: {
    id: "campaigns.selectClientSegment.clientSegmentSideDrawerTitle",
    defaultMessage: "View client segment",
    description: "Title of client segment side drawer",
  },
  previewClientSegmentSideDrawerTitle: {
    id: "campaigns.selectClientSegment.previewClientSegmentSideDrawerTitle",
    defaultMessage: "Preview client segment",
    description: "Title of preview client segment side drawer",
  },
  edit: {
    id: "campaigns.selectClientSegment.edit",
    defaultMessage: "Edit",
    description: "Edit",
  },
  clients: {
    id: "campaigns.selectClientSegment.clients",
    defaultMessage:
      "{totalCount} {totalCount, plural, one {client} other {clients}}",
    description: "clients label",
  },
  clientSegmentSideDrawerButton: {
    id: "campaigns.selectClientSegment.clientSegmentSideDrawerButton",
    defaultMessage: "Edit Criteria",
    description: "Edit criteria button for the client segment",
  },
  savedClientSegment: {
    id: "campaigns.selectClientSegment.updateCriteria.saved",
    defaultMessage: "Saved Client Segment",
    description: "Toast shown after the client segment criteria has been saved",
  },
});
