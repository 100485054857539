import { defineMessages } from "react-intl";

export const messages = defineMessages({
  reminder: {
    id: "checkout.downgradeDisclaimer.reminder",
    defaultMessage:
      "Reminder! By changing from the {planDowngradingFrom} plan to the {planDowngradingTo} plan",
    description: "Reminder message for downgrade disclaimer component",
  },
  featureLossDisclaimer: {
    id: "checkout.downgradeDisclaimer.featureLossDisclaimer",
    defaultMessage:
      "You will no longer have access to features unique to {planDowngradingFrom} {growToCore, select, true {or {growToCorePlanName}} other {}}",
    description:
      "Feature loss disclaimer message for downgrade disclaimer component",
  },
  planName: {
    id: "checkout.downgradeDisclaimer.planName",
    defaultMessage: "The {planName} Plan",
    description: "Plan name for downgrade disclaimer component",
  },
  growToCorePlanName: {
    id: "checkout.downgradeDisclaimer.growToCorePlanName",
    defaultMessage: "The Connect Plan",
    description: "Grow plan name for downgrade disclaimer component",
  },
  twoWaySmsDisclaimer: {
    id: "checkout.downgradeDisclaimer.twoWaySmsDisclaimer",
    defaultMessage:
      "You will lose your Dedicated Phone Number for {twoWaySmsLink} if you have it enabled",
    description:
      "Two-way SMS disclaimer message for downgrade disclaimer component",
  },
  twoWaySmsLink: {
    id: "checkout.downgradeDisclaimer.twoWaySmsLink",
    defaultMessage: "Two-Way Text Messaging",
    description: "Two-way SMS link for downgrade disclaimer component",
  },
  qboDisclaimer: {
    id: "checkout.downgradeDisclaimer.qboDisclaimer",
    defaultMessage: "The {qboLink} will be disconnected if you have it enabled",
    description: "QBO disclaimer message for downgrade disclaimer component",
  },
  qboLink: {
    id: "checkout.downgradeDisclaimer.qboLink",
    defaultMessage: "QuickBooks Online Integration",
    description: "QBO link for downgrade disclaimer component",
  },
  specialtyPricingDisclaimer: {
    id: "checkout.downgradeDisclaimer.specialtyPricingDisclaimer",
    defaultMessage:
      "Please note that by changing your plan today, future subscription renewals and plan changes will be subject to then-current pricing for such subscriptions",
    description:
      "Specialty pricing disclaimer message for downgrade disclaimer component",
  },
  haveQuestions: {
    id: "checkout.downgradeDisclaimer.haveQuestions",
    defaultMessage:
      "Review our {termsOfService} for more information. Have questions? {chatWithUsLink}",
    description: "Have questions message for downgrade disclaimer component",
  },
  termsOfService: {
    id: "checkout.downgradeDisclaimer.termsOfService",
    defaultMessage: "terms of service",
    description: "Terms of service link for downgrade disclaimer component",
  },
  chatWithUsButton: {
    id: "checkout.downgradeDisclaimer.chatWithUsButton",
    defaultMessage: "Chat with us",
    description: "Chat with us button for downgrade disclaimer component",
  },
});
